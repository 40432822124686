import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

//SEO
import {
    websiteSchema,
    webpageSchema,
    localBusinessSchema,
    professionalServiceSchema,
    organization,
    // review,
    breadcrubmList,
    // video
    notarySchema,
} from './schemas';


const Metatags = ({ title_par, 
    description_par, 
    type_par, ad_host, 
    ad_origin, 
    link_page,        
    keywords_par, 
    isArticle, 
    art_titleData,
    art_descriptionData,
    art_imagesData,
    art_imagesData_caption,
    art_keywordsData,
    art_dateCreationData,
    art_dateLastActivityData,
    art_shortDescriptionData,
    art_urlData }) => {

    const articleSchema = {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        headline: `${art_titleData}`,
        alternativeHeadline: `${art_descriptionData}`,
        image: {
            "@type":"ImageObject",
            url: `${art_imagesData}` ,
            "caption": `${art_imagesData_caption}`,
            "height": 1494,
            "width": 2239
        },
        author: {
            "@type":"Organization",
            name:"Studio Notarile Falcinelli"
        },
        keywords: `${art_keywordsData}`,
        publisher: {
            "@type":"Organization",
            name:"Studio Notarile Falcinelli"
        },
        url: `${art_urlData}`,
        "inLanguage": "it-IT",
        datePublished: `${art_dateCreationData}`,
        dateCreated: `${art_dateCreationData}`,
        dateModified: `${art_dateLastActivityData}`,
        description: `${art_shortDescriptionData}`,
        articleBody: `${art_descriptionData}`,
        mainEntityOfPage: `${art_urlData}`
    }
    
    return (
        <>
        <Helmet>
            <title>{title_par}</title>
            <link rel="canonical" href={link_page} />
            <link rel="shortcut icon" href="https://www.notaio-treviso.com/favicon.ico" type="image/x-icon"></link>
            <meta name="Studio Notarile Falcinelli - Notaio Treviso" content="Studio Notarile Falcinelli - Notaio Treviso" />
            <meta name="description" content={description_par} />
            <meta name="author" content="Notaio Andrea Falcinelli " />
            <meta name="copyright" content="Notaio Andrea Falcinelli " />

            <meta property="og:title" content={title_par} />
            <meta property="og:description" content={description_par} />
            <meta property="og:url" content={link_page} />
            <meta property="og:type" content={type_par} />
            <meta property="og:locale" content="it_IT" />
            <meta property="og:site_name" content="Studio notarile Falcinelli - notaio Andrea Falcinelli - Treviso" />
            <meta property="og:image" itemprop="image" content={`${link_page}/logo_one.png`} />
            <meta property="og:image:type" content="image/jpeg" />
            <meta property="og:image:width" content="1300" />
            <meta property="og:image:height" content="665" />

            <meta property="article:publisher" content={ad_host} />
            <meta property="article:author" content={ad_host} />

            <meta itemprop="name" content={title_par} />
            <meta itemprop="description" content={description_par} />
            <meta itemprop="image" content={`${link_page}'/logo_one.png'`} />
            <meta itemprop="author" content="Notaio Andrea Falcinelli " />
            <meta itemprop="headline" content="Notaio Andrea Falcinelli " />
            <meta itemprop="datePublished" content="2022" />
            <meta itemprop="publisher" content="Notaio Andrea Falcinelli " />

            <meta name="twitter:title" content={title_par} />
            <meta name="twitter:site" content="@andreamyandreas" />
            <meta name="twitter:description" content={description_par} />
            <meta property="twitter:url" content={link_page} />
            <meta name="twitter:description" content={description_par} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:image" content={`${link_page}/logo_one.png`} />

            <meta name="keywords" content={`
            Notaio Falcinelli, 
            Notaio Falcinelli Treviso, 
            Notaio Falcinelli Veneto, 
            Notaio Andrea Falcinelli, 
            Andrea Falcinelli, 
            Notaio Treviso, 
            Notaio Veneto, 
            Notaio, 
            Notaio Andrea Falcinelli, 
            Notaio Andrea Falcinelli Treviso, 
            Notaio Andrea Falcinelli Veneto, 
            Studio notarile Treviso, 
            Studio notarile Veneto,
            trust Treviso,
            trust,
            trust Veneto,
            diritto societario,
            diritto tributario,
            diritto civile,
            successioni,
            donazioni,
            ${keywords_par}`}
            />
        </Helmet>
        {isArticle.includes('vero') ? 
            <Helmet>
                <script type='application/ld+json'>{JSON.stringify(articleSchema)}</script>
                <script type='application/ld+json'>{JSON.stringify(localBusinessSchema)}</script>
                <script type='application/ld+json'>{JSON.stringify(professionalServiceSchema)}</script>
                <script type='application/ld+json'>{JSON.stringify(organization)}</script>
                <script type='application/ld+json'>{JSON.stringify(breadcrubmList)}</script> 
                <script type='application/ld+json'>{JSON.stringify(notarySchema)}</script>
            </Helmet> : 
            <Helmet>
            <script type='application/ld+json'>{JSON.stringify(websiteSchema)}</script>
            <script type='application/ld+json'>{JSON.stringify(webpageSchema)}</script>
            <script type='application/ld+json'>{JSON.stringify(localBusinessSchema)}</script>
            <script type='application/ld+json'>{JSON.stringify(professionalServiceSchema)}</script>
            <script type='application/ld+json'>{JSON.stringify(organization)}</script>
            {/* <script type='application/ld+json'>{JSON.stringify(review)}</script> */}
            <script type='application/ld+json'>{JSON.stringify(breadcrubmList)}</script> 
            <script type='application/ld+json'>{JSON.stringify(notarySchema)}</script>
            </Helmet>
            }
        </>
    )
}

Metatags.propTypes = {
    title_par: PropTypes.string.isRequired,
    description_par: PropTypes.string.isRequired,
    type_par: PropTypes.string.isRequired,
    ad_host: PropTypes.string.isRequired,
    ad_origin: PropTypes.string.isRequired,
    link_page: PropTypes.string.isRequired,
    keywords_par: PropTypes.string.isRequired,
    isArticle: PropTypes.string.isRequired, 
    art_titleData: PropTypes.string.isRequired,
    art_descriptionData: PropTypes.string.isRequired,
    art_imagesData: PropTypes.string.isRequired,
    art_keywordsData: PropTypes.string.isRequired,
    art_dateCreationData: PropTypes.string.isRequired,
    art_dateLastActivityData: PropTypes.string.isRequired,
    art_shortDescriptionData: PropTypes.string.isRequired,
    art_urlData: PropTypes.string.isRequired
};

export default Metatags;
