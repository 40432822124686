import React from 'react';
import Seo from '../components/seo/Metatags';
import dynamic from 'next/dynamic'

// wrap your component that uses the graph lib.
const AreaRiservata = dynamic(
    () => import('../components/areaRiservata'),
    { ssr: false }
)

let ad_origin = typeof window !== "undefined" ? `${window.location.origin.toString()}` : "https://www.notaio-treviso.com"
let ad_host = typeof window !== "undefined" ? `${window.location.origin.toString()}` : "www.notaio-treviso.com"

const AboutPage = () => {
    return (
        <>
            <Seo
                title_par="Area Riservata | Studio Notarile Falcinelli"
                description_par="Lo studio Notarile Falcinelli vi da il benvenuto nell'area riservata. L'area riservata consente ai Clienti dello Studio Notarile Falcinelli di Treviso di poter accedere comodamente on line alla documentazione e agli atti inerenti. La procedura di accesso avviene in maniera riservata e sicura mediante la proria EMAIL e PASSWORD."
                type_par="website"
                ad_host={ad_host}
                ad_origin={ad_origin}
                link_page={`${ad_origin}/area-riservata`}
                keywords_par="Treviso trust notarile, trust notarile, trust notaio, Treviso, area riservata notaio, documenti notarili, notai treviso"
                isArticle={''}
                art_titleData=''
                art_descriptionData=''
                art_imagesData=''
                art_imagesData_caption=''
                art_keywordsData=''
                art_dateCreationData=''
                art_dateLastActivityData=''
                art_shortDescriptionData=''
                art_urlData=''
            />

            <React.Fragment>
                <AreaRiservata />
            </React.Fragment>
        </>
    )
}

export default AboutPage;
