import React from 'react';
import { MDBFooter } from 'mdb-react-ui-kit';
import { MDBBtn, MDBIcon } from 'mdb-react-ui-kit';

import './style.css';

export default function Footer() {
  return (
    <MDBFooter bgColor='light' className='text-center text-lg-start text-muted'>
      <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
        <div className='me-5 d-none d-lg-block'>
          <span>I canali social dello Studio Notarile Falcinelli:</span>
        </div>

        <div>
          <MDBBtn className='m-1' style={{ backgroundColor: '#3b5998' }} target="_blank" href='https://www.facebook.com/Notaio-Treviso-Studio-Notarile-Falcinelli-108508261876163/'>
            <MDBIcon fab icon='facebook-f' />
          </MDBBtn>

          <MDBBtn className='m-1' style={{ backgroundColor: '#dd4b39' }} target="_blank" href='https://notaiotreviso.business.site/'>
            <MDBIcon fab icon='google' />
          </MDBBtn>

          <MDBBtn className='m-1' style={{ backgroundColor: '#ac2bac' }} target="_blank" href='https://www.instagram.com/notaiotreviso/'>
            <MDBIcon fab icon='instagram' />
          </MDBBtn>

          <MDBBtn className='m-1' style={{ backgroundColor: '#0082ca' }} target="_blank" href='https://www.linkedin.com/company/notaio-treviso-studio-notarile-falcinelli/'>
            <MDBIcon fab icon='linkedin-in' />
          </MDBBtn>

          <MDBBtn className='m-1' style={{ backgroundColor: '#ed302f' }} target="_blank" href='https://www.youtube.com/channel/UCe-V7fjNLFSxcrMCrW_FTNA/featured'>
            <MDBIcon fab icon='youtube' />
          </MDBBtn>

          <MDBBtn className='m-1' style={{ backgroundColor: '#25d366' }} target="_blank" href='https://api.whatsapp.com/send/?phone=3904221628653'>
            <MDBIcon fab icon='whatsapp' />
          </MDBBtn>

        </div>
      </section>

      {/* <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
        <SearchBarComp />
      </section> */}

      <section className=''>
        <div className='container text-center text-md-start mt-5'>
          <div className='row mt-3'>
            <div className='col-md-3 col-lg-4 col-xl-3 mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>
                Studio Notarile Falcinelli | Notaio a Treviso
              </h6>
              <p>
              Ascoltare Le Esigenze Delle Persone, Spiegare Con Chiarezza E Competenza Le Opportunità E Le Conseguenze Delle Scelte Giuridiche, Per Guidare Verso Scelte Consapevoli. Questo È Il Lavoro Del Notaio Falcinelli a Treviso. La Fiducia È Il Suo Requisito Essenziale. Consulta Tutto Il Sito E Richiedi Preventivo Per Atti Immobiliari Di Compravendita E Mutuo O Societari O Chiamaci Al Numero Di Telefono <a
                  title={`Telefono Studio Notarile Falcinelli | Notaio a Treviso`} 
                  href="tel:+3904221628653"
                  target="_blank"
                  className='aColor'
                >
                  +39 04221628653
                </a>
              </p>
            </div>

            <div className='col-md-2 col-lg-2 col-xl-2 mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Contatti</h6>
              <p>
                <a href='/#formContatti' className='text-reset'>
                Invia una richiesta
                </a>
              </p>
              <p>
                <a href='/#formNum' className='text-reset'>
                Il nostro numero
                </a>
              </p>
              <p>
                <a href='/#contattiSroll' className='text-reset'>
                Dove siamo
                </a>
              </p>
              <p>
                <a href='/#linkPar' className='text-reset'>
                Dove parcheggiare
                </a>
              </p>
              <p>
                <a href='/#linkMap' className='text-reset'>
                Mappa di Treviso
                </a>
              </p>
            </div>

            <div className='col-md-3 col-lg-2 col-xl-2 mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Servizi</h6>
              <p>
                <a href='/servizi' className='text-reset'>
                Approfondisci
                </a>
              </p>
              <p>
                <a href='/servizi/autocertificazioni' className='text-reset'>
                Autocertificazioni
                </a>
              </p>
              <p>
                <a href='/servizi/documenti-stipula' className='text-reset'>
                Documenti per la stipula
                </a>
              </p>
            </div>

            <div className='col-md-3 col-lg-2 col-xl-2 mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>News</h6>
              <p>
                <a href='/news#newsLink' className='text-reset'>
                Articoli
                </a>
              </p>
              <p>
                <a href='/news#videoLink' className='text-reset'>
                Video
                </a>
              </p>
              <p>
                <a href='/infografica' className='text-reset'>
                Infografiche
                </a>
              </p>
            </div>

            <div className='col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Contatti</h6>
              <p>
                <a 
                  title={`Via Cornarotta 5 Treviso | Studio Notarile Falcinelli | Notaio a Treviso`} 
                  href="https://goo.gl/maps/cjzqhqb31f4Spurg7"
                  target="_blank"
                  className='aColor'
                  >
                <i className='fas fa-home me-3'></i> 
                  Via Cornarotta n.5, Treviso
                </a>
              </p>
              <p>
              <a
                title={`Mail Studio Notarile Falcinelli | Notaio a Treviso`}
                href="mailto:info@notaiofalcinelli.it"
                target="_blank"
                className='aColor'
                >
                  <i className='fas fa-envelope me-3'></i>
                  info@notaiofalcinelli.it
                </a>
              </p>
              <p>
                <a
                  title={`Telefono Studio Notarile Falcinelli | Notaio a Treviso`} 
                  href="tel:+3904221628653"
                  target="_blank"
                  className='aColor'
                >
                  <i className='fas fa-phone me-3'></i> 
                  +39 04221628653
                </a>
              </p>
              <p>
                <a
                  title={`WhatsApp Studio Notarile Falcinelli | Notaio a Treviso`} 
                  href='https://api.whatsapp.com/send/?phone=3904221628653'
                  target="_blank"
                  className='aColor'
                >
                  <i className="fa fa-whatsapp me-3" style={{color: '#22ad10'}}></i>
                    WhatsApp Studio Notarile Falcinelli
                </a>
              </p>
              {/* <p>
                <i className='fas fa-print me-3'></i> + 01 234 567 89
              </p> */}
            </div>
          </div>
        </div>
      </section>

      <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
        © 2023 Copyright:
        <a className='text-reset fw-bold' href='https://www.notaio-treviso.com/'>
          Notaio Andrea Falcinelli
        </a>
      </div>
    </MDBFooter>
  );
}