import React from 'react';
import Seo from '../components/seo/Metatags';
import dynamic from 'next/dynamic';

//import components
// wrap your component that uses the graph lib.


const BackToTop = dynamic(
    () => import('../components/back-top'),
    { ssr: false }
)

const Intro = dynamic(
    () => import('../components/Intro/index'),
    { ssr: false }
)

const Autocertificazioni = dynamic(
    () => import('../components/Autocertificazioni'),
    { ssr: false }
)


// const Preloader = dynamic(
//     () => import('../components/preloader'),
//     { ssr: false }
// )

let ad_origin = typeof window !== "undefined" ? `${window.location.origin.toString()}` : "https://www.notaio-treviso.com"
let ad_host = typeof window !== "undefined" ? `${window.location.origin.toString()}` : "www.notaio-treviso.com"

const AutocertificazioniPage = () => {
    return (
        <>
            <Seo
                title_par="Autocertificazioni | Notaio a Treviso | Studio Notarile Falcinelli"
                description_par="Contatta lo Studio Notarile Falcinelli per il tuo atto notarile a Treviso e provincia: mutui, atti societari, atti di compravendita, trust, successioni."
                type_par="website"
                ad_host={ad_host}
                ad_origin={ad_origin}
                link_page={`${ad_origin}/servizi/autocertificazioni`}
                keywords_par="autocertificazione, notaio a Treviso, notai Treviso, notai treviso"
                isArticle={''}
                art_titleData=''
                art_descriptionData=''
                art_imagesData=''
                art_imagesData_caption=''
                art_keywordsData=''
                art_dateCreationData=''
                art_dateLastActivityData=''
                art_shortDescriptionData=''
                art_urlData=''
            />
            <React.Fragment>
                <Intro 
                    title={'Autocertificazioni'} 
                    subtitle={'Notaio Treviso - Studio Notarile Falcinelli'} 
                    contents={`
                    Lo studio notarile Falcinelli offre una pagina per compilare e stampare le autocertificazioni. Un'autocertificazione consiste nella facoltà riconosciuta ai cittadini di presentare, in sostituzione delle tradizionali certificazioni richieste, propri stati e requisiti personali, mediante apposite dichiarazioni sottoscritte (firmate) dall'interessato. La firma non deve essere più autenticata.
                    L'autocertificazione sostituisce i certificati senza che ci sia necessità di presentare successivamente il certificato vero e proprio. La pubblica amministrazione ha l'obbligo di accettarle, riservandosi la possibilità di controllo e verifica in caso di sussistenza di ragionevoli dubbi sulla veridicità del loro contenuto.
                    <br/><a className='italicIntro' title='notaio treviso' href='https://www.notaio-treviso.com/'>NOTAIO TREVISO</a>`}
                    linkSpinner={'divAutocertificazioni'}
                />
                <Autocertificazioni />
                <BackToTop />
                {/* <Preloader /> */}
            </React.Fragment>
        </>
    )
}

export default AutocertificazioniPage;
