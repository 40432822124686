import React from 'react';
import Seo from '../components/seo/Metatags';
import dynamic from 'next/dynamic'
import { useParams } from 'react-router-dom';
import data from '../components/servizi/data/output';

//import components
// wrap your component that uses the graph lib.

const BackToTop = dynamic(
    () => import('../components/back-top'),
    { ssr: false }
)

const Contact = dynamic(
    () => import('../components/contatti/index_prev'),
    { ssr: false }
)

const Intro = dynamic(
    () => import('../components/Intro/index'),
    { ssr: false }
)

let ad_origin = typeof window !== "undefined" ? `${window.location.origin.toString()}` : "https://www.notaio-treviso.com"
let ad_host = typeof window !== "undefined" ? `${window.location.origin.toString()}` : "www.notaio-treviso.com"

const ServizioPreventivoPage = () => {
    const { servizioID } = useParams();
    return (
        <>
            {data.map( servizio => {
                if (servizioID === servizio.link) {
                    return (
                        <>
                            <Seo
                                title_par={`${servizio.name} preventivo | Notaio a Treviso`}
                                description_par={`${servizio.name} preventivo. Contatta lo Studio Notarile Falcinelli per il tuo atto notarile a Treviso e provincia: mutui, atti societari, atti di compravendita, trust, successioni.`}
                                type_par="website"
                                ad_host={ad_host}
                                ad_origin={ad_origin}
                                link_page={`${ad_origin}/servizi/${servizio.link}/preventivo`}
                                keywords_par="Treviso trust notarile, trust notarile, trust notaio, Treviso, notai treviso"
                                isArticle={'vero'}
                                art_titleData={`Notaio a Treviso - ${servizio.name}`}
                                art_descriptionData={servizio.description}
                                art_imagesData={servizio.imageArt}
                                art_imagesData_caption={`Notaio a Treviso - ${servizio.name}`}
                                art_keywordsData={`Notaio a Treviso - ${servizio.tag}`}
                                art_dateCreationData=''
                                art_dateLastActivityData=''
                                art_shortDescriptionData={servizio.description}
                                art_urlData={`${ad_origin}/servizi/${servizio.link}/preventivo`}
                            />
                            <React.Fragment>
                                <Intro 
                                    subtitle={`${servizio.name} - Richiesta di Preventivo`} 
                                    title={'Studio Notarile Falcinelli - Notaio Treviso'}
                                    contents={`Studio Notarile Falcinelli</br>Scorrere per compilare la richiesta di preventivo.</br><a className='italicIntro' title='notaio treviso' href='https://www.notaio-treviso.com/'>NOTAIO TREVISO</a>`}
                                    linkSpinner={'formContatti'}
                                />
                                <Contact />
                                <BackToTop />
                                {/* <Preloader /> */}
                            </React.Fragment>
                        </>
                    );
                }
                })}
    </>
    )
}

export default ServizioPreventivoPage;
