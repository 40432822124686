import React from 'react';
import Container from 'react-bootstrap/Container';

import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon
} from "react-share";

import './styles.css';


const SocialShare = ({ urlSpec }) => {
  return (
    <div>
      <div className='gridBu'>
        <FacebookShareButton
          className="social-btn"
          url={urlSpec}
        >
          <FacebookIcon round size="30" />
        </FacebookShareButton>
      </div>
      <div className='gridBu'>
        <TwitterShareButton
          className="social-btn"
          url={urlSpec}
        >
          <TwitterIcon round size="30" />
        </TwitterShareButton>
      </div>
      <div className='gridBu'>
        <LinkedinShareButton
          className="social-btn"
          url={urlSpec}
        >
          <LinkedinIcon round size="30" />
        </LinkedinShareButton>
      </div>
      <div className='gridBu'>
        <WhatsappShareButton
          className="social-btn"
          url={urlSpec}
        >
          <WhatsappIcon round size="30" />
        </WhatsappShareButton>
      </div>
    </div>
  );
}

export default SocialShare;
