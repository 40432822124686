import React from 'react';
import Seo from '../components/seo/Metatags';
import dynamic from 'next/dynamic';
// import DocumentiStipula from '../components/DocumentiStipula';

//import components
// wrap your component that uses the graph lib.

const Intro = dynamic(
    () => import('../components/Intro/index'),
    { ssr: false }
)

const DocumentiStipula = dynamic(
    () => import('../components/DocumentiStipula'),
    { ssr: false }
)

const BackToTop = dynamic(
    () => import('../components/back-top'),
    { ssr: false }
)

let ad_origin = typeof window !== "undefined" ? `${window.location.origin.toString()}` : "https://www.notaio-treviso.com"
let ad_host = typeof window !== "undefined" ? `${window.location.origin.toString()}` : "www.notaio-treviso.com"

const DocumentiStipulaPage = () => {
    return (
        <>
            <Seo
                title_par="Documenti per la stipula | Notaio a Treviso | Studio Notarile Falcinelli"
                description_par="Documenti occorrenti per le pratiche notarili. Notaio a Treviso. Studio Notarile Falcinelli: mutui, atti societari, atti di compravendita, trust, successioni."
                type_par="website"
                ad_host={ad_host}
                ad_origin={ad_origin}
                link_page={`${ad_origin}/servizi/documenti-stipula`}
                keywords_par="documenti per la stipula, documenti notaio, notaio a Treviso, notai treviso"
                isArticle={''}
                art_titleData=''
                art_descriptionData=''
                art_imagesData=''
                art_imagesData_caption=''
                art_keywordsData=''
                art_dateCreationData=''
                art_dateLastActivityData=''
                art_shortDescriptionData=''
                art_urlData=''
            />
            <React.Fragment>
                <Intro 
                    title={'Documenti per le pratiche notarili'} 
                    subtitle={'Notaio Treviso - Studio Notarile Falcinelli'} 
                    contents={`A seconda del tipo di rogito che si intenda stipulare sono necessari diversi tipi di documenti. Quasi tutti i documenti possono essere richiesti autonomamente anche dal nostro studio notarile, tuttavia avere a disposizione una documentazione quanto più completa possibile, agevola e velocizza l'istruttoria, ed inoltre evita inutili aggravi di costi, dovuti alla richiesta a pagamento di certificati e documentazione varia. Contattaci per trust, affidamento fiduciario, compravendita, mutuo, rent to buy, donazioni, unioni civili, testamenti, successioni, patto di famiglia, tutela del patrimonio, società, azienda, enti no profit, leasing, vidimazioni e copie.<br/><a className='italicIntro' title='notaio treviso' href='https://www.notaio-treviso.com/'>NOTAIO TREVISO</a>`}
                    linkSpinner={'divStipula'}
                />
                <DocumentiStipula />
                <BackToTop />
            </React.Fragment>
        </>
    )
}

export default DocumentiStipulaPage;
