import React from 'react';
import Seo from '../components/seo/Metatags';
import dynamic from 'next/dynamic';

//import components
// wrap your component that uses the graph lib.
const Intro = dynamic(
    () => import('../components/Intro/index'),
    { ssr: false }
)

const BackToTop = dynamic(
    () => import('../components/back-top'),
    { ssr: false }
)

const Servizi = dynamic(
    () => import('../components/servizi'),
    { ssr: false }
)

// const Preloader = dynamic(
//     () => import('../components/preloader'),
//     { ssr: false }
// )

let ad_origin = typeof window !== "undefined" ? `${window.location.origin.toString()}` : "https://www.notaio-treviso.com"
let ad_host = typeof window !== "undefined" ? `${window.location.origin.toString()}` : "www.notaio-treviso.com"

const ServiziPage = () => {
    return (
        <>
            <Seo
                title_par="Servizi | Notaio a Treviso | Studio Notarile Falcinelli"
                description_par="Contatta lo Studio Notarile Falcinelli per il tuo atto notarile a Treviso e provincia: mutui, atti societari, atti di compravendita, trust, successioni."
                type_par="website"
                ad_host={ad_host}
                ad_origin={ad_origin}
                link_page={`${ad_origin}/servizi`}
                keywords_par="Treviso trust notarile, trust notarile, trust notaio, Treviso, notai treviso"
                isArticle={''}
                art_titleData=''
                art_descriptionData=''
                art_imagesData=''
                art_imagesData_caption=''
                art_keywordsData=''
                art_dateCreationData=''
                art_dateLastActivityData=''
                art_shortDescriptionData=''
                art_urlData=''
            />
            <React.Fragment>
                <Intro 
                    subtitle={'Servizi'} 
                    title={'Studio Notarile Falcinelli - Notaio Treviso'} 
                    contents={`Lo studio Notarile Falcinelli offre un servizio su misura competente, celere e improntato alla soluzione dei problemi della clientela.
                    <br/>Offre servizi e consulenza in ambito di trust, affidamento fiduciario, compravendita, mutuo, rent to buy, donazioni, unioni civili, testamenti, successioni, patto di famiglia, tutela del patrimonio, società, azienda, enti no profit, leasing, vidimazioni e copie.<br/>In questa pagina potrete ricercare e selezionare il servizio di vostro interesse e richiedere informazioni e preventivi.<br/>Lo studio Notarile Falcinelli affronta la risoluzione delle questioni legali e notarili con serietà, studio, professionalità e competenza, mettendo sempre al centro la soddisfazione dei propri clienti.<br/><a className='italicIntro' title='notaio treviso' href='https://www.notaio-treviso.com/'>NOTAIO TREVISO</a>`}
                    linkSpinner={'serviziList'}
                />
                <Servizi />
                <BackToTop />
                {/* <Preloader /> */}
            </React.Fragment>
        </>
    )
}

export default ServiziPage;
