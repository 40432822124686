import React from 'react';
import { Container } from 'react-bootstrap';

const ContenutoCorpus = ({ autocert }) => {
 console.log(autocert)
 return ( 
  <Container className='articoloBoxDesktop'>
      <div className="content post_content" dangerouslySetInnerHTML={{ __html: 
       `
       <h3>Modulo autocertificazione "${autocert.name}"</h3>
       <p>
        In questa pagina proponiamo un modello di autocertificazione di <strong>${autocert.name}</strong> da compilare e stampare.
       </p>
       <p>
        Si tratta di un modulo editabile direttamente dall'interfaccia inedita che lo <strong>Studio Notarile Falcinelli</strong> mette a disposizione sul suo sito.
       </p>
       <p>
        Il dichiarante può firmare il documento tramite l'apposita area di firma. La firma non necessita di essere autenticata.
       </p>
       <p> 
        Una volta compilata online l'autocertificazione di <strong>${autocert.name}</strong> si può visualizzare l'anteprima e scaricre il certificato in PDF che può essere salvato sul proprio dispositivo, inviato per email oppure stampato.
        Si ricorda che non è necessario applicare marca da bollo.
       </p>
       
       <h3>Autocertificazione ${autocert.name}</h3>
       ` + autocert.corpus +
       `
       <h3>Responsabilità per l’autocertificazione</h3>
       <p>
        La persona che dichiara si assume la responsabilità civile e penale di quanto dichiarato nella dichiarazione, e che in caso di falsa dichiarazione, ci sono delle sanzioni. D’altra parte, il dichiarante che presenta un’<strong>autocertificazione</strong> falsa perderà i benefici ottenuti attraverso di essa.
       </p>
       `
       }}></div>
  </Container>
  );
}

export default ContenutoCorpus;

