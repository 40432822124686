let ad_origin = typeof window !== "undefined" ? `${window.location.origin.toString()}` : "https://www.notaio-treviso.com/"
let ad_host = typeof window !== "undefined" ? `${window.location.origin.toString()}` : "https://www.notaio-treviso.com/"

export const websiteSchema = {
    '@context': 'https://www.schema.org',
    '@type': 'WebSite',
    'name': 'Studio notarile Falcinelli - Notaio Andrea Falcinelli - Treviso',
    'alternateName': 'Studio notarile Falcinelli - Notaio Andrea Falcinelli - Treviso',
    'url': ad_origin
  }
  
  export const webpageSchema = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    'name': 'Studio notarile Falcinelli - Notaio Andrea Falcinelli - Treviso',
    'description': 'Lo studio notarile Falcinelli ha sede in Via di Cornarotta n°5 a Treviso (a pochi metri da Piazza del Duomo, a 200 metri dalla Fontana delle Tette, a 250 metri da Piazza dei Signori). Contatta lo Studio Notarile Falcinelli per il tuo atto notarile a Treviso e provincia: mutui, atti societari, atti di compravendita, trust, successioni.',
    'publisher': {
      '@type': 'service',
      'name': 'Studio notarile Falcinelli - Notaio Andrea Falcinelli - Treviso'
    },
    'license': ad_origin
  }
  
  export const localBusinessSchema = {
    '@context': 'httpss://www.schema.org',
    '@type': 'localbusiness',
    'name': 'Studio notarile Falcinelli - Notaio Andrea Falcinelli - Treviso',
    'telephone': '+ 3904221628653',
    'url': ad_origin,
    'logo': `${ad_origin}/logo_one.png`,
    'image': `${ad_origin}/logo_one.png`,
    'pricerange': '',
    'description': 'Lo studio notarile Falcinelli ha sede in Via di Cornarotta n°5 a Treviso (a pochi metri da Piazza del Duomo, a 200 metri dalla Fontana delle Tette, a 250 metri da Piazza dei Signori). Contatta lo Studio Notarile Falcinelli per il tuo atto notarile a Treviso e provincia: mutui, atti societari, atti di compravendita, trust, successioni.',
    'address': {
      '@type': 'PostalAddress',
      'streetAddress': 'via Cornarotta 5',
      'addressLocality': 'Treviso',
      'addressRegion': 'Veneto',
      'postalCode': '31100',
      'addressCountry': 'IT'
    },
    'geo': {
      '@type': 'GeoCoordinates',
      'longitude': '45.66734871828549',
      'latitude': '12.243494082208693'
    },
    'contactPoint': {
      '@type': 'PostalAddress',
      'contactType': 'Customer Service',
      'telephone': '+39 04221628653'
    },
    'aggregateRating': {
      '@type': 'AggregateRating',
      'ratingValue': '5',
      'bestRating': '5',
      'worstRating': '1',
      'ratingCount': '54'
    },
    'review': {
      '@type': 'Review',
      'author': {
        '@type': 'Person',
        'name': 'Roberto Caligoni'
      },
      'datePublished': '2022-06-15',
      'reviewRating': {
        '@type': 'Rating',
        'ratingValue': '5',
        'bestRating': '5',
        'worstRating': '1'
      },
      'reviewBody': 'Servizio molto efficiente, preciso e puntuale. Notaio cordiale e molto preparato.'
    }
  }
  
  export const professionalServiceSchema = {
    '@context': 'https://www.schema.org',
    '@type': 'professionalService',
    'name': ' Studio notarile Falcinelli - Notaio Andrea Falcinelli - Treviso',
    'url': ad_origin,
    'logo': `${ad_origin}/logo_one.png`,
    'image': `${ad_origin}/logo_one.png`,
    'alternateName': 'Studio notarile Falcinelli - Notaio Andrea Falcinelli - Treviso',
    'priceRange': 'Affordable',
    'telephone': '+39 04221628653',
    'description': 'Lo studio notarile Falcinelli ha sede in Via di Cornarotta n°5 a Treviso (a pochi metri da Piazza del Duomo, a 200 metri dalla Fontana delle Tette, a 250 metri da Piazza dei Signori). Contatta lo Studio Notarile Falcinelli per il tuo atto notarile a Treviso e provincia: mutui, atti societari, atti di compravendita, trust, successioni.',
    'address': {
        '@type': 'PostalAddress',
        'streetAddress': 'via Cornarotta 5',
        'addressLocality': 'Treviso',
        'addressRegion': 'Veneto',
        'postalCode': '31100',
        'addressCountry': 'IT'
      },
    'aggregateRating': {
      '@type': 'AggregateRating',
      'ratingValue': '5',
      'bestRating': '5',
      'worstRating': '1',
      'reviewCount': '54'
    },
    'openingHoursSpecification': [
      {
        '@context': 'https://schema.org',
        '@type': 'OpeningHoursSpecification',
        'dayOfWeek': 'Saturday',
        'opens': 'Closed'
      },
      {
        '@context': 'https://schema.org',
        '@type': 'OpeningHoursSpecification',
        'dayOfWeek': 'Sunday',
        'opens': 'Closed'
      },
      {
        '@context': 'https://schema.org',
        '@type': 'OpeningHoursSpecification',
        'dayOfWeek': 'Monday',
        'opens': '9:00 am - 18:00 pm'
      },
      {
        '@context': 'https://schema.org',
        '@type': 'OpeningHoursSpecification',
        'dayOfWeek': 'Tuesday',
        'opens': '9:00 am - 18:00 pm'
      },
      {
        '@context': 'https://schema.org',
        '@type': 'OpeningHoursSpecification',
        'dayOfWeek': 'Wednesday',
        'opens': '9:00 am - 18:00 pm'
      },
      {
        '@context': 'https://schema.org',
        '@type': 'OpeningHoursSpecification',
        'dayOfWeek': 'Thursday',
        'opens': '9:00 am - 18:00 pm'
      },
      {
        '@context': 'https://schema.org',
        '@type': 'OpeningHoursSpecification',
        'dayOfWeek': 'Friday',
        'opens': '9:00 am - 18:00 pm'
      }
    ],
    'sameAs': [
      'https://www.facebook.com/Notaio-Treviso-Studio-Notarile-Falcinelli-108508261876163/',
      'https://www.instagram.com/notaiotreviso/',
      'https://www.linkedin.com/company/notaio-treviso-studio-notarile-falcinelli/',
      'https://www.youtube.com/channel/UCe-V7fjNLFSxcrMCrW_FTNA/featured',
      'https://notaiotreviso.business.site/',
      'https://www.notaiofalcinelli.com/',
      'https://www.notaiofalcinelli.it/',
      'https://www.notaio-treviso.it/',
      'https://www.notaio-treviso.com/',
      'https://api.whatsapp.com/send/?phone=3904221628653',

    ],
    'geo': {
        '@type': 'GeoCoordinates',
        'longitude': '45.66734871828549',
        'latitude': '12.243494082208693'
      },
    'hasMap': 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2788.177937920897!2d12.241302715564292!3d45.66732807910399!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477937d50d9e2ead%3A0x65a7e8e932c7ec2e!2sVia%20Cornarotta%2C%205%2C%2031100%20Treviso%20TV!5e0!3m2!1sit!2sit!4v1645958186830!5m2!1sit!2sit',
    'contactPoint': {
      '@type': 'ContactPoint',
      'telephone': '+39 04221628653',
      'contactType': 'Customer Service'
    }
  }
  
  export const organization = {
    '@context': 'httpsssss://schema.org/',
    '@type': 'Organization',
    'name': 'Studio notarile Falcinelli - Notaio Andrea Falcinelli - Treviso',
    'description': 'Lo studio notarile Falcinelli ha sede in Via di Cornarotta n°5 a Treviso (a pochi metri da Piazza del Duomo, a 200 metri dalla Fontana delle Tette, a 250 metri da Piazza dei Signori). Contatta lo Studio Notarile Falcinelli per il tuo atto notarile a Treviso e provincia: mutui, atti societari, atti di compravendita, trust, successioni.',
    'url': ad_origin,
    'logo': `${ad_origin}/logo_one.png`,
    'aggregateRating': {
      '@type': 'AggregateRating',
      'ratingValue': '5',
      'ratingCount': '5',
      'reviewCount': '54'
    },
    'review': {
      '@type': 'Review',
      'author': {
        '@type': 'Person',
        'name': 'Ludovica Minacci'
      },
      'datePublished': '2022-05-27',
      'reviewRating': {
        '@type': 'Rating',
        'ratingValue': '5',
        'bestRating': '5',
        'worstRating': '1'
      },
      'reviewBody': 'Notaio gentile e disponibile. Servizio veramente eccellente.'
    }
  }
  
  export const review = {
    '@context': 'https://schema.org',
    '@type': 'Review',
    'itemReviewed': {
      '@type': 'Organization',
      'name': 'Studio notarile Falcinelli - Notaio Andrea Falcinelli - Notaio Treviso'
    },
    'reviewRating': {
      '@type': 'Rating',
      'ratingValue': '5',
      'bestRating': '5',
      'worstRating': '1'
    },
    'name': 'Nicoletta M',
    'author': {
      '@type': 'Person',
      'name': 'Nicoletta M'
    },
    'reviewBody': 'Notaio molto preparato, gentile e disponibile. Servizio super efficiente.',
    'datePublished': '2022-06-20',
    'publisher': {
      '@type': 'Organization',
      'name': 'Studio notarile Falcinelli - Notaio Andrea Falcinelli - Notaio Treviso'
    }
  }
  
  export const breadcrubmList = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    'itemListElement': [
      {
        '@type': 'ListItem',
        'position': 1,
        'item': {
          '@id': ad_origin,
          'name': 'Home'
        }
      },
      {
        '@type': 'ListItem',
        'position': 2,
        'item': {
          '@id': `${ad_origin}/area-riservata`,
          'name': 'Area Riservata'
        }
      },
						{
        '@type': 'ListItem',
        'position': 3,
        'item': {
          '@id': `${ad_origin}/servizi`,
          'name': 'Servizi'
        }
      },
						{
        '@type': 'ListItem',
        'position': 4,
        'item': {
          '@id': `${ad_origin}/servizi/aziende_e_societ%C3%A0`,
          'name': 'Aziende e società'
        }
      },
						{
        '@type': 'ListItem',
        'position': 5,
        'item': {
          '@id': `${ad_origin}/servizi/compravendita`,
          'name': 'Compravendita'
        }
      },
						{
        '@type': 'ListItem',
        'position': 6,
        'item': {
          '@id': `${ad_origin}/servizi/contratto_di_rete`,
          'name': 'Contratto di rete'
        }
      },
						{
        '@type': 'ListItem',
        'position': 7,
        'item': {
          '@id': `${ad_origin}/donazioni`,
          'name': 'Donazioni'
        }
      },
						{
        '@type': 'ListItem',
        'position': 8,
        'item': {
          '@id': `${ad_origin}/enti_no-profit`,
          'name': 'Enti no profit'
        }
      },
						{
        '@type': 'ListItem',
        'position': 9,
        'item': {
          '@id': `${ad_origin}/servizi/eredit%C3%A0_e_testamento`,
          'name': 'Eredità e testamento'
        }
      },
						{
        '@type': 'ListItem',
        'position': 10,
        'item': {
          '@id': `${ad_origin}/servizi/leasing`,
          'name': 'Leasing'
        }
      },
						{
        '@type': 'ListItem',
        'position': 11,
        'item': {
          '@id': `${ad_origin}/mutuo`,
          'name': 'Mutuo'
        }
      },
						{
        '@type': 'ListItem',
        'position': 12,
        'item': {
          '@id': `${ad_origin}/servizi/patto_di_famiglia`,
          'name': 'Patto di famiglia'
        }
      },
						{
        '@type': 'ListItem',
        'position': 13,
        'item': {
          '@id': `${ad_origin}/servizi/persone_e_famiglia`,
          'name': 'Persone e famiglia'
        }
      },
						{
        '@type': 'ListItem',
        'position': 14,
        'item': {
          '@id': `${ad_origin}/servizi/rent_to_buy`,
          'name': 'Rent to buy'
        }
      },
						{
        '@type': 'ListItem',
        'position': 15,
        'item': {
          '@id': `${ad_origin}/servizi/testamento_di_vita`,
          'name': 'Testamento di vita'
        }
      },
						{
        '@type': 'ListItem',
        'position': 16,
        'item': {
          '@id': `${ad_origin}/servizi/trust_e_affidamento_fiduciario`,
          'name': 'Trust e affidamento fiduciario'
        }
      },
						{
        '@type': 'ListItem',
        'position': 17,
        'item': {
          '@id': `${ad_origin}/servizi/tutela_del_patrimonio`,
          'name': 'Tutela del patrimonio'
        }
      },
						{
        '@type': 'ListItem',
        'position': 18,
        'item': {
          '@id': `${ad_origin}/servizi/unioni_civili_e_convivenze`,
          'name': 'Unioni civili e convivenze'
        }
      },
    ]
  }
  
  export const video = {
    '@context': 'https://schema.org',
    '@type': 'VideoObject',
    'name': 'Studio notarile Falcinelli - Notaio Andrea Falcinelli - Treviso | Notaio a Treviso | Notaio Falcinelli | mutui, atti societari, atti di compravendita, trust, successioni',
    'description': "Lo studio notarile Falcinelli offre un servizio su misura competente, celere e improntato alla soluzione dei problemi della clientela. Si trova a 50 metri dal Duomo di Treviso, accanto alla Fondazione Benetton e al Comando provinciale dei Carabinieri. Offre servizi e consulenza in ambito di trust, affidamento fiduciario, compravendita, mutuo, rent to buy, donazioni, unioni civili, testamenti, successioni, patto di famiglia, tutela del patrimonio, società, azienda, enti no profit, leasing, vidimazioni e copie.",
    'thumbnailUrl': 'https://www.youtube.com/watch?v=-A9tAyLrN8Q',
    'uploadDate': '',
    'duration': '',
    'publisher': {
      '@type': 'Organization',
      'name': 'Notaio Treviso - Studio Notarile Falcinelli',
      'logo': {
        '@type': 'ImageObject',
        'url': `${{ad_origin}}/logo_one.png`,
        'width': 802,
        'height': 764
      }
    },
    'contentUrl': 'https://www.youtube.com/watch?v=-A9tAyLrN8Q',
    'embedUrl': "https://www.youtube.com/channel/UCe-V7fjNLFSxcrMCrW_FTNA",
    'interactionCount': '100'
  }

export const notarySchema = 
{
	"@context": "https://www.schema.org",
	"@type": ["Organization", "LocalBusiness","Notary"],
	"@id": "#NotaioTreviso",
	"image": `${ad_origin}/logo_one.png`,
	"name": "Studio Notarile Falcinelli | Treviso",
	"legalName" : "Studio Notarile Falcinelli",
	"alternateName": "Notaio Andrea Falcinelli",
	"url": `${ad_origin}`,
	"mainEntityOfPage": {
		"@type": "WebPage",
		"@id": `${ad_origin}`
	},
	"logo": `${ad_origin}/logo_one.png`,
	"telephone": "+39 04221628653",
	"description": "Lo studio notarile Falcinelli ha sede in Via di Cornarotta n°5 a Treviso (a pochi metri da Piazza del Duomo, a 200 metri dalla Fontana delle Tette, a 250 metri da Piazza dei Signori). Contatta lo Studio Notarile Falcinelli per il tuo atto notarile a Treviso e provincia: mutui, atti societari, atti di compravendita, trust, successioni.",
	'priceRange': 'Affordable',
	"openingHours": "Mo,Tu,We,Th,Fr 09:00-18:00",
	"paymentAccepted":"Visa, Master Card, ApplePay, AndroidPay",
	'address': {
    '@type': 'PostalAddress',
    'streetAddress': 'via Cornarotta 5',
    'addressLocality': 'Treviso',
    'addressRegion': 'Veneto',
    'postalCode': '31100',
    'addressCountry': 'IT'
  },
  'geo': {
    '@type': 'GeoCoordinates',
    'longitude': '45.66734871828549',
    'latitude': '12.243494082208693'
  },
	"contactPoint": [
		{ 
			"@type": "ContactPoint",
			"telephone": "+39 04221628653",
			"contactType": "customer service",
			"email": "info@notaiofalcinelli.it",
			"areaServed": [
				"IT",
			],
			"availableLanguage": [
        "Italian",
        "English",
				"French"				
			]
		}
	],
	'sameAs': [
    'https://www.notaiofalcinelli.com/',
    'https://www.notaiofalcinelli.it/',
    'https://www.notaio-treviso.it/',
    'https://www.notaio-treviso.com/',
    'https://www.facebook.com/Notaio-Treviso-Studio-Notarile-Falcinelli-108508261876163/',
    'https://www.instagram.com/notaiotreviso/',
    'https://www.linkedin.com/company/notaio-treviso-studio-notarile-falcinelli/',
    'https://www.youtube.com/channel/UCe-V7fjNLFSxcrMCrW_FTNA/featured',
    'https://notaiotreviso.business.site/',
  ],
	"makesOffer":[
		{
			"@type":"Offer",
			"itemOffered":
				{
					"@type":"Service",
					"name":"Trust",
					"url":"https://www.notaio-treviso.com/servizi/trust_e_affidamento_fiduciario"
				}
			},
			{
				"@type":"Offer",
				"itemOffered":
				{
					"@type":"Service",
					"name":"Compravendita",
					"url":"https://www.notaio-treviso.com/servizi/compravendita"
				}
			},
      {
				"@type":"Offer",
				"itemOffered":
				{
					"@type":"Service",
					"name":"Mutuo",
					"url":"https://www.notaio-treviso.com/servizi/mutuo"
				}
			},
      {
				"@type":"Offer",
				"itemOffered":
				{
					"@type":"Service",
					"name":"Rent to buy",
					"url":"https://www.notaio-treviso.com/servizi/rent_to_buy"
				}
			},
      {
				"@type":"Offer",
				"itemOffered":
				{
					"@type":"Service",
					"name":"Persone e famiglia",
					"url":"https://www.notaio-treviso.com/servizi/persone_e_famiglia"
				}
			},
      {
				"@type":"Offer",
				"itemOffered":
				{
					"@type":"Service",
					"name":"Unione civile e convivenza",
					"url":"https://www.notaio-treviso.com/servizi/unioni_civili_e_convivenze"
				}
			},
      {
				"@type":"Offer",
				"itemOffered":
				{
					"@type":"Service",
					"name":"Eredità e Testamento",
					"url":"https://www.notaio-treviso.com/servizi/eredit%C3%A0_e_testamento"
				}
			},
      {
				"@type":"Offer",
				"itemOffered":
				{
					"@type":"Service",
					"name":"Testamento di vita",
					"url":"https://www.notaio-treviso.com/servizi/testamento_di_vita"
				}
			},
      {
				"@type":"Offer",
				"itemOffered":
				{
					"@type":"Service",
					"name":"Donazioni",
					"url":"https://www.notaio-treviso.com/servizi/donazioni"
				}
			},
      {
				"@type":"Offer",
				"itemOffered":
				{
					"@type":"Service",
					"name":"Patto di famiglia",
					"url":"https://www.notaio-treviso.com/servizi/patto_di_famiglia"
				}
			},
      {
				"@type":"Offer",
				"itemOffered":
				{
					"@type":"Service",
					"name":"Affidamento fiduciario",
					"url":"https://www.notaio-treviso.com/servizi/trust_e_affidamento_fiduciario",
				}
			},
      {
				"@type":"Offer",
				"itemOffered":
				{
					"@type":"Service",
					"name":"Aziende",
					"url":"https://www.notaio-treviso.com/servizi/aziende_e_societ%C3%A0",
				}
			},
      {
				"@type":"Offer",
				"itemOffered":
				{
					"@type":"Service",
					"name":"Società",
          "url":"https://www.notaio-treviso.com/servizi/aziende_e_societ%C3%A0"
				}
			},
      {
				"@type":"Offer",
				"itemOffered":
				{
					"@type":"Service",
					"name":"Contratto di rete",
					"url":"https://www.notaio-treviso.com/servizi/contratto_di_rete"
				}
			},
      {
				"@type":"Offer",
				"itemOffered":
				{
					"@type":"Service",
					"name":"Enti no-profit",
					"url":"https://www.notaio-treviso.com/servizi/enti_no-profit"
				}
			},
      {
				"@type":"Offer",
				"itemOffered":
				{
					"@type":"Service",
					"name":"Leasing",
					"url":"https://www.notaio-treviso.com/servizi/leasing"
				}
			}
	],
	"location": [
		{
			"@type": "PostalAddress",
			"telephone": "+39 04221628653",
			'streetAddress': 'via Cornarotta 5',
      'addressLocality': 'Treviso',
      'addressRegion': 'Veneto',
			"addressCountry": "Italy",
			"postalCode": "31100",
			"serviceArea":
				[
					{
						"@type": "PostalAddress",
						"addressLocality":"Altivole",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31030"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Arcade",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31030"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Asolo",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31011"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Borso del Grappa",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31030"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Breda di Piave",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31030"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Caerano di San Marco",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31031"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Cappella Maggiore",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31012"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Carbonera",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31030"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Casale sul Sile",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31032"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Casier",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31030"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Castelcucco",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31030"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Castelfranco Veneto",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31033"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Castello di Godego",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31030"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Cavaso del Tomba",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31034"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Cessalto",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31040"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Chiarano",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31040"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Cimadolmo",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31010"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Cison di Valmarino",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31030"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Codognè",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31013"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Colle Umberto",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31014"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Conegliano",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31015"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Cordignano",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31016"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Cornuda",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31041"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Crocetta del Montello",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31035"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Farra di Soligo",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31010"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Follina",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31051"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Fontanelle",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31043"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Fonte",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31010"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Fregona",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31010"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Gaiarine",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31018"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Giavera del Montello",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31040"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Godega di Sant'Urbano",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31010"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Gorgo al Monticano",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31040"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Istrana",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31036"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Loria",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31037"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Mansuè",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31040"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Mareno di Piave",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31010"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Maser",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31010"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Maserada sul Piave",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31052"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Meduna di Livenza",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31040"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Miane",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31050"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Mogliano Veneto",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31021"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Monastier di Treviso",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31050"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Monfumo",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31010"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Montebelluna",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31044"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Morgano",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31050"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Moriago della Battaglia",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31010"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Comune",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"CAP"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Motta di Livenza",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31045"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Nervesa della Battaglia",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31040"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Oderzo",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31046"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Ormelle",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31024"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Orsago",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31010"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Paese",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31038"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Pederobba",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31040"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Pieve del Grappa",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31017"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Pieve di Soligo",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31053"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Ponte di Piave",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31047"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Ponzano Veneto",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31050"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Portobuffolè",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31040"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Possagno",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31054"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Povegliano",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31050"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Preganziol",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31022"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Quinto di Treviso",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31055"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Refrontolo",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31020"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Resana",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31023"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Revine Lago",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31020"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Riese Pio X",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31039"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Roncade",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31056"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Salgareda",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31040"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"San Biagio di Callalta",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31048"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"San Fior",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31020"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"San Pietro di Feletto",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31020"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"San Polo di Piave",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31020"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"San Vendemiano",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31020"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"San Zenone degli Ezzelini",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31020"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Santa Lucia di Piave",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31025"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Sarmede",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31026"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Segusino",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31040"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Sernaglia della Battaglia",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31020"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Silea",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31057"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Spresiano",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31027"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Susegana",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31058"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Tarzo",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31020"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Trevignano",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31040"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Treviso",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31100"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Valdobbiadene",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31049"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Vazzola",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31028"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Vedelago",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31050"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Vidor",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31020"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Villorba",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31020"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Vittorio Veneto",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31029"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Volpago del Montello",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31040"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Zenson di Piave",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31050"
					},
          {
						"@type": "PostalAddress",
						"addressLocality":"Zero Branco",
						"addressRegion": "Veneto",
            "addressCountry": "Italy",
            "postalCode":"31059"
					},
				]
		},
		{
			"@type": "PostalAddress",
			"telephone": "+39 04221628653",
			'streetAddress': 'via Cornarotta 5',
      'addressLocality': 'Treviso',
      'addressRegion': 'Veneto',
			"addressCountry": "Italy",
			"postalCode": "31100",
		}
	],
	"aggregateRating": {
			"@type":"AggregateRating",
			"ratingValue":"9.3",
			"bestRating":"10",
			"reviewCount":"719",
			"itemReviewed": "#NotaioTreviso"
	},
	"potentialAction": {
		"@type": "OrderAction",
		"target":
		{
			"@type": "EntryPoint",
			"name":"Contatti",
			"urlTemplate":`${ad_origin}/#contact`,
			"inLanguage": "it-IT",
			"actionPlatform":
			[
				"https://schema.org/DesktopWebPlatform",
				"https://schema.org/IOSPlatform",
				"https://schema.org/AndroidPlatform"
			]
		},
		"result":
		{
			"@type": "Reservation",
			"name": "Richiedi un preventivo"
		}
	},
	"foundingDate": "2022",
	"founders": [
		{
			"@type": "Person",
			"name": "Andrea Falcinelli",
			"worksFor": "Notaio pubblico",
			"email": "mailto:info@notaiofalcinelli.it",
			"image": "johndoe.jpg",
			"jobTitle": "Partner",
			"alumniOf": "LUISS Guido Carli",
			"birthPlace": "Assisi, PG",
			"birthDate": "1988.12.30",
			"gender": "male",
			"nationality": "Italian",
			"telephone": "+39 04221628653",
			"url": "https://it.linkedin.com/in/andrea-falcinelli-1a9b4a68",
			"address": {
				"@type": "PostalAddress",
				"addressLocality": "Treviso",
				"addressRegion": "Veneto",
				"postalCode":"31100",
				"streetAddress": "Via Cornarotta 5"
			},
			'sameAs': [
        'https://www.notaiofalcinelli.com/',
        'https://www.notaiofalcinelli.it/',
        'https://www.notaio-treviso.it/',
        'https://www.notaio-treviso.com/',
        'https://www.facebook.com/Notaio-Treviso-Studio-Notarile-Falcinelli-108508261876163/',
        'https://www.instagram.com/notaiotreviso/',
        'https://www.linkedin.com/company/notaio-treviso-studio-notarile-falcinelli/',
        'https://www.youtube.com/channel/UCe-V7fjNLFSxcrMCrW_FTNA/featured',
        'https://notaiotreviso.business.site/',
      ],
		},
	]
}

				