import React from 'react';
import { useParams } from 'react-router-dom';

import Seo from '../../components/seo/Metatags';
import dynamic from 'next/dynamic'

import AutocertificazioniDettagli_contenuto from './contenuto';

import Data from '../../components/Autocertificazioni/data/output';

import './styles.css';


const Intro = dynamic(
  () => import('../../components/Intro/index'),
  { ssr: false }
)

const BackToTop = dynamic(
  () => import('../../components/back-top'),
  { ssr: false }
)

const AutocertificazioniDettagli = props => {

  const { autocertificazioniID } = useParams();
  
  const location_origin = typeof window !== "undefined" ? `${window.location.href.toString()}` : "https://www.notaio-treviso.com"
  const location_host = typeof window !== "undefined" ? `${window.location.host.toString()}` : "www.notaio-treviso.com"

  return (
    <div>
      {Data.map(autocert => {
        // console.log(servizioID, servizio.link)
        if (autocertificazioniID === autocert.link)
          return (
            <div key={autocert.id}>
              {/* metadati SEO */}
              <Seo
                title_par={`Autocertificazione ${autocert.name} | Notaio a Treviso`}
                description_par={`${autocert.description}`}
                type_par="article"
                ad_host={location_host}
                ad_origin={location_origin}
                link_page={location_origin}
                keywords_par={`${autocert.name}, Treviso, servizio notarile, notaio a Treviso, notaio, notai treviso`}
                isArticle={'vero'}
                art_titleData={autocert.name}
                art_descriptionData={autocert.description}
                art_imagesData={autocert.imageArt}
                art_imagesData_caption={`${autocert.name} - notaio a Treviso`}
                art_keywordsData={autocert.name}
                art_dateCreationData="2022-05-07T10:10:54+00:00"
                art_dateLastActivityData="2022-05-07T10:30:54+00:00"
                art_shortDescriptionData={autocert.description}
                art_urlData={location_origin}
              />

              <React.Fragment>
                <Intro 
                    title={autocert.name} 
                    subtitle={`Autocertificazione fornita dallo Studio Notarile Falcinelli`} 
                    contents={`${autocert.description}<br/><a className='italicIntro' title='notaio treviso' href='https://www.notaio-treviso.com/'>NOTAIO TREVISO</a>`}
                    linkSpinner={'divAutocertDett'}
                />
                <AutocertificazioniDettagli_contenuto autocert={autocert} />
                <BackToTop />
              </React.Fragment>

            </div>
          );
      }
      )}
    </div>
  );
};

export default AutocertificazioniDettagli;

