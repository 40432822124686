import React from 'react';
import { useHistory } from "react-router-dom";

const menuItem = [
  {
    id: 1,
    name: "Approfondisci e ricerca",
    link: "/servizi",
  },
  // {
  //   id: 2,
  //   name: "Richiesta preventivo",
  //   link: "/servizi/preventivi",
  // },
  // {
  //   id: 3,
  //   name: "Richiesta copie atti",
  //   link: "/copie_atti",
  // },
  // {
  //   id: 4,
  //   name: "Richiesta consulenza",
  //   link: "/servizi/consulenza",
  // },
  {
    id: 5,
    name: "Documenti per la stipula",
    link: "/servizi/documenti-stipula",
  },
  {
    id: 6,
    name: "Autocertificazioni",
    link: "/servizi/autocertificazioni",
  },
]

const ServiziMenu = () => {
  let history = useHistory();
  // console.log(history)
  return (
    <div className='boxMen'>
      <h2 className='titleTop'>Servizi</h2>
      <div className='box_services'>
        {menuItem.map((item) => {
          return (
            <h3 key={item.id} className='menuItem'>
              <a title={`${item.name} | Notaio a Treviso`} className='a_link_custom' href={item.link}>{item.name}</a>
            </h3>
          );
        }
        )}
      </div>
    </div>
  );
}

export default ServiziMenu;
