import React, { Component } from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../../components/Footer'
import dynamic from 'next/dynamic'
import Container from 'react-bootstrap/Container';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SocialShare from '../../components/servizi/SocialShare';

import ServiziMenu from '../../components/servizi/ServiziMenu';
// import AutocertificazioniCorpus from '../AutocertificazioniCorpus';

import Data from '../../components/Autocertificazioni/data/output';
import { useMediaQuery } from 'react-responsive';
import ContenutoCorpus from './contenutoCorpus';
import './styles.css';

const AutocertificazioniCorpus = dynamic(
  () => import('../../components/AutocertificazioniCorpus'),
  { ssr: false }
)

const TagComponent = dynamic(
    () => import('../../components/tags/index'),
    { ssr: false }
)

const AutocertificazioniDettagli_contenuto = ({ autocert }) => {
  
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  const isSmallScreen = useMediaQuery({ query: '(max-width: 990px)' })
 
  const location_origin = typeof window !== "undefined" ? `${window.location.origin.toString()}` : `https://www.notaio-treviso.com`
  const location_host = typeof window !== "undefined" ? `${window.location.host.toString()}` : "www.notaio-treviso.com"


  return (
   <>
        { isSmallScreen ?
            <section
                className="paralax-mf footer-paralax bg-image route secBack"
                id='divAutocertDett'
            >
                <Container style={{ textAlign: '-webkit-center', marginTop: "65px" }} fluid="md">
                <Row style={{display: 'table-row', textAlignLast: 'center'}}>
                    <Row style={{justifyContent: 'center'}}>
                        <AutocertificazioniCorpus autocertificazioniID={autocert.link} isDesktopOrLaptop={isDesktopOrLaptop} isSmallScreen={isSmallScreen}/>
                    </Row>
                    <ServiziMenu/>
                    <Row>
                        <SocialShare urlSpec={`${location_origin}/servizi/autocertificazioni/${autocert.link}`} />
                    </Row>
                    <Row>
                        <ContenutoCorpus autocert={autocert}/>
                    </Row>
                    <div className='boxAltrecert'>
                        <h2 className='titleTop' >Altre autocertificazioni</h2>
                        {Data.map( element => {
                        return (
                            <h3 key={element.id} className='menuItem'><a className='a_link_custom' href={`${location_origin}/servizi/autocertificazioni/${element.link}`}>{element.name}</a></h3>
                        );
                        }
                        )}
                    </div>
                </Row>
                </Container>

                <TagComponent />
                <Footer />
            </section>
            :
            <section
                className="paralax-mf footer-paralax bg-image route secBack"
                id='divAutocertDett'
            >
                <Container style={{ marginTop: "65px" }} fluid="md">
                <Row>
                    <Col className='col-md-8'>
                        <AutocertificazioniCorpus autocertificazioniID={autocert.link} isDesktopOrLaptop={isDesktopOrLaptop} isSmallScreen={isSmallScreen}/>
                    </Col>
                    <Col className='col-md-4'>
                        <ServiziMenu/>
                        <div className='boxAltrecert'>
                        <h2 className='titleTop' >Altre autocertificazioni</h2>
                        {Data.map( element => {
                            return (
                            <h3 key={element.id} className='menuItem'><a className='a_link_custom' href={`${location_origin}/servizi/autocertificazioni/${element.link}`}>{element.name}</a></h3>
                            );
                            }
                        )}
                        </div>
                    </Col>
                    <Col className='col-md-8'>
                        <SocialShare urlSpec={`${location_origin}/servizi/autocertificazioni/${autocert.link}`} />
                        <ContenutoCorpus autocert={autocert}/>
                    </Col>
                </Row>
                </Container>

                <TagComponent />
                <Footer />
            </section>
        }
    </>
  );
};

export default AutocertificazioniDettagli_contenuto;

