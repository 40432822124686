
const infografiche = [
    {
        id: 0,
        name: "Prezzo-Valore",
        pdf_sub: "I vantaggi della trasparenza nelle vendite immobiliari",
        author: "Consiglio Nazionale del Notariato",
        link:"prezzo-valore",
        pdfLink:"https://notariato.it/wp-content/uploads/9.Infografica_PREZZO-VALORE_-1.pdf",
        cnnlink: "https://notariato.it/it/guida/infografiche-e-videografiche-prezzo-valore/",
        imageArt: "https://notariato.it/wp-content/uploads/Immagini_infografiche_Tavola-disegno-1-copia-4.png",
        deskheight: '385px',
        deskbottom: '477px',
        deskheightBot: '70px',
        deskheightBotFr: '475px',
        mobileheight: '385px',
        mobilebottom: '431px',
        mobileheightBot: '58px',
        mobileheightBotFr: '480px',
        content: `
        <h4><strong>Cos’è il “prezzo-valore”</strong></h4>
        <p>Dal 1° gennaio 2006 è possibile regolare fiscalmente alcuni trasferimenti immobiliari al valore catastale, indipendentemente dal corrispettivo pattuito e indicato.</p>
        <p>La norma è ispirata ad una logica di trasparenza nella contrattazione immobiliare e di equità nel prelievo fiscale: consente la tassazione del trasferimento degli immobili sulla base del loro valore catastale e, al contempo, l’emersione dei valori reali delle compravendite.</p>

        <h4>A chi si applica?</h4>
        <p>A cessioni soggette ad imposta di Registro, nei confronti di persone fisiche che non agiscano nell’esercizio di attività commerciali, artistiche o professionali.</p>

        <h4>Quali beni possono beneficiarne?</h4>
        <p>Immobili ad uso abitativo e relative pertinenze (box, cantine ecc. senza limitazione di numero) anche se acquistate separatamente dal bene principale ma a questo pertinenziate.
        </p>
        <h4>Quando si applica?</h4>
        <p>In caso di atti negoziali traslativi del diritto di proprietà e/o di godimento.
        </p>
        <p>A seguito di espropriazione forzata o pubblico incanto.
        </p>
        `
    },
    {
        id: 1,
        name: "Garanzia preliminare",
        pdf_sub: "Sicurezza nel contratto di compravendita immobiliare",
        author: "Consiglio Nazionale del Notariato",
        link:"garanzia-preliminare",
        pdfLink:"https://notariato.it/wp-content/uploads/3.-Infografica_garanziaPreliminare_-2.pdf",
        cnnlink: "https://notariato.it/it/guida/infografiche-e-videografiche-garanzia-preliminare/",
        imageArt: "https://notariato.it/wp-content/uploads/Immagini_infografiche_Tavola-disegno-1-copia-3.png",
        deskheight: '275px',
        deskbottom: '475px',
        deskheightBot: '98px',
        deskheightBotFr: '374px',
        mobileheight: '284px',
        mobilebottom: '341px',
        mobileheightBot: '84px',
        mobileheightBotFr: '377px',
        content: `
        <h4><strong>Il contratto preliminare o “compromesso”</strong></h4>
        <p>Il preliminare di vendita stabilisce modalità e termini con cui venditore e acquirente si obbligano a concludere una compravendita.</p>

        <p>La proposta d'acquisto, invece, è la dichiarazione dell’acquirente di voler acquistare un bene a un certo prezzo, che impegna solo chi l’ha firmata.</p>

        <p>Particolare tipo di proposta è il patto di opzione, in cui:</p>
        <p>
        <li>la parte promittente è obbligata e vincolata a stipulare il contratto definitivo</li>
        <li>l’altra parte ha il diritto di accettare o meno (entro un termine fissato)</li>
        </p>

        <h4><strong>Acquisto sicuro: la trascrizione del preliminare</strong></h4>
        <p>Il preliminare ha effetto solo tra le parti.</p>

        <p>Nonostante la sottoscrizione del preliminare, il venditore potrebbe:</p>
        <p>
        <li>vendere l‘immobile ad altre persone</li>
        <li>scrivere ipoteche sull’immobile</li>
        </p>

        <p><strong>Come evitare queste spiacevoli possibilità?</strong></p>
        <p>
        Con la trascrizione del preliminare, grazie alla quale tutti gli atti fatti o "subiti" dal venditore non pregiudicano i diritti dell'acquirente.
        </p>
        <p>
        <strong>La trascrizione è obbligatoria nel caso di</strong> contratti preliminari aventi ad oggetto immobili da costruire promessi in vendita a persone fisiche da un costruttore, la cui costruzione sia abilitata da titoli richiesti/presentati a partire dal 16 marzo 2019.
        </p>

        <p><strong>In cosa consiste la trascrizione del preliminare?</strong></p>
        <p>
        La trascrizione è l’inserimento del contratto nei Registri Immobiliari: il notaio deve eseguirla entro 30 giorni dalla stipula del preliminare.</p>

        <h4><strong>Il contenuto del compromesso</strong></h4>
        <p>
        <li>Prezzo del bene e modalità di pagamento</li>
        <li>Indicazione dell’immobile con i dati del Catasto</li>
        <li>Termine per la stipula del rogito definitivo</li>
        <li>Certificazione energetica: obbligo di attestato di prestazione energetica anche per il preliminare di vendita.</li>
        <li>In caso di “irregolarità catastale” il venditore ha l'obbligo di procedere alla regolarizzazione</li>
        <li>È opportuno inserire anche un’apposita clausola per disciplinare il rapporto fra le parti in caso di vizi o di mancanza di qualità previste negli immobili considerati</li>
        </p>

        <h4><strong>La riserva di nomina</strong></h4>
        <p>Non sempre la parte promissaria acquirente è a conoscenza di chi sarà il futuro compratore.
        </p>
        <p>È opportuno inserire nel contratto preliminare un’apposita clausola così che la vendita possa essere conclusa anche con diverso acquirente</p>
        `
    },
    {
        id: 2,
        name: "Acquisto in costruzione",
        pdf_sub: "Gli immobili dopo il 16 marzo 2019",
        author: "Consiglio Nazionale del Notariato",
        link:"acquisto-in-costruzione",
        pdfLink:"https://notariato.it/wp-content/uploads/4.Infografica_acquistoCostruzione_-2.pdf",
        cnnlink: "https://notariato.it/it/guida/infografiche-e-videografiche-acquisto-in-costruzione/",
        imageArt: "https://notariato.it/wp-content/uploads/Immagini_infografiche_Tavola-disegno-1-copia-6.png",
        deskheight: '410px',
        deskbottom: '475px',
        deskheightBot: '90px',
        deskheightBotFr: '500px',
        mobileheight: '414px',
        mobilebottom: '477px',
        mobileheightBot: '57px',
        mobileheightBotFr: '507px',
        content: `
        <h4><strong>Le fonti normative</strong></h4>
        <p>
        D.Lgs. 122/2005, entrato in vigore il 21 luglio 2005 e, a partire dal 16 marzo 2019, modificato dal D.Lgs 14/2019.
        </p>
        <h4><strong>La nuova disciplina</strong></h4>
        <p>
        Si applica solo agli immobili da costruire per i quali il titolo abilitativo edilizio sia stato richiesto o presentato a partire dal 16 marzo 2019.
        </p>
        <p><strong>N.B.</strong>Importante individuare la data di richiesta del permesso di costruire
        o di presentazione di titoli alternativi.</p>
      
        <h4><strong>I requisiti</strong></h4>
        <p>Soggettivi
        <li>Il venditore deve essere un costruttore (persona fisica o società), non un privato.</li>
        <li>L’acquirente deve essere una persona fisica.</li>
        </p>
        <p>Oggettivi
        <li>Qualunque tipo di fabbricato (es.: appartamenti, ville, negozi, laboratori)</li>
        li>La costruzione non deve essere ancora ultimata: deve essere ancora in stadio tale da non consentire l’invio della S.C.AGI. (art. 24 D.P.R. 6 giugno 2001, n. 380)
        </li>
        </p>

        <h4><strong>Strumenti di tutela obbligatori previsti dalla nuova disciplina</strong></h4>
        <p>
        </p>
        <h5><strong>INTERVENTO DEL NOTAIO</strong></h5>
        <p>
        Consente l'informazione preventiva delle le parti e la verifica del rispetto della disciplina.
        </p>
        <h5><strong>FIDEIUSSIONE</strong></h5>
        <p>
        Consente l'informazione preventiva delle le parti e la verifica del rispetto della disciplina.
        </p>
        <h5><strong>POLIZZA ASSICURATIVA INDENNITARIA DECENNALE</strong></h5>
        <p>
        Rilasciata dal costruttore a beneficio dell'acquirente, copre i danni materiali e diretti all'immobile derivanti da rovina (totale o parziale) o da gravi difetti di costruzione manifestatisi successivamente alla stipula del contratto definitivo.
        </p>
        <h5><strong>TRASCRIZIONE DEL PRELIMINARE</strong></h5>
        <p>Grazie all’intervento del notaio, permette all’acquirente di proteggere il suo acquisto con il c.d. effetto prenotativo e di godere del privilegio speciale (ex art. 2775-bis c.c) nella ripetizione delle somme versate al costruttore.</p>
        `
    },
    {
        id: 3,
        name: "Acquisto certificato",
        pdf_sub: "Agibilità, sicurezza ed efficienza energetica degli immobili",
        author: "Consiglio Nazionale del Notariato",
        link:"acquisto-certificato",
        pdfLink:"https://notariato.it/wp-content/uploads/15.-Infografica-Acquisto-certificato-2.pdf",
        cnnlink: "https://notariato.it/it/guida/infografiche-e-videografiche-acquisto-certificato/",
        imageArt: "https://notariato.it/wp-content/uploads/Immagini_infografiche_Tavola-disegno-1-copia.png",
        deskheight: '410px',
        deskbottom: '475px',
        deskheightBot: '90px',
        deskheightBotFr: '500px',
        mobileheight: '414px',
        mobilebottom: '477px',
        mobileheightBot: '57px',
        mobileheightBotFr: '507px',
        content: `
        <p>
        Acquistare edifici “certificati” sotto il profilo igienico-sanitario, della sicurezza e del risparmio energetico è un elemento importante dal punto di vista economico e sociale.
        </p>
        <br></br>
        <h4><strong>I TRE ASPETTI DELL’ACQUISTO CERTIFICATO</strong></h4>
        <br></br>
        <h5><strong>AGIBILITÀ</strong></h5>
        <p>
        Consiste nella dichiarazione di sussistenza delle condizioni di sicurezza, igiene, salubrità e
        risparmio energetico degli edifici e degli impianti.
        </p>
        <p>
        Come si attesta l'agibilità:
        </p>
        <p>
        PRIMA DELL’ 11/12/2016
        </p>
        <li>
        con il certificato di agibilità rilasciato dal Comune
        </li>
        <li>
        con il c.d. "silenzio assenso" a seguito di istanza presentata dall’interessato
        </li>
        <p>
        A PARTIRE DALL’ 11/12/2016
        </p>
        <li>
        mediante presentazione al comune della "segnalazione certificata di inizio attività" (con questa, oltre all'agibilità va attestata anche la conformità dell'immobile al progetto presentato in comune) 
        </li>
        <br></br>
        <h5><strong>IMPIANTI E DICHIARAZIONI DI CONFORMITÀ</strong></h5>
        <p>
        Secondo la normativa vigente gli impianti esistenti negli edifici (es. impianto elettrico, impianto termico ecc.), devono essere conformi alle norme di sicurezza vigenti.
        </p>
        <p>
        Come si attesta la conformità degli impianti ?
        </p>
        <li>
        La conformità è attestata nella dichiarazione di conformità che la ditta installatrice rilascia al termine dei lavori.
        </li>
        <li>
        La dichiarazione è divenuta obbligatoria (e costituisce condizione per la dichiarazione di agibilità) a partire dal 13 marzo 1990 (legge 5 marzo 1990 n. 46).
        </li>

        <br></br>
        <h5><strong>EFFICIENZA ENERGETICA DEGLI IMMOBILI</strong></h5>
        <p>
        L'efficienza energetica della casa nei suoi consumi, può contribuire a preservare l'ambiente, consentendo al contempo un non trascurabile risparmio.
        </p>
        <p>
        Dove è indicato il grado di efficienza energetica?
        </p>
        <li>
        Il grado di efficienza risulta dall'attestato di prestazione energetica (APE), che ha lo scopo di consentire all'acquirente di conoscere la qualità di un immobile ed i suoi consumi.
        </li>
        <li>
        L'APE deve essere allegato agli atti di compravendita e agli atti di trasferimento a titolo oneroso.
        </li>

        <br></br>
        <h5><strong>COSA CHIEDERE IN FASE DI CONTRATTAZIONE</strong></h5>
        <p>
        In fase di acquisto di un immobile e già al momento della stipula del preliminare, è importante chiedere al venditore:
        </p>
        
        <li>
        LA DOCUMENTAZIONE CHE ATTESTI LA SUSSISTENZA DELL'AGIBILITÀ
        </li>
        <li>
        LE DICHIARAZIONI DI CONFORMITÀ DEGLI IMPIANTI
        </li>
        <li>
        L'ATTESTATO DI PRESTAZIONE ENERGETICA
        </li>
        <br></br>
        <p><strong>
        Cosa succede se manca l'agibilità? </strong>
        </p>
        <li>
        La mancanza dell'agibilità non incide sulla validità e quindi sulla possibilità di stipulare l’atto di compravendita. Tuttavia, la sua presenza è condizione per la concreta utilizzabilità dell'immobile e incide quindi sulla commerciabilità economica: il venditore è comunque tenuto a garantire l'agibilità.
        </li>

        <p><strong>
        Cosa succede se gli impianti non sono conformi?
        </strong>
        </p>
        <li>
        La non conformità degli impianti non incide sulla validità e quindi sulla possibilità di stipulare l’atto di compravendita. Tuttavia, il venditore è tenuto a garantire che il bene non presenta vizi e che gli impianti sono conformi.
        </li>

        <p><strong>
        Cosa succede se manca l'attestato di prestazione energetica? </strong>
        </p>
        <li>
        La mancanza dell'attestato non incide sulla validità e quindi sulla possibilità di stipulare l’atto di compravendita. La mancanza dell’attestato di prestazione energetica comporta l’applicazione di una sanzione amministrativa (da 3.000 a 18.000€).
        </li>
        `
    },
    {
        id: 4,
        name: "Acquisto all’asta",
        pdf_sub: "Dieci consigli utili per partecipare consapevolmente",
        author: "Consiglio Nazionale del Notariato",
        link:"acquisto-asta",
        pdfLink:"https://notariato.it/wp-content/uploads/10.-Infografica_Acquisto_asta.pdf",
        cnnlink: "https://notariato.it/it/guida/infografiche-e-videografiche-acquisto-allasta/",
        imageArt: "https://notariato.it/wp-content/uploads/Immagini_infografiche_Tavola-disegno-1.png",
        deskheight: '410px',
        deskbottom: '475px',
        deskheightBot: '90px',
        deskheightBotFr: '500px',
        mobileheight: '414px',
        mobilebottom: '477px',
        mobileheightBot: '57px',
        mobileheightBotFr: '507px',
        content: `
        <br></br>
        <h4>ASTE IMMOBILIARI GIUDIZIARIE</h4>
        <br></br>
        <h5>DIECI CONSIGLI UTILI PER PARTECIPARE CONSAPEVOLMENTE</h5>
        <p>Non dare per scontata la convenienza del prezzo. È possibile aggiudicarsi un immobile a un prezzo inferiore rispetto al suo valore di mercato ma conviene informarsi preventivamente sui valori di immobili simili che si trovano nella medesima zona.</p>
        <br></br>
        <h5>I TEMPI</h5>
        <p>Valutare se si abbia o meno urgenza di avere la disponibilità
        dell’immobile, i tempi per ottenere l’effettiva disponibilità non sono sempre prevedibili</p>
        <br></br>
        <h5>DOCUMENTI</h5>
        <p>È importante prendere visione dei documenti risultanti dall’avviso di vendita e di altri dettagli, disponibili su appositi siti web 45 giorni prima dell’asta. Attenzione particolare richiede la perizia, che specifica vincoli e oneri a carico dell’acquirente.</p>
        <br></br>
        <h5>VISITARE GLI IMMOBILI</h5>
        <p>La visita è indispensabile anche per comprendere gli aspetti non menzionanti nella perizia e chiedere informazioni ulteriori.
        Occorre preventivare che le condizioni dell’immobile al momento del rilascio potrebbero essere peggiori rispetto a quelle
        descritte.</p>
        <br></br>
        <h5>ASTE TELEMATICHE</h5>
        <p>Necessarie la firma digitale, una pec e una postazione informatica idonea per partecipare online alla procedura telematica.</p>
        <br></br>
        <h5>SOMMA DA INVESTIRE</h5>
        <p>È opportuno decidere prima quanto si è disposti a spendere per
        l’acquisto evitando il rischio di esporsi più del dovuto.</p>
        <br></br>
        <h5>SPESE</h5>
        <p>È consigliabile informarsi sull’importo delle spese che devono essere sostenute oltre al prezzo di aggiudicazione.
        </p>
        <br></br>
        <h5>BENEFICI FISCALI</h5>
        <p>Ai beni acquisiti con le aste immobiliari si applicano i medesimi benefici previsti per i trasferimenti immobiliari. La volontà di usufruire delle agevolazioni fiscali deve essere espressa già in sede di asta o meglio al termine della stessa.
        </p>
        <br></br>
        <h5>MODALITÀ DI PAGAMENTO</h5>
        <p>Chi acquista deve poter disporre entro il termine previsto per il saldo di tutta la cifra richiesta. Si consiglia di attivarsi per tempo con la banca per accordarsi sulle modalità di pagamento.
        </p>
        <br></br>
        <h5>DISPONIBILITÀ</h5>
        <p>Attenzione agli immobili occupati! Il decreto di trasferimento costituisce un titolo esecutivo attraverso il quale si può procedere per ottenere il rilascio dell’immobile in favore dell'aggiudicatario.
        </p>

        <br></br>
        <h4>ASTE TELEMATICHE NOTARILI</h4>
        <br></br>
        <h5>COSA SONO E COME FUNZIONANO</h5>
        <p>RAN (RETE ASTE NOTARILI)</p>
        <p>Il Consiglio Nazionale del Notariato ha strutturato un sistema qualificato di aste telematiche notarili</p>
        <li>Partecipazione a distanza attraverso studi dislocati sul territorio</li>
        <li>Sicurezza, trasparenza, accessibilità, tracciabilità</li>
        <li>Applicazione normativa antiriciclaggio</li>

        <br></br>
        <h5>I SOGGETTI COINVOLTI</h5>
        <p>OFFERENTE</p>
        <li>colui che partecipa all’asta con un’offerta</li>
        <li>Non dovrà dotarsi di mezzi informatici, perché potrà utilizzare le risorse dello studio</li>
        <p>NOTAIO (BANDITORE E PERIFERICO)</p>
        <p>Il notaio indicato nel bando d’asta e i notai periferici abilitati alla RAN provvedono a:</p>
        <li>identificare gli offerenti</li>
        <li>verificare la validità di offerte e cauzioni</li>
        <li>registrare le offerte</li>
        <li>inserire i rilanci</li>
        
        <br></br>
        <h5>IL GIORNO DELL’ASTA</h5>
        <p>Lotto immobiliare</p>
        <li>Il banditore pubblica l’avviso con i dati dell’immobile messo
        all’asta sui portali del notariato e sugli altri portali collegati</li>

        <br></br>
        <p><strong>Fase delle offerte segrete</strong></p>
        <li>
        L’offerente si reca dal banditore o da un notaio periferico abilitato per verificare la cauzione e registrare l’offerta digitale resa segreta tramite certificato con doppia cifra.
        </li>
        <li>
        Una volta depositata l’offerta non può essere ritirata e decade
        solo con l’aggiudicazione a favore di un altro offerente.
        </li>
        <li>
        In caso di più offerte sarà aperta una gara a partire dal prezzo
        più alto, con rilancio minimo.
        </li>

        <br></br>
        <p><strong>Fase della gara</strong></p>
        <li>
        I notai identificano gli offerenti e verificano la cauzione.
        </li>
        <li>
        Il banditore apre la seduta, consentendo ai notai periferici di collegarsi al sistema.
        </li>
        <li>
        La RAN gestisce in tempo reale le offerte, consentendo al banditore di aggiudicare il lotto.
        </li>

        <p>Atto notarile di tsrasferimento dell'immobile</p>
        <li>
        L’aggiudicatario diventa titolare del bene oggetto dell’asta.
        </li>
        <br></br>
        <p><strong>ATTENZIONE</strong></p>
        <p>Prima di partecipare, si consiglia di consultare sempre il disciplinare d’asta. Nonostante le linee guida comuni, infatti, i singoli disciplinari potrebbero prevedere una regolamentazione diversa</p>

        `
    },
    {
        id: 4,
        name: "Mutuo informato",
        pdf_sub: "Dieci regole per un mutuo responsabile",
        author: "Consiglio Nazionale del Notariato",
        link:"mutuo-informato",
        pdfLink:"https://notariato.it/wp-content/uploads/16.Infografica_MUTUO-1.pdf",
        cnnlink: "https://notariato.it/it/guida/infografiche-e-videografiche-mutuo-informato/",
        imageArt: "https://notariato.it/wp-content/uploads/Immagini_infografiche_Tavola-disegno-1-copia-2.png",
        deskheight: '410px',
        deskbottom: '475px',
        deskheightBot: '90px',
        deskheightBotFr: '500px',
        mobileheight: '414px',
        mobilebottom: '477px',
        mobileheightBot: '57px',
        mobileheightBotFr: '507px',
        content: `
        <h4>DIECI REGOLE PER UN MUTUO RESPONSABILE</h4>
        <h5>Evitare un eccessivo indebitamento</h5>
        <p>L’ammontare della somma deve essere attentamente ponderato, al fine di evitare rate insostenibili.</p>

        <h5>Prestare attenzione a quanto riportato nel foglio informativo e nel P.I.E.S.</h5>
        <p>Il P.I.E.S. contiene l’informativa personalizzata relativa al prodotto scelto, consentendo al cliente di valutare e confrontare le condizioni di mutuo proposte dalle banche.</p>

        <h5>Valutare il tasso di interesse</h5>
        <p>Il tasso rappresenta la percentuale dell'interesse sulla somma mutuata: è determinato in base a parametri fissati, a cui la banca aggiunge una maggiorazione (spread). Lo spread rappresenta il margine di guadagno dell'istituto che concede il finanziamento.</p>
        <p>Il tasso di interesse può essere:</p>
        <li>Fisso: costante per tutta la durata del mutuo.</li>
        <li>Variabile: determinato con riferimento a parametri mutevoli.</li>
        <li>Misto: può passare da fisso a variabile o viceversa.</li>

        <h5>Conoscere anticipatamente le spese accessorie</h5>
        <p>Per evitare brutte sorprese è bene conoscere:</p>
        <li>spese di perizia ed istruttoria;</li>
        <li>eventuale mediazione creditizia;</li>
        <li>assicurazioni.</li>
        <p>I suddetti costi sono espressi dal T.A.E.G., che indica il costo totale annuo del mutuo.</p>

        <h5>Informarsi sui tempi</h5>
        <p>È necessario informare la banca delle proprie necessità così da
        conoscere i tempi previsti sia per l'istruttoria che per l'erogazione della somma.</p>

        <h5>Tener conto del trattamento tributario</h5>
        <p>Nei mutui con durata superiore a 18 mesi si applica un’imposta sostitutiva pari allo 0,25% dell’importo mutuato. Fanno eccezione i finanziamenti contratti per acquisto, costruzione o ristrutturazione di abitazioni diverse dalla prima casa.</p>

        <h5>Tener conto della detraibilità fiscale</h5>
        <p>Parte degli interessi passivi e degli oneri accessori (tra cui la parcella notarile)dei mutui ipotecari, possono essere portati in detrazione (19% nel limite di 4.000 €).</p>

        <h5>Valutare le conseguenze di ritardati o mancati pagamenti</h5>
        <p>È normalmente previsto un tasso di mora. È anche prevista, in caso di inadempimento, la decadenza dal beneficio del termine
        o la risoluzione del contratto.</p>
        <p>In caso di difficoltà si potrà ricorrere a soluzioni alternative quali:</p>
        <li>rifinanziamento totale o parziale;</li>
        <li>modifica delle condizioni contrattuali.</li>

        <h5>Eventuali inadempimenti causano segnalazioni ad enti pubblici e privati (centrale rischi, S.I.C.)</h5>
        <p>con possibile pregiudizio per l’ottenimento di nuovi finanziamenti.</p>
        `
    },
    // {
    //     id: 5,
    //     name: "Casa e giovani",
    //     pdf_sub: "Guida pratica alla locazione e alla compravendita immobiliare",
    //     author: "Consiglio Nazionale del Notariato",
    //     link:"acquisto-giovani",
    //     pdfLink:"https://notariato.it/wp-content/uploads/9.Infografica_PREZZO-VALORE_-1.pdf",
    //     cnnlink: "https://notariato.it/it/guida/casa-e-giovani/",
    //     imageArt: "https://notariato.it/wp-content/uploads/copertina-guida-acquisto-giovani.jpg",
    //     deskheight: '410px',
    //     deskbottom: '475px',
    //     deskheightBot: '90px',
    //     deskheightBotFr: '500px',
    //     mobileheight: '414px',
    //     mobilebottom: '477px',
    //     mobileheightBot: '57px',
    //     mobileheightBotFr: '507px',
    //     content: `<p>La segregazione delle quote di una s.r.l. in un trust autodichiarato il cui disponente-trustee è anche beneficiario e guardiano integra il fumus del reato di sottrazione fraudolenta al pagamento delle imposte, in quanto ricorre la consapevolezza dell'esistenza del debito tributario e detto trust è un trust sham mirato a sottrarre le quote all'esecuzione del Fisco a carico del contribuente-disponente. Il reato di sottrazione fraudolenta al pagamento di imposte è astrattamente configurabile in capo a tutti i soggetti che hanno partecipato all'istituzione del trust.</p>
    //     `
    // },

    
];

export default infografiche;
