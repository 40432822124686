import React, { Component } from 'react';
import { useParams } from 'react-router-dom';

import Seo from '../../components/seo/Metatags';
import dynamic from 'next/dynamic'

import InfograficheDettagli_contenuto from './contenuto';

import Data from '../../components/Infografica/data/output';

import './styles.css';


const Intro = dynamic(
  () => import('../../components/Intro/index'),
  { ssr: false }
)

const BackToTop = dynamic(
  () => import('../../components/back-top'),
  { ssr: false }
)

const InfograficaDettagli = props => {

  const { infograficaID } = useParams();
  
  const location_origin = typeof window !== "undefined" ? `${window.location.href.toString()}` : "https://www.notaio-treviso.com"
  const location_host = typeof window !== "undefined" ? `${window.location.host.toString()}` : "www.notaio-treviso.com"

  return (
    <div>
      {Data.map(item => {
        // console.log(servizioID, servizio.link)
        if (infograficaID === item.link)
          return (
            <div key={item.id}>
              {/* metadati SEO */}
              <Seo
                title_par={`${item.name} | Notaio a Treviso`}
                description_par={`${item.pdf_sub} | Notaio Treviso`}
                pdf_sub_par={`${item.pdf_sub}`}
                type_par="article"
                ad_host={location_host}
                ad_origin={location_origin}
                link_page={location_origin}
                keywords_par={`${item.name}, Treviso, servizio notarile, notaio a Treviso, notaio, notai treviso`}
                isArticle={'vero'}
                art_titleData={item.name}
                art_pdf_subData={item.pdf_sub}
                art_imagesData={item.imageArt}
                art_imagesData_caption={`${item.name} - notaio a Treviso`}
                art_keywordsData={item.name}
                art_dateCreationData="2022-05-07T10:10:54+00:00"
                art_dateLastActivityData="2022-05-07T10:30:54+00:00"
                art_shortpdf_subData={item.pdf_sub}
                art_urlData={location_origin}
              />

              <React.Fragment>
                <Intro 
                    title={item.name} 
                    subtitle={`Infografica | Studio Notarile Falcinelli`} 
                    contents={`${item.pdf_sub}<br/><a className='italicIntro' title='notaio treviso' href='https://www.notaio-treviso.com/'>NOTAIO TREVISO</a>`}
                    linkSpinner={'divitemDett'}
                />
                <InfograficheDettagli_contenuto item={item} />
                <BackToTop />
              </React.Fragment>

            </div>
          );
      }
      )}
    </div>
  );
};

export default InfograficaDettagli;

