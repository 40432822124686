import React, { Component } from 'react';
import { Container } from 'react-bootstrap';

const ContenutoCorpus = ({ item }) => {
 console.log(item)
 return ( 
  <Container className='articoloBoxDesktop'>
      <div className="content post_content" dangerouslySetInnerHTML={{ __html: 
       `
       <h3>${item.name}</h3>
       ${item.content}
       `
       }}></div>
  </Container>
  );
}

export default ContenutoCorpus;

