import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import initialDetails from '../../components/servizi/data/output';
import Seo from '../../components/seo/Metatags';
import dynamic from 'next/dynamic'

import './styles.css';

const BackToTop = dynamic(
  () => import('../../components/back-top'),
  { ssr: false }
)

// const Preloader = dynamic(
//   () => import('../../components/preloader'),
//   { ssr: false }
// )

const ServizioApprofondisci_contenuto = dynamic(
  () => import('./contenuto'),
  { ssr: false }
)

const Intro = dynamic(
  () => import('../../components/Intro/index'),
  { ssr: false }
)


const ServizioApprofondisci = ({ }) => {

  const { servizioID } = useParams();
  const location_origin = typeof window !== "undefined" ? `${window.location.href.toString()}` : "https://www.notaio-treviso.com"
  const location_host = typeof window !== "undefined" ? `${window.location.host.toString()}` : "www.notaio-treviso.com"

  return (
    <div>
      {initialDetails.map(servizio => {
        // console.log(servizioID, servizio.link)
        if (servizioID === servizio.link)
          return (
            <div key={servizio.id}>
              {/* metadati SEO */}
              <Seo
                title_par={`${servizio.name} | Studio Notarile Falcinelli`}
                description_par={`${servizio.description}`}
                type_par="article"
                ad_host={location_host}
                ad_origin={location_origin + '/servizi/' + servizio.link}
                link_page={location_origin}
                keywords_par={`${servizio.tipologia}, Treviso, servizio notarile, notaio a Treviso, notaio, notai treviso`}
                isArticle={'vero'}
                art_titleData={servizio.name}
                art_descriptionData={servizio.description}
                art_imagesData={servizio.imageArt}
                art_imagesData_caption={`${servizio.name} - notaio a Treviso`}
                art_keywordsData={servizio.tipologia}
                art_dateCreationData="2022-03-11T10:10:54+00:00"
                art_dateLastActivityData="2022-03-11T10:30:54+00:00"
                art_shortDescriptionData={servizio.description}
                art_urlData={location_origin + '/servizi/' + servizio.link}
              />

              <React.Fragment>
                <Intro 
                    title={servizio.name} 
                    subtitle={`Notai di Treviso - Parliamo di ${servizio.name}`} 
                    contents={`${servizio.description}<br/><a className='italicIntro' title='notaio treviso' href='https://www.notaio-treviso.com/'>NOTAIO TREVISO</a>`}
                    tag={servizio.tag}
                    linkSpinner={'divAppCont'}
                />
                <ServizioApprofondisci_contenuto servizio={servizio} />
                <BackToTop />
                {/* <Preloader /> */}
              </React.Fragment>

            </div>
          );
      }
      )}
    </div>
  );
}

export default ServizioApprofondisci;
