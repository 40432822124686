const data = [
{
    id: 0,
    name: "Cessione di azienda",
    link: "cessione-di-azienda",
    consapevolezza: "lunga",
    description: "La cessione d’azienda consiste nel trasferimento del diritto di esercizio di una azienda a un altro soggetto economico e/o giuridico. Tale atto si effettua solitamente in seguito a un atto di compravendita, successione o donazione e può derivare anche da affitto o usufrutto.",
    imageArt: "https://www.notaio-treviso.com/documenti-stipula.webp",
    corpus: `<p>I contratti che hanno per oggetto la cessione di azienda devono essere stipulati con atto notarile al fine del loro deposito presso il competente Registro delle imprese.</p>
    <p>La consulenza di un professionista particolarmente qualificato nel campo giuridico come il Notaio può risultare particolarmente indicata per la verifica della sussistenza di tutti i requisiti richiesti dalla legge per la validità del contratto, come ad esempio la corretta formulazione dell’oggetto, del corrispettivo o delle modalità di pagamento, ovvero l’inserimento delle menzioni urbanistiche in presenza di immobili aziendali, ecc.) nonché per la corretta determinazione delle imposte legate all’operazione in oggetto.</p>
    <p>Il Notaio non è invece chiamato ad accertare né la continuità delle iscrizioni dell’azienda nel Registro delle imprese, né l’effettiva titolarità dei vari permessi e licenze.</p>`
},

{
    id: 1,
    name: "Cessione di quote/azioni di società",
    link: "cessione-quote",
    consapevolezza: "lunga",
    description: "La cessione di quote societarie è un contratto con il quale un socio cede la propria quota sociale a un altro socio oppure a un terzo estraneo, che entra così a fare parte della società. È un contratto che può assumere varie forme a seconda che questa cessione sia a titolo gratuito o oneroso. Nel primo caso si parla di donazione di quote, nel secondo caso invece abbiamo la vera e propria cessione. È come se fosse in sostanza una compravendita avente ad oggetto le quote sociali.",
    imageArt: "https://www.notaio-treviso.com/documenti-stipula.webp",
    corpus: `<p>Per la cessione di quote societarie occorre portare al Notaio i documenti di identità del cedente e del cessionario. Occorre inoltre portare l’atto costitutivo della società nonché lo statuto, se separato. Possono essere utili anche la situazione patrimoniale della società, che i soci possono esaminare per analizzare la situazione economica ed esserne consapevoli, prima di rispettivamente uscire ed entrare dalla società in oggetto.</p>

    <p>Lo studi notarile Falcinelli provvederà ad effettuare una visura camerale, per cui è opportuno inviare i suddetti documenti qualche giorno prima dell’atto. Dalla visura emergono tutte le cariche sociali, in modo che il Notaio possa controllare effettivamente la titolarità della partecipazione ceduta.</p>`
},

{
    id: 2,
    name: "Compravendita immobiliare",
    link: "compravendita-immobiliare",
    consapevolezza: "lunga",
    description: "Tra i documenti necessari alla vendita di un immobile troviamo l’atto notarile di compravendita o rogito notarile. Si tratta di un contratto di compravendita redatto in forma scritta di fronte al notaio con il quale il compratore e il venditore si obbligano rispettivamente a pagare il prezzo di vendita e a consegnare l’immobile secondo quanto previsto dalla legge. Per essere valido nei confronti di terzi, oltre all’autenticazione del notaio è necessaria la trascrizione nei registri immobiliari.",
    imageArt: "https://www.notaio-treviso.com/documenti-stipula.webp",
    corpus: `<p>L’atto di compravendita in sostanza è quel contratto che sancisce il trasferimento della proprietà di un immobile.</p>

    <p>L’atto di vendita tra privati, comporta degli obblighi per entrambe le parti: il venditore si obbliga a consegnare la casa e a garantire che sia libera da vizi, il compratore si obbliga a pagare il prezzo pattuito. La dichiarazione di proprietà di un immobile non può essere espressa tramite dichiarazione verbale, è necessario un documento ufficiale chiamato atto di proprietà casa che attesti:</p>
    <ul>
    <li>La proprietà esclusiva dell’immobile</li>
    <li>La correttezza nei passaggi di proprietà degli ultimi 20 anni</li>
    <li>L’assenza di vincoli restrittivi gravanti sull’immobile.</li>
    </ul>

    <p>Solo successivamente a questa verifica l’atto di compravendita può essere firmato da entrambe le parti di fronte al notaio, per concretizzare il passaggio della proprietà dell’immobile. Il passaggio avviene anche se non è stato ancora pagato il prezzo e l’immobile non è stato consegnato.</p>
    
    <p>Il notaio è la persona giuridica con preparazione tecnica e giuridica in grado di stilare praticamente il contratto. Sempre lui ha il compito di controllare la veridicità del contratto.</p>`
},

{
    id: 3,
    name: "Separazione beni",
    link: "separazione-beni",
    consapevolezza: "lunga",
    description: "Il regime di separazione dei beni consente a ciascun coniuge di rimanere titolare esclusivo dei beni di sua spettanza e di ogni acquisto fatto in costanza di matrimonio; l’amministrazione dei beni spetta a ciascuno dei coniugi che risponde delle obbligazioni contratte (ad es debiti) unicamente con il proprio patrimonio senza alcun coinvolgimento da parte dell’altro coniuge.",
    imageArt: "https://www.notaio-treviso.com/documenti-stipula.webp",
    corpus: `<p>Nel regime di separazione dei beni i coniugi mantengono separati i rispettivi patrimoni e amministrano, godono e dispongono liberamente dei propri beni.</p>

    <p>Il regime di separazione dei beni non deve essere confuso con la separazione personale dei coniugi o, addirittura, con il divorzio: il primo infatti regola e definisce la gestione del patrimonio di una coppia, il secondo indica la fase volta allo scioglimento del rapporto coniugale.</p>
    
    <p>I diritti spettanti in sede di successione al coniuge coniugato in regime di separazione dei beni sono gli stessi del coniuge in comunione legale dei beni: il regime patrimoniale prescelto dai coniugi, quindi non incide sulla quota spettante per legge al coniuge superstite.</p>
    
    <p>Il notaio è una figura esperta nel diritto di famiglia che può fornire informazioni utili sia sulle regole generali sia sui singoli casi concreti e in particolare illustrare le particolarità della disciplina nell’ipotesi di soggetti fra loro conviventi.</p>`
},

{
    id: 4,
    name: "Costituzione di fondo patrimoniale",
    link: "costituzione-fondo-patrimoniale",
    consapevolezza: "lunga",
    description: "Oltre a scegliere tra comunione legale o separazione dei beni, i coniugi possono anche scegliere se costituire un fondo patrimoniale. Il fondo patrimoniale è un particolare tipo di convenzione attraverso la quale determinati beni possono essere destinati a far fronte ai bisogni della famiglia: ciò significa che il fondo patrimoniale è un patrimonio destinato ad uno specifico scopo.",
    imageArt: "https://www.notaio-treviso.com/documenti-stipula.webp",
    corpus: `<p>Possono essere compresi nel fondo solo:</p>
    <ul>
    <li>beni immobili;</li>
    <li>beni mobili registrati;</li>
    <li>titoli di credito.</li>
    </ul>
    
    <p>Conferire beni in fondo patrimoniale significa apporre sui beni stessi un vincolo di destinazione ai bisogni della famiglia, senza che sia necessario un trasferimento di proprietà. Di conseguenza, il conferimento può avvenire in due modi:</p>
    <ul>
    <li>la proprietà del bene conferito va ad entrambi i coniugi;</li>
    <li>in alternativa, ciascun coniuge può restare proprietario esclusivo del bene conferito.</li>
    </ul>

    <p>E’ importante precisare, che nella nozione di famiglia non è contenuta la convivenza more uxorio in quanto <strong>la costituzione del fondo patrimoniale sussiste in presenza o per il verificarsi successivo del vincolo coniugale</strong>.</p>
     
    <p>La costituzione del fondo patrimoniale può avvenire:</p>
    <ul>
    <li>da parte dei coniugi;</li>
    <li>da parte di un terzo. In questo caso, la costituzione del fondo patrimoniale si perfeziona con l’accettazione di entrambi i coniugi.</li>
    </ul>
    <p>L’amministrazione dei beni costituenti il fondo patrimoniale è regolata dalle norme relative all’amministrazione della comunione legale. In particolare, per poter validamente alienare o disporre dei beni del fondo, occorre distinguere due ipotesi:</p>
    <ul>
    <li>se non vi sono figli minori, occorre il consenso di entrambi i coniugi;</li>
    <li>se vi sono figli minori, oltre al consenso dei coniugi occorre anche l’autorizzazione del giudice.</li>
    </ul>
    <p>La costituzione del fondo patrimoniale deve avvenire con la stipula di una convenzione per atto pubblico ricevuto dal notaio in presenza di due testimoni, che verrà annotata a margine dell’atto di matrimonio e trascritta nei Registri immobiliari.</p>
    <p>L’atto di costituzione del fondo può però stabilire regole diverse: di conseguenza, anche in questo caso i consigli del notaio sono importanti per trovare la soluzione più corretta per le proprie esigenze</p>`
},

{
    id: 5,
    name: "Procura",
    link: "procura",
    consapevolezza: "corta",
    description: "La procura è l’atto con il quale un soggetto (“rappresentato”) delega ad altro soggetto (“rappresentante” o “procuratore”) il potere di agire in suo nome e nel suo interesse per il compimento di uno o più atti giuridici.",
    imageArt: "https://www.notaio-treviso.com/documenti-stipula.webp",
    corpus: `
    <p>Da un punto di vista giuridico, la procura generale e speciale che sia (in ordine alle cui differenze diremo nei paragrafi successivi dell’elaborato) si configura quale negozio unilaterale e non come contratto, atteso che in linea di principio, non necessita di accettazione da parte del rappresentante. Detta connotazione fa sì che, proprio in quanto atto unilaterale e non contratto, sul rappresentante non grava alcun obbligo di esercitare il potere di rappresentanza che gli viene conferito dal rappresentato, giacché colui investito del potere di compiere uno o più atti giuridici nel nome e interesse altrui è libero di scegliere se esercitare tale potere, ovvero disinteressarsene. In ogni caso, allorché il procuratore decida di agire in ossequio al potere conferitogli dal rappresentato, dovrà farlo in maniera conforme e coerente agli interessi del delegante, onde evitare di incorrere in responsabilità, foriere dell’obbligo di risarcimento dei danni.</p>
    `
},

{
    id: 6,
    name: "Mutuo",
    link: "mutuo",
    consapevolezza: "lunga",
    description: "Per poter stipulare un contratto di mutuo ipotecario, cioè garantito da ipoteca, è necessario l’intervento del notaio, la cui funzione è quella di consentire l’iscrizione ipotecaria presso i competenti Uffici del Territorio.",
    imageArt: "https://www.notaio-treviso.com/documenti-stipula.webp",
    corpus: `
    <p>La scelta del mutuo è di cruciale importanza ed è importante farsi assistere nella scelta della  tipologia di mutuo adatto alla situazione specifica.</p>

    <p>Il dovere di assoluta imparzialità, che vieta al notaio di esprimere valutazioni o giudizi di merito circa la scelta che il mutuatario deve compiere, infatti, non gli impedisce di prestare una adeguata assistenza informativa circa le diverse tipologie di mutuo, i diritti e i doveri del mutuatario e della banca mutuante, le norme in tema di trasparenza bancaria e di tutela del consumatore.</p>
    
    <p>Una volta che si è scelta la banca, la stessa avvia una procedura di merito creditizio con cui verifica la qualità del rischio assunto dalla banca con la relativa redditività dell’operazione, sulla base di informazioni adeguate fornite dal mutuatario e, ove necessario, ottenute consultando una banca dati indipendente.</p>
    
    <p>Conclusa la predetta istruttoria, spetta al notaio compiere tutti gli accertamenti utili e necessari a garantire la banca circa l’esistenza di un bene ipotecabile non gravato da nessun altra formalità pregiudizievole.</p>
    `
},

{
    id: 7,
    name: "Donazione",
    link: "donazione",
    consapevolezza: "lunga",
    description: "La donazione deve essere fatta per atto pubblico notarile sotto pena di nullità e richiede la presenza irrinunziabile di due testimoni, non parenti coniugi o affini, né interessati all'atto.",
    imageArt: "https://www.notaio-treviso.com/documenti-stipula.webp",
    corpus: `<p>La donazione è un atto rischioso e richiede l’assistenza di un professionista esperto:</p>

    <p>a) perché la donazione anticipa la vostra successione;</p>
    
    <p>b) perché la donazione è un atto tendenzialmente definitivo. Donare, è donare! Una donazione è irrevocabile, come tutti i contratti. Il donante non può più riprendere ciò che ha donato, neppure se successivamente si penta del suo gesto o se i rapporti tra le parti siano cambiati dopo l'atto di donazione. Tuttavia, la donazione, come tutti i contratti, può essere sciolta per mutuo dissenso o per cause ammesse dalla legge, che prevede cause di revocazione della donazione. Inoltre, è possibile apporre all’atto di donazione clausole o condizioni che provochino l’effetto di spogliare il donatario del bene donato e di farlo ritornare in capo al donante;</p>
    
    <p>c) per sapere se si abbia la capacità di donare e ricevere per donazione. Solo chi ha la proprietà di un bene può donare: non si può ovviamente donare un bene che appartiene ad un'altra persona. I donanti e i donatari devono essere capaci, ma sono ammesse le donazioni di minori e di inabilitati in sede di convenzioni matrimoniali e sono ammesse, con le necessarie forme abilitative, le liberalità in occasione di nozze a favore dei discendenti dell'interdetto o dell'inabilitato, nonché si può donare a soggetti non ancora nati, anche se non concepiti. Anche le persone giuridiche possono donare, purchè tale capacità sia ammessa dal loro statuto o dall'atto costitutivo e sia compatibile con gli scopi per i quali sono state costituite. Le stesse possono anche ricevere per donazione. Possono ricevere per donazione anche gli enti non riconosciuti;</p>
    
    <p>d) per sapere se si possa donare a mezzo di altri. La scelta della persona del donatario deve essere fatta dal donante o direttamente o indicando una categoria o una pluralità di soggetti tra cui un terzo, suo mandatario, sceglierà. Analoga disposizione è valida per la scelta dell'oggetto della donazione;</p>
    
    <p>e) per sapere come donare. Vista l'importanza di tale atto, la legge richiede la necessità di usare l'atto pubblico notarile, e si richiede, altresì, la presenza di due testimoni, a pena di nullità. Se i beni donati sono beni mobili di richiede l'indicazione del loro valore all'interno dell'atto o in una nota a parte. Ma la forma notarile non è richiesta nel caso di donazione di modico valore di cosa mobile (donazione manuale): qui basta la consegna della cosa. Si può donare anche in via indiretta, per esempio procurando l'acquisto del bene a chi si vuole beneficiare, senza necessità di utilizzare le forme della donazione (atto pubblico notarile con due testimoni irrinunziabili);</p>
    
    <p>f) per sapere cosa si può donare. Tutti i beni possono costituire oggetto di una donazione: mobili o immobili, denaro, titoli di credito, azioni e quote di società, aziende..., purché si tratti di beni presenti nel patrimonio del donante e non di beni futuri. Non sono consentite le donazioni che abbiano ad oggetto un'obbligazione di fare (si pensi, per esempio alla gratuite prestazione del medico o dell'artista), né quelle che abbiano ad oggetto un'obbligazione di non fare (si pensi, per esempio, all'obbligazione, senza corrispettivo, di non costruire un muro per non togliere luce ad un fondo attiguo).</p>
    
    <p>g) per evidenziare il motivo che spinge a donare. Il motivo che spinge a donare può essere rilevante:</p>
    
    <p>- nel caso di donazioni rimuneratorie, qualora l'attribuzione venga effettuata per riconoscenza (per un aiuto economico ricevuto o promesso) o in considerazione dei meriti di chi riceve la donazione o per speciale remunerazione, anche se non costituiscono donazioni le liberalità conformi agli usi (es. mance o regali natalizi dei clienti ai professionisti);</p>
    
    <p>- nel caso di donazioni obnuziali, qualora l'attribuzione sia compiuta in vista di un determinato futuro matrimonio dagli sposi tra loro o da altri a favore degli sposi o dei figli nascituri. In questo caso non è necessario che il donatario accetti la donazione, però, se il matrimonio non verrà celebrato, la stessa perderà di efficacia.</p>
    
    <p>- nel caso di donazioni modali, qualora si voglia obbligare il donatario a dare, a fare o a non fare qualche cosa a favore del donante stesso o di un terzo.
    </p>`
},

{
    id: 8,
    name: "Dichiarazione di successione",
    link: "dichiarazione-di-successione",
    consapevolezza: "lunga",
    description: "La dichiarazione di successione è un documento di natura fiscale che segue il momento della morte di un certo soggetto e disciplina il momento dell’apertura della successione a favore dei chiamati all’eredità.",
    imageArt: "https://www.notaio-treviso.com/documenti-stipula.webp",
    corpus: `<p>La dichiarazione di successione è un documento di natura fiscale che segue il momento della morte di un certo soggetto e disciplina il momento dell’apertura della successione a favore dei chiamati all’eredità.</p>
    <p>Tale dichiarazione è quindi a loro carico e la funzione principale è quella di fornire informazioni all’Agenzia delle entrate sull’ammontare e sulla composizione del patrimonio ereditario in modo da poter calcolare le imposte che devono essere pagate.</p>
    <p>Molte volte è difficile capire come debba avvenire la sua esatta compilazione, ed è per questo che il notaio viene in aiuto.</p>
    <p>I soggetti obbligati a presentare la dichiarazione di successione sono individuati dall’Agenzia delle entrate e sono i seguenti:</p>
    <ul>
    <li>i chiamati all'eredità (per legge o per testamento, anche se non hanno ancora accettato l'eredità, purché non vi abbiano espressamente rinunciato) e i legatari, anche nel caso di apertura della successione per dichiarazione di morte presunta, o i rappresentanti legali;</li>

    <li>chi è immesso nel possesso dei beni, in caso di assenza del defunto o di dichiarazione di morte presunta;</li>

    <li>gli amministratori dell'eredità;</li>

    <li>i curatori delle eredità giacenti;</li>

    <li>gli esecutori testamentari;</li>

    <li>il trustee.</li>
    </ul>

    <p>Il ruolo del Notaio è quanto mai significativo nel momento dell’apertura della successione, soprattutto per il compimento di tutte le pratiche preliminari in cui si richiede una collaborazione di tutti gli eredi partecipanti.</p>

    <p>In particolare, il Notaio si occuperà di coadiuvare le parti nella compilazione della dichiarazione di successione, compiendo tutti gli accertamenti necessari.</p>

    <p>Provvede, inoltre:</p>

    <ul>
    <li>a redigere il prospetto di autoliquidazione delle imposte, il modello di pagamento di tasse, imposte, sanzioni ed altre entrate, nonché la relativa autocertificazione;</li>

    <li>a registrare la dichiarazione presso l’Agenzia delle Entrate competente, successivamente predisponendo e presentando la voltura catastale presso il Catasto territorialmente competente in base a dove sono situati gli immobili ereditati.</li>
    </ul>

    <p>COSA SUCCEDE SE MANCA LA DICHIARAZIONE DI SUCCESSIONE?
    <p>Nel caso in cui la registrazione della dichiarazione di successione sia omessa, si applica una sanzione che va dal 120 al 240% dell’imposta liquidata; se non è dovuta l’imposta, si applica la sanzione amministrativa da 250 a 1.000 euro.</p>

    <p>Se invece la dichiarazione è presentata con un ritardo che non supera i trenta giorni, si applica la sanzione amministrativa dal 60 al 120% dell’imposta liquidata; se non è dovuta imposta, si applica la sanzione amministrativa da 150 a 500 euro.</p>

    <p>Il tardivo versamento dell’imposta comporta invece l’applicazione della sanzione del 30%, calcolata sull’importo non versato. Tuttavia, prima che avvenga l’accertamento d’ufficio si può effettuare il cosiddetto “ravvedimento operoso”, mediante il quale è possibile regolarizzare i versamenti di imposte omessi o insufficienti, in questo modo beneficiando di una riduzione delle sanzioni.</p>

    <p>COSA SUCCEDE DOPO LA PRESENTAZIONE DELLA DICHIARAZIONE?</p>
    <p>Una volta presentata la dichiarazione, l’Agenzia delle entrate notifica agli eredi la liquidazione dell’imposta di successione, da pagare entro i 60 giorni successivi alla richiesta (e devono farlo tutti gli eredi solidalmente: significa che il Fisco può agire per ottenere l’intero importo anche nei confronti di un erede soltanto che, poi, potrà rivalersi sugli altri in proporzione alle loro quote di eredità). Ovviamente l’importo dovuto a titolo di imposta dipende dal grado di parentela degli eredi rispetto al defunto, e si dovranno applicare, quindi, certe aliquote e franchigie.</p>

    `
},

{
    id: 9,
    name: "Costituzione di società",
    link: "costituzione-società",
    consapevolezza: "lunga",
    description: `Esistono varie tipologie di società per svolgere attività d’impresa: la scelta dipende non solo dalla natura e dalle circostanze che ne possono determinare la nascita. Per questo è consigliabile rivolgersi per tempo al notaio.`,
    imageArt: "https://www.notaio-treviso.com/documenti-stipula.webp",
    corpus: `<p>Le persone che intendono costituire una società devono concludere dal notaio, per atto pubblico o scrittura privata autenticata, un contratto di società (anche detto atto costitutivo) attraverso il quale  due o più persone conferiscono beni o servizi per l’esercizio in comune di un’attività economica allo scopo di dividerne gli utili.</p>

    <p>Oggi è ammessa anche la costituzione di una società da parte di una sola persona, sia nella forma della società per azioni, sia di società a responsabilità limitata.</p> 
    
    <p>L’atto notarile contiene dati certi e attendibili grazie al controllo di legalità preventivo effettuato dal notaio e, una volta iscritto nel Registro delle Imprese, produce i suoi effetti nei confronti di tutti (tecnicamente definiti terzi). A ribadire la funzione di giustizia preventiva affidata al notaio in materia societaria, è anche una recente norma di legge che prevede l’iscrizione immediata per tutti gli atti societari, non solo l’atto costitutivo, predisposti dal notaio nel registro delle imprese, lasciando a quest’ultimo una verifica di “seconda istanza”.</p>
    
    <p>Il notaio, inoltre, ha una preparazione specifica in materia societaria e, intervenendo per legge in posizione di terzietà ed indipendenza rispetto alle parti, è in grado di consigliare i futuri soci nella stesura dell’atto costitutivo e dei patti sociali contemperando gli interessi in campo per evitare futuri contenziosi. Nella stesura dell’atto costitutivo, quindi, tiene conto delle esigenze specifiche dei soggetti interessati e dei riflessi che la costituzione e l’attività della società produrrà sul loro assetto patrimoniale (come, per esempio, la successione).</p>`
},



];
export default data;
