import React from 'react';
import Seo from '../components/seo/Metatags';
import dynamic from 'next/dynamic'
//import components
// wrap your component that uses the graph lib.
const Intro = dynamic(
    () => import('../components/Intro/index'),
    { ssr: false }
)

const TagComponent = dynamic(
    () => import('../components/tags/index'),
    { ssr: false }
)

// const FaqComponent = dynamic(
//     () => import('../components/faq/index'),
//     { ssr: false }
// )

const ChatBot = dynamic(
    () => import('../components/chatBot'),
    { ssr: false }
)

const BackToTop = dynamic(
    () => import('../components/back-top'),
    { ssr: false }
)

const Contact = dynamic(
    () => import('../components/contatti'),
    { ssr: false }
)

const NavComponent = dynamic(
    () => import('../components/navcomponent/index'),
    { ssr: false }
)

const AreeDiIntervento = dynamic(
    () => import('../components/areeDiIntervento/index'),
    { ssr: false }
)

const Mission = dynamic(
    () => import('../components/mission/index'),
    { ssr: false }
)

const OpElement = dynamic(
    () => import('../components/opelement/index'),
    { ssr: false }
)
const NotaioCard = dynamic(
    () => import('../components/notaioCard/index'),
    { ssr: false }
)
const Preloader = dynamic(
    () => import('../components/preloader'),
    { ssr: false }
)

const Footer = dynamic(
    () => import('../components/Footer'),
    { ssr: false }
)

let ad_origin = typeof window !== "undefined" ? `${window.location.origin.toString()}` : "https://www.notaio-treviso.com"
let ad_host = typeof window !== "undefined" ? `${window.location.origin.toString()}` : "www.notaio-treviso.com"

const HomePage = () => {
    
    return (
        <>
            <Seo
                title_par="Notaio Treviso | Studio Notarile Falcinelli"
                description_par="Notaio di Treviso. Contatta lo Studio Notarile Falcinelli per il tuo atto notarile a Treviso e provincia: mutui, atti societari, atti di compravendita, trust, successioni."
                type_par="website"
                ad_host={ad_host}
                ad_origin={ad_origin}
                link_page={ad_origin}
                keywords_par="Treviso trust notarile, trust notarile, trust notaio, Treviso, notai treviso"
                isArticle={''}
                art_titleData=''
                art_descriptionData=''
                art_imagesData=''
                art_imagesData_caption=''
                art_keywordsData=''
                art_dateCreationData=''
                art_dateLastActivityData=''
                art_shortDescriptionData=''
                art_urlData=''
            />
            <React.Fragment>
                <Intro 
                    title={'Notaio Treviso - Studio Notarile Falcinelli'} 
                    subtitle={'Notaio Andrea Falcinelli'} 
                    contents={`Lo studio Notarile Falcinelli offre un servizio su misura competente, celere e improntato alla soluzione dei problemi della clientela. Si trova a 50 metri dal Duomo di Treviso, accanto alla Fondazione Benetton e al Comando provinciale dei Carabinieri.
                    Offre servizi e consulenza in ambito di trust, affidamento fiduciario, compravendita, mutuo, rent to buy, donazioni, unioni civili, testamenti, successioni, patto di famiglia, tutela del patrimonio, società, azienda, enti no profit, leasing, vidimazioni e copie.
                    Il notaio Falcinelli di Treviso è un pubblico ufficiale, al quale lo Stato ha affidato il potere di attribuire pubblica fede, ovvero il valore di prova legale, agli atti che stipula.
                    <br/><br/><a className='italicIntro downintro' title='notaio treviso' href='https://www.notaio-treviso.com/'>NOTAIO TREVISO</a>`}
                    linkSpinner={'missionSec'}
                />

                <NotaioCard/>

                <OpElement
                title={'Notaio Treviso'} 
                subtitle={`Notaio di Treviso e provincia`} 
                cardTitle={`Chi siamo`} 
                content={`<p>Lo studio notarile Falcinelli svolge la propria funzione pubblica nei confronti di privati, imprese, enti ed istituzioni nell'area di <strong>Treviso</strong> e <strong>provincia di Treviso</strong>.</p>

                <p>Lo studio garantisce il completo svolgimento delle <strong>pratiche notarili</strong> come per esempio quelle inerenti i trasferimenti ipotecari (<strong>vendita e acquisto casa</strong>), gli atti ipotecari (contratti di <strong>mutuo</strong>), i <strong>trust</strong> o le <strong>successioni ereditarie</strong> etc.</p>
                
                <p>Per aiutare il cliente nella ricerca dei <strong>servizi notarili</strong> di cui ha bisogno, questi sono stati suddivisi nel sito in servizi e atti notarili per le aziende (inizio attività, titoli abilitativi, acquisti di concessioni, brevetti e marchi), servizi e atti notarili per i giovani (acquisto prima casa, iscrizioni ad albi e qualifiche, inizio di un'attività imprenditoriale), servizi e atti notarili per la famiglia (scelta del regime patrimoniale, testamenti e successioni, assegnazioni ai coniugi a seguito di separazione o divorzio ecc.).</p>
                
                <p>I settori tradizionalmente propri dell'<strong>attività notarile</strong> svolti dallo studio del <strong>notaio in Treviso e provincia</strong> sono tanti e complessi e vi invitiamo a contattarci per qualsiasi domanda a riguardo.</p>
                
                <p>Lo <strong>Studio Notarile Falcinelli</strong> è specializzato in differenti aree di competenze, pone al centro le esigenze dei suoi clienti e se ne prende cura con professionalità e serietà.</p>
                
                <p>Lo studio del Notaio si trova nel <strong>centro di Treviso</strong>, vicino al Duomo in <strong>via Cornarotta 5</strong>.</p>`}
                />

                <Mission 
                    title={`Il Notaio di Treviso a Supporto Dell'Impresa E Del Cittadino`} 
                    subtitle={'Il Notaio Fornisce Consulenza E Assistenza'}/>

                <AreeDiIntervento
                title={'Notaio Treviso - Aree di Intervento'} 
                subtitle={'Un team di esperti per supportarti in tutte le fasi della tua vita'}  /> 

                <NavComponent
                title={'Il Notaio di Treviso Al servizio di cittadini e imprese'} 
                subtitle={'I Servizi dello Studio Notarile Falcinelli a Treviso'}  /> 

                
                <Contact />
                
                <OpElement
                title={'Perchè scegliere il Notaio di Treviso'} 
                cardTitle={`Cosa facciamo`} 
                subtitle={`L'impegno dello Studio Notarile Falcinelli Riservato Ad Ogni Cliente`}  
                content={`Nelle Pratiche Notarili Ci Atteniamo Al Nostro Protocollo Operativo, Che Rendiamo Pubblico Al Fine Di Permettere Di Conoscere Lo Standard Qualitativo Che Adottiamo Nella Preparazione Degli Atti Di Cui Siamo Incaricati.`}
                />

                {/* <FaqComponent
                title={'FAQ'} 
                subtitle={`Le domande più frequenti sul Notaio di Treviso`} 
                /> */}

                <TagComponent />

                <Footer />
                <BackToTop />
                <ChatBot />
                
                <Preloader />
            </React.Fragment>
        </>
    )
}

export default HomePage;
