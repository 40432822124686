import React from 'react';
import { hydrate, render } from 'react-dom';
import { Provider } from "react-redux";
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import configureStore from "./configureStore";

const store = configureStore();

const rootElement = document.getElementById('root');
const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

rootElement.hasChildNodes()
    ? hydrate(app, rootElement)
    : render(app, rootElement);

