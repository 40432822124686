import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import dynamic from 'next/dynamic'

// import Navbar from './components/navbar';
import AreaRiservataPage from './pages/AreaRiservataPage';
import HomePage from './pages/HomePage';
import Contatti from './pages/Contatti';
import ServiziPage from './pages/ServiziPage';
import ServizioApprofondisciPage from './pages/ServizioApprofondisciPage';

import AutocertificazioniPage from './pages/AutocertificazioniPage';
import AutocertificazioniDettagliPage from './pages/AutocertificazioniDettagliPage';
import DocumentiStipulaPage from './pages/DocumentiStipulaPage';
import DocumentiStipulaDettagliPage from './pages/DocumentiStipulaDettagliPage';

import ServizioInfo from './pages/ServizioInfoPage';
import ServizioPreventivo from './pages/ServizioPreventivoPage';
import NewsPage from './pages/NewsPage';
import InfograficaPage from './pages/InfograficaPage';
import InfograficaDettagliPage from './pages/InfograficaDettagliPage'
import RichiestaPreventivo from './pages/RichiediPreventivoPage';

import RichiestaConsulenza from './pages/RichiediConsulenzaPage';
import RichiestaCopiaAtti from './pages/RichiediCopiaAttiPage';
import RichiestaVideoconferenza from './pages/RichiediVideoConferenzaPage';

//import css in order
import 'normalize.css';
import './styles/animate.css';
import 'bootstrap/dist/css/bootstrap.css';
import './img/icons/css/ionicons.css';
import './img/font-awesome/css/font-awesome.css';
import 'lightbox2/dist/css/lightbox.min.css';
import './styles/app.css';

const Navbar = dynamic(
    () => import('./navbar/NavBar'),
    { ssr: false }
)

const App = () => {
   
    return (
        <>
            <Navbar />
            <main>
                <Switch>
                    <Route exact path="/area-riservata">
                        <AreaRiservataPage />
                    </Route>
                    <Route exact path="/">
                        <HomePage />
                    </Route>
                    <Route exact path="/contatti">
                        <Contatti />
                    </Route>
                    <Route exact path="/servizi">
                        <ServiziPage />
                    </Route>
                    <Route exact path="/news">
                        <NewsPage />
                    </Route>
                    <Route exact path="/infografica">
                        <InfograficaPage />
                    </Route>
                    <Route exact path="/infografica/:infograficaID">
                        <InfograficaDettagliPage />
                    </Route>
                    <Route exact path="/preventivo">
                        <RichiestaPreventivo />
                    </Route>
                    <Route exact path="/copia-atti">
                        <RichiestaCopiaAtti />
                    </Route>
                    <Route exact path="/videoconferenza">
                        <RichiestaVideoconferenza />
                    </Route>
                    <Route exact path="/consulenza">
                        <RichiestaConsulenza />
                    </Route>
                    <Route exact path="/servizi/autocertificazioni">
                        <AutocertificazioniPage />
                    </Route>
                    <Route exact path="/servizi/documenti-stipula">
                        <DocumentiStipulaPage />
                    </Route>
                    <Route exact path="/servizi/:servizioID">
                        <ServizioApprofondisciPage />
                    </Route>
                    <Route exact path="/servizi/:servizioID/informazioni">
                        <ServizioInfo />
                    </Route>
                    <Route exact path="/servizi/:servizioID/preventivo">
                        <ServizioPreventivo />
                    </Route>
                    <Route exact path="/servizi/autocertificazioni/:autocertificazioniID">
                        <AutocertificazioniDettagliPage />
                    </Route>
                    <Route exact path="/servizi/documenti-stipula/:documentiStipulaID">
                        <DocumentiStipulaDettagliPage />
                    </Route>
                </Switch>
            </main>
        </>
    )
}

export default App;
