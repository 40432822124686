
const initialDetails = [
    {
        id: 0,
        name: "Compravendita",
        link: "compravendita",
        tipologia: "Compravendita e Finanziamenti",
        tag: ["casa", "acquisto casa"],
        codice_tipo: 1,
        description: `Studio Notarile Falcinelli - Notaio Falcinelli - Il contratto di compravendita è un accordo tra due parti (venditore e acquirente) che stabilisce le condizioni per la cessione di un bene (ad esempio un'auto, un immobile o un'azienda) da parte del venditore all'acquirente.`,
        corpus: `
        <p>Il contratto di compravendita è un accordo tra due parti (venditore e acquirente) che stabilisce le condizioni per la cessione di un bene (ad esempio un'auto, un immobile o un'azienda) da parte del venditore all'acquirente. Il contratto di compravendita è uno degli atti notarili più comuni e serve a trasferire la proprietà di un bene da un soggetto ad un altro.</p>

        <p>Il notaio svolge un ruolo fondamentale nella stipula di un contratto di compravendita, in quanto è lui che redige il contratto e ne certifica la validità, assicurando che sia conforme alla legge e che tutti i requisiti siano stati rispettati. Inoltre, il notaio è un professionista che può offrire un supporto prezioso nella scelta della forma più adatta per il contratto di compravendita e nella definizione degli obiettivi.</p>

        <p>Lo Studio Notarile del Notaio Falcinelli di Treviso, è un'eccellenza nella città di Treviso per quanto riguarda la consulenza e la predisposizione di atti notarili e nella costituzione di contratti di compravendita. Il notaio Falcinelli, con la sua lunga esperienza e competenza, saprà consigliare al meglio i propri clienti per quanto riguarda la costituzione di contratti di compravendita, offrendo loro una consulenza personalizzata in base alle loro esigenze.</p>

        <p>Il contratto di compravendita è un accordo fondamentale per la cessione di beni e per la trasferimento della proprietà, è importante che questo contratto sia redatto in modo preciso e che tutte le clausole siano state definite in modo chiaro e trasparente, il notaio Falcinelli garantisce questo.</p>

        <p>Per coloro che cercano un notaio esperto in contratti di compravendita a Treviso, lo Studio Notarile del Notaio Falcinelli rappresenta una scelta ideale, offrendo un servizio di alta qualità e una consulenza professionale e personalizzata. Contattando lo studio notarile del Notaio Falcinelli si potrà avere la certezza di essere assistiti in modo efficace e professionale, per costituire un contratto di compravendita adatto alle proprie esigenze.</p>
        <br></br>
        <p>Concludendo:</p>
        <p>In generale la compravendita è il contratto che ha per oggetto il trasferimento della proprietà di una cosa o il trasferimento di un altro diritto verso il corrispettivo di un prezzo; più genericamente si può dire che la compravendita immobiliare è l’accordo formale con il quale una parte, detta venditrice, trasferisce ad un’altra, detta acquirente, la proprietà di un  determinato immobile a fronte del pagamento del prezzo pattuito.</p>

        <p>Il nostro ordinamento giuridico, in considerazione dell’estrema importanza della proprietà immobiliare, non solo esige che l’accordo delle parti rivesta la forma scritta, ma richiede anche l’osservanza di talune regole essenziali, la presenza di alcuni requisiti fondamentali ed il contestuale adempimento di alcuni obblighi particolari.</p>
        
        <p>Proprio per questi motivi e soprattutto al fine di tutelare l’elevato valore della posta in gioco, si è affidato il compito di curare le compravendite immobiliari al notaio, nella sua duplice veste di pubblico ufficiale e di esperto professionista in campo giuridico, il quale potrà anche suggerire soluzioni alternative più idonee al raggiungimento del risultato sperato in relazione al singolo caso concreto.</p>`,
        imageArt: `https://www.notaio-treviso.com/compravendita.webp`,
    },

    {
        id: 1,
        name: "Mutuo",
        link: "mutuo",
        tipologia: "Compravendita e Finanziamenti",
        tag: ["casa", "mutuo", "acquisto casa"],
        codice_tipo: 1,
        description: `Studio Notarile Falcinelli - Notaio Falcinelli - Il mutuo è un contratto con il quale una parte, detta mutuante (di solito una banca), trasferisce una determinata quantità di denaro a favore dell'altra, detta mutuatario, al fine che quest'ultima possa goderne per un tempo determinato a fronte del pagamento alla prima di un corrispettivo rappresentato da interessi.`,
        corpus: `<p>Il mutuo è un contratto con il quale una parte, detta mutuante (di solito una banca), trasferisce una determinata quantità di denaro a favore dell'altra, detta mutuatario, al fine che quest'ultima possa goderne per un tempo determinato a fronte del pagamento alla prima di un corrispettivo rappresentato da interessi.</p>

        <p>Così schematizzata la struttura essenziale e la funzione del contratto, si deve subito evidenziare che un mutuo in concreto è arricchito di svariate clausole non sempre di immediata comprensione, ma necessarie a regolare tutti i rapporti tra le parti per la durata prevista: modalità di erogazione del mutuo, fissazione dei termini di rimborso del capitale e di pagamento degli interessi, determinazione di altre spese per gli oneri di amministrazione del contratto, disciplina e costi di estinzione anticipata, prestazione e manutenzione delle garanzie, conseguenze degli inadempimenti relativi (ritardi) e assoluti (mancato pagamento).</p>
        
        <p>Ciascuno di questi aspetti è a sua volta caratterizzato da una notevole ricchezza di possibilità e di sfumature, frutto sia di prassi e regole moderne, sia di una meditazione dottrinale che a giusta ragione può definirsi millenaria (il mutuo è contemplato nel diritto romano classico ed è stato oggetto di studio da parte dei giuristi bizantini, dei giuristi medievali di diritto comune, dei giuristi delle codificazioni dell'età moderna). Attualmente la tematica è ulteriormente arricchita da suggestioni e novità provenienti da Paesi stranieri e dalla disciplina dell'Unione europea.</p>
        
        <p>Tutto questo rende l'idea  della effettiva complessità della figura: il contratto di mutuo, tipico prodotto di una prestazione intellettuale, ben si presta a essere concepito come una composizione di ingegneria giuridica, in cui il lavoro di costruzione, dimensionamento e coordinamento delle diverse clausole, caratterizza il risultato nel suo complesso, e ne rende possibile una valutazione in termini di migliore (o peggiore) qualità e convenienza.</p>
        
        <p>I contratti di mutuo sono generalmente predisposti dalle banche; il notaio se incaricato per tempo può esercitare su di essi un controllo volto a renderli più chiari e comprensibili, a individuare e suggerire  soluzioni meglio adatte ai contraenti, ad eliminare clausole che possono ingenerare un non giustificato squilibrio contrattuale.</p>
        
        <p>Se la struttura di base del contratto è quella indicata, può avvenire, abbastanza di frequente, che il mutuo venga stipulato secondo una disciplina speciale, quella del "credito fondiario.</p>
        
        <p>Tale disciplina oggi si distingue ben poco da quella di un mutuo ipotecario "ordinario", discutendosi addirittura tra gli esperti quando si sia in presenza di una fattispecie oppure di un'altra; tuttavia, esistono delle differenze significative, alcune delle quali saranno evidenziate nelle schede illustrative che seguono.</p>
        
        <p>Non costituisce, invece, una specie particolare, il cosiddetto mutuo unilaterale, diffuso nella recente prassi. La diversità risiede unicamente nel fatto che a recarsi dal notaio (come è indispensabile al fine della concessione della garanzia ipotecaria) è solo il mutuatario: questa modalità di perfezionamento può ridurre, di fatto ed a seconda dei casi, lo spazio del notaio per esercitare la sua funzione di indirizzo e consulenza.</p>
        
        <p>Occorre fin d'ora attirare l'attenzione su un punto essenziale. Non sempre alla stipulazione del mutuo segue immediatamente la materiale disponibilità del denaro: a volte le banche trattengono la somma  fino al momento della effettiva acquisizione della garanzia ipotecaria, cioè, in concreto (a seconda dei diversi modelli), anche per una quindicina di giorni (o più) dopo la stipula.</p>
        
        <p>Il punto è di notevole importanza in quanto, spesso, il mutuatario ha necessità di disporre del denaro subito, per pagare al venditore la casa che contestualmente concede in garanzia alla banca! Un possibile rimedio consiste nell'ottenere dalla banca un finanziamento "ponte" (o "pre-finanziamento"), che copra il periodo tra la stipulazione e il tempo necessario al consolidamento della garanzia; ma non tutte le banche sono a ciò disponibili. Con l'assistenza del notaio si potrà, secondo i casi, cercare di contemperare per quanto possibile le opposte esigenze.</p>
        
        <p>Altra avvertenza concerne un aspetto eventuale: il mutuatario che sappia che l'immobile acquistato, e conces-so in ipoteca, potrebbe essere presto rivenduto per varie esigenze (trasferimento di residenza, aumento dei componenti della famiglia con necessità di una casa più grande, ecc.), a volte fa conto di poter accollare, in conto prezzo, al futuro acquirente dell'immobile la parte del mutuo che gli resta da pagare: a parte il fatto che sull'accollo deve essere d'accordo anche l'acquirente, e che, a volte, le banche pongono qualche ostacolo al-l'operazione, bisogna ricordare che, per regola generale l'accollo non è "liberatorio", ma "cumulativo": ciò significa che, effettuato l'accollo del residuo mutuo, la banca non cambia debitore, ma ne acquisisce uno in più; per cui, se il nuovo acquirente per caso non riesce a pagare la banca, questa potrà ancora avanzare prete-se nei confronti dell'originario mutuatario.</p>
        
        <p>Per questi motivi, la attuale prassi constata, in effetti, un minore ricorso all'accollo: in sostanza il venditore estingue il suo mutuo, mentre l'acquirente, se necessario, ne contrae uno nuovo in proprio. Anche su questo aspetto, il notaio può essere di aiuto, evidenziando le concrete alternative ed i relativi costi.</p>`,
        imageArt: `https://www.notaio-treviso.com/mutuo.webp`,
    },

    {
        id: 2,
        name: "Rent to buy",
        link: "rent-to-buy",
        tipologia: "Compravendita e Finanziamenti",
        tag: ["affitto con riscatto", "acquisto casa"],
        codice_tipo: 1,
        description: `Studio Notarile Falcinelli - Notaio Falcinelli - Il Rent to buy entra in gioco nella situazione in cui: imprese di costruzione e privati proprietari con la necessità di vendere, ma che non trovano acquirenti; acquirenti che vorrebbero comprare, ma che non hanno le occorrenti risorse finanziarie; banche che non concedono credito. Un mix tremendo, che manda in corto circuito il mercato immobiliare e che, tuttavia, ha anche un aspetto, per così dire, positivo ...`,
        corpus: `<p>Rent to buy, buy to rent ed help to buy erano, fino a qualche anno fa, espressioni sconosciute, che non rientravano né nel gergo degli operatori specializzati né nel linguaggio della gente comune e neppure in quello della stampa quotidiana. Quando però oggi, ad ogni livello, si parla di mercato immobiliare, non si può non ricorrere a queste espressioni, che sono divenute d’attualità per essere unite dal comune denominatore di rappresentare un probabilmente piccolo, ma comunque pur sempre significativo, contributo per fronteggiare la profonda crisi in cui è piombato il commercio dei beni immobili.</p>

        <p>La situazione è nota: imprese di costruzione e privati proprietari con la necessità di vendere, ma che non trovano acquirenti; acquirenti che vorrebbero comprare, ma che non hanno le occorrenti risorse finanziarie; banche che non concedono credito. Un mix tremendo, che manda in corto circuito il mercato immobiliare e che, tuttavia, ha anche un aspetto, per così dire, positivo: costringere gli operatori a adoperarsi per trovare possibili soluzioni e, in particolare, costringerli, dato che il sistema non offre incentivi, a rivisitare gli istituti giuridici tradizionali (alcuni addirittura avvolti nell’oblio della desuetudine) per osservarli sotto la luce di un loro rinnovato utilizzo al fine di smuovere, almeno un poco, l’ingessamento derivante dell’attuale pessima congiuntura economica.</p>
        
        <p>Tra l’altro, il fatto di avere fin da subito denominato quelle formule in inglese (per chissà quale mania di definire con anglicismi ogni “cosa nuova” con cui si abbia a che fare), non rende giustizia, perché non premia il notevole sforzo di inventiva degli operatori che le hanno ideate, e ciò in quanto quel nome inglese evoca indubbiamente l’impressione che si tratti di formule “importate”, quando invece la loro ideazione ha profonde radici nel nostro ordinamento. Anzi, come già s’è detto, si tratta, in gran parte, della rivisitazione in chiave attuale di istituti talora antichissimi (alcuni dai quali, tra l’altro, tanto antichi da esser stati praticamente dimenticati e che ora, dunque, vengono, per così dire, “riesumati”) e quindi di un’operazione che trova il proprio solidissimo ancoraggio nelle radici più profonde del nostro sistema giuridico, il quale, una volta tanto, non si mette al traino di esperienze maturate in altri contesti di più fervida attività intellettuale, ma dimostra, da un lato, la propria solidità e, d’altro lato, la propria versatilità, per essere adatto a supportare sia situazioni di floridezza che, come nel caso attuale, situazioni di crisi.</p>
        
        <p>Seppure il rent to buy, il buy to rent e l’help to buy siano soluzioni ovviamente tutte diverse l’una dall’altra, si tratta peraltro di formule legate tra loro da un comune fil rouge e quindi da un unico presupposto: vale a dire, il fatto che si deroga con esse alla tradizionale prassi secondo la quale la stipula di un contratto di compravendita immobiliare coincide con il pagamento del saldo del prezzo dovuto dall’acquirente al venditore. Infatti, se l’acquirente non ha i soldi per pagare e se nessuna banca lo finanzia, altro non resta che al finanziamento provveda “indirettamente” il venditore: accettando, cioè, di ricevere pagamenti dilazionati nel tempo. Una volta compiuto questo cambio di mentalità, rispetto alla scena di una “tradizionale” compravendita, le soluzioni divengono le più varie (e le commenteremo più approfonditamente nelle pagine successive):</p>
        
        <p>a) nel rent to buy, si stipula un contratto di locazione, che poi si trasforma in un contratto di compravendita quanto la somma dei canoni di locazione pagati per un certo tempo abbia eguagliato il prezzo pattuito per la cessione della proprietà dell’immobile;</p>
        
        <p>b) nel buy to rent, si stipula un contratto di compravendita immediatamente traslativo della proprietà con la particolare pattuizione per la quale il prezzo sarà pagato in un certo numero di rate e con la previsione che il caso di inadempimento provoca il ritorno della proprietà in capo al venditore;</p>
        
        <p>c) nell’help to buy, si stipula un contratto preliminare preordinato al versamento a rate, da parte dell’acquirente al venditore, di caparre e di acconti fino al 20-30 per cento del prezzo pattuito, in modo da poter poi presentare a una banca una pratica di finanziamento per il residuo 80-70 per cento del prezzo.</p>
        
        <p>Accanto a queste soluzioni, che sono senz’altro quelle più sofisticate, abbiamo poi anche altre formule da dedicare ai casi concreti nei quali esse meglio si adattino. Si pensi ad esempio:</p>
        
        <p>a) al contratto di vitalizio ipotecario, con il quale una persona anziana può ricevere un finanziamento da una banca, obbligandosi a pagare periodicamente solo gli interessi della somma mutuata e lasciando l’onere di restituire il capitale ai propri eredi, i quali avranno la scelta tra il pagare di tasca propria il debito verso la banca (ottenendo quindi la liberazione dall’ipoteca dell’immobile dato in garanzia alla banca e da essi ottenuto in eredità) e la vendita dell’immobile ipotecato, per utilizzare il ricavato al fine di estinguere il credito della banca;</p>
        
        <p>b) alla vendita di nuda proprietà con riserva dell’usufrutto in capo al venditore (il che concilia l’esigenza del venditore di finanziarsi senza perdere la disponibilità della casa e l’esigenza del compratore di pagare un prezzo basso, “scommettendo” sulla più o meno lunga permanenza in vita del venditore e quindi sulla durata del suo usufrutto).</p>`,
        imageArt: `https://www.notaio-treviso.com/rent-to-buy.webp`,
    },

    {
        id: 3,
        name: "Persone e famiglia",
        link: "persone-e-famiglia",
        tipologia: "Famiglia Unioni Civili e Successioni",
        tag: ["famiglia", "persone"],
        codice_tipo: 2,
        description: `Studio Notarile Falcinelli - Notaio Falcinelli - Il diritto delle persone e della famiglia è un ramo del diritto che si occupa della tutela dei diritti e degli interessi delle persone e delle famiglie. Il notaio è un professionista che può offrire un supporto prezioso in materia di diritto delle persone e della famiglia, sia nella predisposizione di atti notarili che nella consulenza.`,
        corpus: `<p>La legge regola molti aspetti della vita della famiglia e l’ausilio di un tecnico del diritto può evitare di prendere decisioni avventate e non corrette.</p>
        
        <p>Il diritto delle persone e della famiglia è un ramo del diritto che si occupa della tutela dei diritti e degli interessi delle persone e delle famiglie. Il notaio è un professionista che può offrire un supporto prezioso in materia di diritto delle persone e della famiglia, sia nella predisposizione di atti notarili che nella consulenza.</p>

        <p>Il notaio può portare a conoscenza delle diverse disposizioni di legge relative ai singoli casi concreti e può suggerire le soluzioni più idonee per regolare i rapporti tra coniugi, per operare correttamente nei confronti dei figli o nei confronti di eventuali soggetti incapaci, anche al fine di evitare l’assunzione di impegni non conformi alle disposizioni di legge.</p>
        

        <p>Lo Studio Notarile del Notaio Falcinelli di Treviso è un'eccellenza nella città di Treviso per quanto riguarda la consulenza e la predisposizione di atti notarili in materia di diritto delle persone e della famiglia. Il notaio Falcinelli, con la sua lunga esperienza e competenza, saprà consigliare al meglio i propri clienti in materia di diritto delle persone e della famiglia, offrendo loro una consulenza personalizzata in base alle loro esigenze.</p>

        <p>Il notaio Falcinelli è un professionista esperto in materia di diritto di famiglia, in particolare nella stipula di contratti di matrimonio, nella redazione di testamenti, nella costituzione di trust per la tutela dei minori e nella predisposizione di accordi prematrimoniali. Il notaio Falcinelli è inoltre esperto in materia di diritto delle persone, in particolare per quanto riguarda la redazione di atti di nomina di amministratori di sostegno e di testamento biologico.</p>

        <p>Inoltre lo Studio Notarile del Notaio Falcinelli di Treviso si occupa anche di successioni, successioni internazionali e di successioni con testamento. Il notaio Falcinelli e il suo team sono in grado di assistere i propri clienti nella predisposizione di testamenti e nella gestione delle successioni, garantendo che tutti i requisiti legali siano rispettati e che i desideri del defunto siano rispettati.</p>

        <p>Il notaio Falcinelli inoltre offre anche consulenza in materia di diritto immobiliare, in particolare per quanto riguarda la compravendita di immobili, la costituzione di diritti reali, la stipula di contratti di locazione e la costituzione di diritti di superficie.</p>
        
        <p>In sintesi, lo Studio Notarile del Notaio Falcinelli di Treviso offre una vasta gamma di servizi in materia di diritto delle persone e della famiglia, diritto immobiliare e diritto successorio, offrendo una consulenza personalizzata e un servizio di alta qualità ai propri clienti.</p>
        
        `,
        imageArt: `https://www.notaio-treviso.com/persone-e-famiglia.webp`,
    },

    {
        id: 4,
        name: "Unioni civili e convivenze",
        link: "unioni-civili-e-convivenze",
        tipologia: "Famiglia Unioni Civili e Successioni",
        tag: ["famiglia", "persone"],
        codice_tipo: 2,
        description: `Studio Notarile Falcinelli - Notaio Falcinelli - Il matrimonio, l' “unione civile” tra persone di sesso identico, la “convivenza di fatto” registrata all’Anagrafe, la convivenza di fatto non registrata.`,
        corpus: `<p>La nuova legislazione in tema di rapporti di coppia porta come conseguenza uno scenario nel quale vi saranno da osservare quattro situazioni:</p>

        <p>a) il matrimonio, situazione che ha come presupposto fondamentale la diversità di sesso delle persone che compongono la coppia;</p>
        
        <p>b) l' “unione civile” tra persone di sesso identico (non è ammessa una “unione civile” tra persone di diverso sesso poiché, in tal caso, occorre ricorrere al matrimonio);</p>
        
        <p>c) la “convivenza di fatto” registrata all’Anagrafe (tra persone di sesso identico o di sesso diverso);</p>
        
        <p>d) la convivenza di fatto non registrata (che, nella nuova legge, non trova menzione e alla quale è immaginabile sarà data dai giudici la medesima rilevanza finora attribuita, in assenza di qualsiasi legislazione, alle coppie conviventi e non sposate).</p>
        
        <p>Quanto al matrimonio, la relativa disciplina non è toccata dalla legislazione in tema di rapporti di coppia.</p>
        
        <p>La nuova legge invece dà ingresso nel nostro ordinamento all’inedita figura della coppia di omosessuali che si dichiarino allo Stato Civile come “unione civile”. L’unione civile e il matrimonio restano indubbiamente disciplinati da due ben distinti apparati normativi; ma, nonostante talune differenze, moltissimi sono i punti di contatto.</p>
        
        <p>Tra le differenze (oltre a quelle inerenti il sesso dei soggetti che compongono la coppia), le modalità con le quali il matrimonio si celebra e l’unione civile si costituisce; nonché il regime del cognome degli appartenenti all’unione civile (uno dei componenti può adottare il cognome dell’altro e anteporlo o posporlo al proprio).</p>
        
        <p>Circa invece i punti di contatto, basta rilevare, in linea generale, che, la nuova legislazione sancisce che «le disposizioni che si riferiscono al matrimonio e le disposizioni contenenti le parole “coniuge”, “coniugi” o termini equivalenti, ovunque ricorrono nelle leggi, negli atti aventi forza di legge, nei regolamenti nonché negli atti amministrativi e nei contratti collettivi, si applicano anche ad ognuna delle parti dell'unione civile tra persone dello stesso sesso».</p>
        
        <p>In linea particolare, similmente a quanto accade nel matrimonio, i componenti dell’unione civile «acquistano gli stessi diritti e assumono i medesimi doveri»; dall'unione civile inoltre deriva «l'obbligo reciproco all'assistenza morale e materiale e alla coabitazione. Entrambe le parti sono tenute, ciascuna in relazione alle proprie sostanze e alla propria capacità di lavoro professionale e casalingo, a contribuire ai bisogni comuni». Ancora, i membri dell’unione civile «concordano tra loro l'indirizzo della vita familiare e fissano la residenza comune; a ciascuna delle parti spetta il potere di attuare l'indirizzo concordato». Sono, in effetti, se si esclude l’obbligo di fedeltà che la legge detta per la coppia sposata, le stesse parole che il codice civile riferisce ai componenti della coppia unita in matrimonio.</p>
        
        <p>Tra le più rilevanti conseguenze della nuova legislazione in materia di “unione civile” e di “convivenza di fatto” svetta senz’altro la rivoluzione che questa normativa comporta nella materia degli interessi economici dei componenti di queste nuove forme di vita in comune.</p>
        
        <p>Infatti, prendendo in considerazione i rapporti patrimoniali che si origineranno nel corso della vita di coppia, occorre notare che la nuova legge equipara, sotto ogni aspetto, i componenti di una unione civile con i coniugi di un matrimonio: pertanto, in mancanza di una convenzione matrimoniale di adozione del regime di separazione dei beni (che, anche nel caso di unione civile, deve essere stipulata nella forma dell’atto pubblico), sia nel matrimonio che nell’unione civile si instaura il regime di comunione dei beni, nel senso che diventano di titolarità comune i beni e i diritti acquistati nel periodo durante il quale si svolge il matrimonio o l’unione civile.</p>
        
        <p>Inoltre, tanto quanto i coniugi, anche i componenti di una unione civile possono adottare il regime del fondo patrimoniale.</p>
        
        <p>Uno scenario diverso si ha invece nel caso di convivenza di fatto registrata poiché, in questa situazione, non si instaura ex lege un regime di comunione degli acquisti, in quanto ognuno dei conviventi di fatto rimane esclusivo titolare di ciò che egli compera.</p>
        
        <p>E’ però possibile a coloro che compongono la coppia di conviventi registrata, stipulare un “contratto di convivenza” (con atto pubblico notarile o con scrittura privata autenticata da un notaio o da un avvocato, di cui è disposta la pubblicità nei registri anagrafici) mediante il quale anche nel regime di convivenza registrata si ottiene la messa in comune dei beni e dei diritti che i conviventi di fatto acquisiscano nel periodo in cui la convivenza registrata si svolge.</p>

        <p>Secondo la nuova legge, questo contratto di convivenza, oltre che regolamentare il regime degli acquisti durante la convivenza, potrà contenere anche le modalità di contribuzione dei conviventi alle necessità della vita in comune, in relazione al patrimonio e al reddito di ciascuno di essi e alla loro capacità di lavoro professionale e casalingo.</p>
        
        <p>La legge non dice altro sul contratto di convivenza, se non che ad esso non sono apponibili condizioni e termini: spetterà dunque all’elaborazione degli studiosi prima, e della giurisprudenza poi, stabilire se il contratto di convivenza potrà essere suscettibile di “ospitare” altri contenuti, quali, ad esempio, la definizione in anticipo (vietata invece nel caso del matrimonio) dei comportamenti da tenere e delle contribuzioni da effettuare in caso di cessazione del rapporto di convivenza.</p>
        
        <p>Occorre infine notare che le coppie sposate, unite in una unione civile o in una convivenza di fatto registrata, saranno equiparate sotto ogni aspetto nel caso in cui uno dei membri della coppia esercisca una impresa sotto forma di “impresa familiare”. Infatti, in questa ipotesi, qualsiasi sia il rapporto (matrimonio, unione civile, convivenza registrata) che unisce il soggetto imprenditore con l’altro componente della coppia, quest’ultimo partecipa in ogni caso agli utili e agli incrementi dell’impresa individuale del componente della coppia titolare dell’impresa.</p>
        `,
        imageArt: `https://www.notaio-treviso.com/unioni-civili-e-convivenze.webp`,
    },

    {
        id: 5,
        name: "Eredità e testamento",
        link: "eredità-e-testamento",
        tipologia: "Famiglia Unioni Civili e Successioni",
        tag: ["famiglia", "persone", "successione", "morte"],
        codice_tipo: 2,
        description: `Studio Notarile Falcinelli - Notaio Falcinelli - È fondamentale per chiunque intenda devolvere i propri beni, disporre consapevolmente del proprio patrimonio secondo le regole previste dalla legge.`,
        corpus: `
        
        <p>La successione e il testamento sono aspetti importanti del diritto che riguardano la distribuzione dei beni di una persona dopo la sua morte. Il notaio è un professionista che può offrire un supporto prezioso in materia di eredità e testamento, sia nella predisposizione di atti notarili che nella consulenza.</p>

        <p>Lo Studio Notarile del Notaio Falcinelli di Treviso è un'eccellenza nella città di Treviso per quanto riguarda la consulenza e la predisposizione di atti notarili in materia di eredità e testamento. Il notaio Falcinelli, con la sua lunga esperienza e competenza, saprà consigliare al meglio i propri clienti in materia di eredità e testamento, offrendo loro una consulenza personalizzata in base alle loro esigenze.</p>

        <p>Il notaio Falcinelli è esperto nella predisposizione di testamenti, sia ordinari che testamenti biologici, per garantire che i desideri del testatore siano rispettati e che i propri cari siano tutelati in caso di sua prematura scomparsa. Inoltre, il notaio Falcinelli è in grado di assistere i propri clienti nella gestione delle successioni, garantendo che tutti i requisiti legali siano rispettati e che i beni vengano distribuiti in modo equo e corretto.</p>

        <p>Il notaio Falcinelli inoltre offre anche consulenza in materia di diritto successorio internazionale, per garantire che le successioni che coinvolgono beni situati in paesi diversi dall'Italia siano gestite in modo corretto e conforme alle leggi locali. Inoltre, il notaio Falcinelli è in grado di assistere i propri clienti nella predisposizione di accordi di successione anticipata, per garantire che i propri beni vengano distribuiti secondo i propri desideri, anche in caso di malattia o invalidità.</p>

        <p>In generale, lo Studio Notarile del Notaio Falcinelli di Treviso offre una vasta gamma di servizi in materia di eredità e testamento, fornendo una consulenza personalizzata e un servizio di alta qualità ai propri clienti. Il notaio Falcinelli e il suo team sono in grado di assistere i propri clienti in tutte le fasi del processo di successione e testamento, garantendo che i loro desideri e interessi siano tutelati in modo adeguato.</p>
        
        <br></br>
        <p>Concludendo:</p>
        <p>È fondamentale per chiunque intenda devolvere i propri beni, disporre consapevolmente del proprio patrimonio secondo le regole previste dalla legge.</p>

        <p>Il nostro ordinamento stabilisce che una quota di eredità, la legittima, spetta di diritto ai parenti più stretti e di essa bisogna tener conto anche nel redigere il proprio testamento.</p>
        
        <p>Così come per chi è “chiamato all’eredità” è importante conoscerne la consistenza in termini di diritti e di oneri anche di carattere fiscale. Di qui la necessità di essere informati e di valutare le modalità con cui accettarla.</p>
        
        <p>Grazie alla sua preparazione specifica in materia, il notaio può aiutare a compiere le scelte più adeguate per affrontare una successione o una donazione senza rischi.</p>`,
        imageArt: `https://www.notaio-treviso.com/eredit%C3%A0-e-testamento.webp`,
    },

    {
        id: 6,
        name: "Testamento di vita",
        link: "testamento-di-vita",
        tipologia: "Famiglia Unioni Civili e Successioni",
        tag: ["famiglia", "persone", "successione", "morte"],
        codice_tipo: 2,
        description: `Studio Notarile Falcinelli - Notaio Falcinelli - Il testamento biologico (detto anche: testamento di vita o dichiarazione anticipata di trattamento) è l'espressione della volontà da parte di una persona (il "testatore"), fornita in condizioni di lucidità mentale, in merito alle terapie che intende o non intende accettare nell'eventualità in cui dovesse trovarsi nella condizione di incapacità di esprimere il proprio diritto di acconsentire o non acconsentire alle cure proposte ...`,
        corpus: `<p>Il testamento biologico (detto anche: testamento di vita o dichiarazione anticipata di trattamento) è l'espressione della volontà da parte di una persona (il "testatore"), fornita in condizioni di lucidità mentale, in merito alle terapie che intende o non intende accettare nell'eventualità in cui dovesse trovarsi nella condizione di incapacità di esprimere il proprio diritto di acconsentire o non acconsentire alle cure proposte (cosiddetto "consenso informato") per malattie o lesioni traumatiche cerebrali irreversibili o invalidanti, malattie che costringano a trattamenti permanenti con macchine o sistemi artificiali che impediscano una normale vita di relazione.
        La parola "testamento" viene presa in prestito dal linguaggio giuridico, con ciò riferendosi ai testamenti tradizionali nei quali, di solito, si lasciano scritti (di pugno) le volontà di divisione dei beni materiali per gli eredi o beneficiari. Nel mondo anglosassone lo stesso documento viene anche chiamato living will (impropriamente tradotto come "volontà del vivente"). La volontà sulla sorte della persona passa ai congiunti di primo grado qualora la persona stessa non sia più in grado di intendere e di volere per motivi biologici.</p>
        
        <p>La legge sul testamento biologico (legge 2 dicembre 2017, n. 219 pubblicata sulla Gazzetta Ufficiale n. 12 del 16 gennaio 2018) è in vigore dal 31 gennaio 2018.</p>
        
        <p>Nel rispetto dei principi di cui agli articoli 2, 13 e 32 della Costituzione e degli articoli 1, 2 e 3 della Carta dei diritti fondamentali dell'Unione europea, a tutela del diritto alla vita, alla salute, alla dignità e all'autodeterminazione della persona, la legge ribadisce il principio per cui nessun trattamento sanitario può essere iniziato o proseguito se privo del consenso libero e informato della persona interessata, tranne che nei casi espressamente previsti dalla legge. Il cuore della legge è l’introduzione della disciplina delle DAT, disposizioni anticipate di trattamento, con le quali le persone possono dare indicazioni sui trattamenti sanitari da ricevere o da rifiutare nei casi in cui si trovassero in condizioni di incapacità.</p>
        
        
        <h2 className = "h2postContent">Che cosa sono le DAT ?</h2 className = "h2postContent">
        <p>Sono delle disposizioni/indicazioni che la persona, in previsione della eventuale futura incapacità di autodeterminarsi, può esprimere in merito alla accettazione o rifiuto di determinati:</p>
        <ul>
        <li>accertamenti diagnostici</li>
        <li>scelte terapeutiche (in generale)</li>
        <li>singoli trattamenti sanitari (in particolare).</li>
        </ul>
        <h2 className = "h2postContent">Quale la novità ?</h2 className = "h2postContent">
        <p>Viene definitivamente sgombrato il campo dal dubbio se la nutrizione e la idratazione artificiale possano essere rifiutate. Dalla legge (art. 1, comma 5) sono considerate una modalità di somministrazione, su prescrizione medica, di nutrienti mediante dispositivi medici e, pertanto, in quanto tali, a tutti gli effetti “trattamenti sanitari”.</p>
        
        <h2 className = "h2postContent">Chi può fare le DAT ?</h2 className = "h2postContent">
        <p>Qualunque persona che sia</p>
        <ul>
        <li>maggiorenne</li>
        <li>capace di intendere e di volere.</li>
        </ul>

        <h2 className = "h2postContent">In che forma si possono manifestare le DAT ?</h2 className = "h2postContent">
        <ul>
        <li>Atto pubblico notarile</li>
        <li>Scrittura privata autenticata dal notaio</li>
        <li>Scrittura privata semplice consegnata personalmente all’Ufficio dello Stato Civile del Comune di residenza del disponente.</li>
        </ul>
        <p>L’atto non sconta nessun tipo di imposta (di registro, di bollo) né tassa o diritto.</p>
        
        <h2 className = "h2postContent">E se il paziente non è in condizioni di firmare ?</h2 className = "h2postContent">
        <p>La legge notarile prevede la possibilità di stipulare l’atto in presenza di due testimoni. Può, inoltre, manifestare le DAT anche attraverso una videoregistrazione o anche altro dispositivo che consenta di comunicare.</p>
        
        <h2 className = "h2postContent">Occorre una preventiva consultazione con un medico ?</h2 className = "h2postContent">
        <p>Sì. La legge stabilisce che la persona acquisisca preventivamente adeguate informazioni mediche sulle conseguenze delle proprie scelte.</p>
        
        <h2 className = "h2postContent">Si possono revocare o modificare le DAT ?</h2 className = "h2postContent">
        <p>Sì, in qualunque momento</p>
        <ul>
        <li>utilizzando la stessa forma con cui sono state rilasciate</li>
        <li>o, quando motivi di urgenza o altra impossibilità, non consentano di rispettare la stessa forma simmetrica, mediante dichiarazione verbale o videoregistrazione raccolta da un medico alla presenza di due testimoni.</li>
        </ul>

        <h2 className = "h2postContent">Sono valide le DAT rilasciate prima di questa legge ?</h2 className = "h2postContent">
        <p>Sì. Conservano validità se ed in quanto non risultino contrarie alle prescrizioni di questa legge, anche se rilasciate in un periodo di vuoto normativo.
        
        <h2 className = "h2postContent">Dove viene pubblicizzata la DAT ?</h2 className = "h2postContent">
        <ul>
        <li>In un registro comunale (ove già istituito)</li>
        <li>In un registro sanitario elettronico su base regionale, ove le Regioni abbiano istituito una modalità telematica di gestione della cartella clinica. In tal caso il disponente ha la scelta se far pubblicare copia della DAT ovvero lasciare solo indicazioni di chi sia il fiduciario o dove siano reperibili in copia.</li>
        </ul>

        <h2 className = "h2postContent">Quindi manca una Banca dati a livello nazionale ?</h2 className = "h2postContent">
        <p>La legge 219 prevede solo registri regionali: se la persona è ricoverata in una regione diversa da quella in cui vive si rischia di non conoscere le DAT. La legge di Bilancio <p>per il 2108 stanzia 2 milioni di euro per un registro nazionale. Il Notariato ha quasi ultimato un registro nazionale – non accessibile al pubblico per motivi di privacy e senza costi per lo Stato – consultabile da parte di tutte le aziende sanitarie italiane.</p>
        
        <h2 className = "h2postContent">Si può nominare un terzo che si interfacci con i medici ?</h2 className = "h2postContent">
        <p>Sì. La legge prevede la possibilità (non l’obbligo) di nominare un fiduciario che sostituisca il disponente divenuto incapace nei rapporti con i medici e la struttura sanitaria, eventualmente consentendo di disattenderle, di concerto con il medico, solo nel caso in cui:</p>
        <ul>
        <li>appaiano palesemente incongrue</li>
        <li>non siano corrispondenti alla condizione clinica attuale del paziente</li>
        <li>siano sopravvenute terapie non prevedibili alla data di redazione delle DAT.</li>
        </ul>

        <h2 className = "h2postContent">Cosa succede in caso di contrasto tra il fiduciario e il medico ?</h2 className = "h2postContent">
        <p>L’art 3, comma 5, della legge prescrive che in tal caso la decisione è rimessa al Giudice Tutelare su ricorso del rappresentante legale della persona interessata, ovvero dei soggetti di cui all’art. 406 c.c. o del medico o del direttore della struttura sanitaria.</p>
        
        <h2 className = "h2postContent">Si possono nominare più fiduciari ?</h2 className = "h2postContent">
        <p>La legge non lo vieta, ma sarebbe opportuno di no, onde evitare possibili contrasti tra loro. In ogni caso è opportuna la nomina di un secondo fiduciario che subentri nel caso in cui il primo nominato non possa o non voglia accettare l’incarico.</p>
        
        <h2 className = "h2postContent">Il fiduciario nominato ha l’obbligo di accettare ?</h2 className = "h2postContent">
        <p>No. Il fiduciario può non accettare l’incarico (che prevede una sottoscrizione delle DAT per accettazione) ovvero rifiutarlo successivamente con atto scritto comunicato al disponente. Se accetta, gli viene consegnata una copia delle DAT.</p>
        
        <h2 className = "h2postContent">Il disponente può revocare o modificare il fiduciario ?</h2 className = "h2postContent">
        <p>Sì, lo può fare. In qualsiasi momento, senza obbligo di motivazione nelle stesse forme in cui lo ha nominato.</p>
        
        <h2 className = "h2postContent">Se viene revocato il fiduciario senza sostituzione o questi rinunzia le DAT perdono efficacia ?</h2 className = "h2postContent">
        <p>No. Le DAT conservano il loro valore prescrittivo per il medico e la struttura sanitaria. In mancanza del fiduciario, in caso di necessità di un alter ego, sarà il Giudice <p>Tutelare a nominare un amministratore di sostegno che svolga i medesimi compiti.</p>
        `,
        imageArt: `https://www.notaio-treviso.com/testamento-di-vita.webp`,
    },

    {
        id: 7,
        name: "Donazioni",
        link: "donazioni",
        tipologia: "Donazioni - Trust - Tutela del Patrimonio",
        tag: ["donazioni", "trust", "tutela del patrimonio"],
        codice_tipo: 3,
        description: `Studio Notarile Falcinelli - Notaio Falcinelli - La donazione è il contratto con il quale, per spirito di liberalità, una persona arricchisce l’altra, disponendo a suo favore di un suo diritto o assumendo verso la stessa un’obbligazione.`,
        corpus: `<p>La donazione è il contratto con il quale, per spirito di liberalità, una persona arricchisce l’altra, disponendo a suo favore di un suo diritto o assumendo verso la stessa un’obbligazione.</p>

        <p>Poiché una volta conclusa è di norma irrevocabile ad opera di una delle parti, per la donazione è essenziale è la forma del contratto: deve essere conclusa sotto il controllo del notaio per atto pubblico alla presenza di due testimoni.</p>
        
        <p>È opportuno quindi farsi consigliare dal notaio che potrà indicare le soluzioni giuridiche più adatte ad evitare futuri, dolorosi contenziosi familiari e rilevanti problemi di commerciabilità dei beni donati.</p>

        <p>La donazione è un atto giuridico con cui una persona (donante) trasferisce la proprietà di un bene a un'altra persona (donatario), senza ricevere alcun compenso in cambio. Il notaio è un professionista che può offrire un supporto prezioso nella stipula di contratti di donazione, sia nella predisposizione degli atti notarili che nella consulenza.</p>

        <p>Lo Studio Notarile del Notaio Falcinelli di Treviso è un'eccellenza nella città di Treviso per quanto riguarda la consulenza e la predisposizione di atti notarili in materia di donazione. Il notaio Falcinelli, con la sua lunga esperienza e competenza, saprà consigliare al meglio i propri clienti in materia di donazione, offrendo loro una consulenza personalizzata in base alle loro esigenze.</p>

        <p>Il notaio Falcinelli è esperto nella predisposizione di contratti di donazione, sia per quanto riguarda beni mobili che immobili, e sarà in grado di assistere i propri clienti nella scelta della forma più adatta per la donazione, in base alle esigenze dei donanti e dei donatari. Inoltre, il notaio Falcinelli può fornire un supporto prezioso nella definizione dei termini e delle condizioni del contratto di donazione, per garantire che sia conforme alla legge e che tutti i requisiti siano rispettati.</p>

        <p>La donazione può essere utilizzata per diversi scopi, come ad esempio per trasferire la proprietà di un bene a un familiare o ad un amico, per favorire un'organizzazione benefica o per ottenere benefici fiscali. Il notaio Falcinelli è in grado di consigliare i propri clienti in base alle loro esigenze specifiche, per garantire che la donazione sia effettuata in modo corretto e conforme alla legge.</p>

        <p>In generale, lo Studio Notarile del Notaio Falcinelli di Treviso offre un'ampia gamma di servizi in materia di donazione, fornendo una consulenza personalizzata e un servizio di alta qualità ai propri clienti. Il notaio Falcinelli e il suo team saranno in grado di assistere i propri clienti in tutte le fasi del processo di donazione, garantendo che i loro desideri e interessi siano tutelati in modo adeguato.</p>
        
        `,
        imageArt: `https://www.notaio-treviso.com/donazioni.webp`,
    },

    {
        id: 8,
        name: "Patto di famiglia",
        link: "patto-di-famiglia",
        tipologia: "Donazioni - Trust - Tutela del Patrimonio",
        tag: ["donazioni", "trust", "tutela del patrimonio"],
        codice_tipo: 3,
        description: `Studio Notarile Falcinelli - Notaio Falcinelli - Si tratta della possibilità per un imprenditore di gestire il passaggio generazionale della propria impresa, trasferendo ad uno o più discendenti l'azienda o le quote di partecipazione al capitale della “società di famiglia”, senza che vi possano essere contestazioni in sede di eredità.`,
        corpus: `<p>Nel 2006 è stato introdotto nel nostro ordinamento il “patto di famiglia”.</p>

        <p>Si tratta della possibilità per un imprenditore di gestire il passaggio generazionale della propria impresa, trasferendo ad uno o più discendenti l'azienda o le quote di partecipazione al capitale della “società di famiglia”, senza che vi possano essere contestazioni in sede di eredità.</p>
        
        <p>E’ una novità importante nel sistema del diritto successorio: nel nostro Paese è infatti piuttosto diffusala presenza di imprese a carattere “familiare”.</p>
        
        <p>Pur incidendo notevolmente sulla sostanza della successione testamentaria dell’imprenditore, il patto di famiglia è un contratto tipicamente tra vivi, che comporta il trasferimento immediato dell’impresa di famiglia.</p>
        
        <p>Il patto di famiglia deve essere stipulato per atto pubblico dal notaio a pena di nullità e vi devono partecipare coloro che sarebbero legittimari (cioè eredi che la legge prevede non possano essere esclusi, come ad esempio il coniuge e i figli) se in quel momento si aprisse la successione dell’imprenditore.</p>
        
        <p>Il patto deve prevedere che i beneficiari assegnatari dell'azienda o delle partecipazioni societarie “compensino” gli altri partecipanti al contratto con il pagamento di una somma corrispondente al valore delle quote riservate ai legittimari (a meno che questi non vi rinuncino in tutto o in parte).</p>
        
        <p>I contraenti possono convenire che la liquidazione, in tutto o in parte, avvenga in natura, ossia ricevendo alcuni beni al posto del denaro; in questo caso i beni in natura assegnati a favore degli altri legittimari (non assegnatari dell'azienda) "sono imputati alle quote di legittima loro spettanti", cioè sono da considerarsi un anticipo sulla futura eredità.</p>
        
        <p>All'apertura della successione dell'imprenditore alcuni nuovi soggetti possono assumere la qualifica di legittimari dopo la stipula del patto di famiglia (ad esempio, il nuovo coniuge dell’imprenditore vedovo o celibe; nuovi figli): in questo caso costoro potranno chiedere ai beneficiari del patto di famiglia il pagamento di una somma pari al valore della quota di legittima che gli spetta per legge.</p>`,
        imageArt: `https://www.notaio-treviso.com/patto-di-famiglia.webp`,
    },

    {
        id: 9,
        name: "Trust e affidamento fiduciario",
        link: "trust-e-affidamento-fiduciario",
        tipologia: "Donazioni - Trust - Tutela del Patrimonio",
        tag: ["donazioni", "trust", "tutela del patrimonio"],
        codice_tipo: 3,
        description: `Studio Notarile Falcinelli - Notaio Falcinelli - Il trust consiste in un rapporto in virtù del quale un dato soggetto, chiamato trustee (o fiduciario), cui sono attribuiti i diritti e i poteri di un vero e proprio proprietario (o legal owner) gestisce un patrimonio per uno scopo prestabilito, purchè lecito e non contrario all'ordine pubblico.`,
        corpus: `
        
        <p>Il trust notarile è uno strumento giuridico utilizzato per proteggere il patrimonio in caso di malattia, invalidità o decesso. In Italia, il trust notarile è stato introdotto solo recentemente dalla legge del 12 luglio 2019, n. 69, nota come "Decreto Crescita 2.0".</p>

        <p>Un trust è un accordo in base al quale una persona (il disponente) trasferisce la proprietà di un bene a un'altra persona (il trustee) per la gestione in beneficio di una o più persone (i beneficiari). Ci sono diversi tipi di trust, come ad esempio trust discrezionali, trust per la tutela dei minori e trust a scopo di previdenza.</p>

        <p>Il trust notarile in Italia è uno strumento molto versatile che può essere utilizzato per diverse finalità, come ad esempio la protezione dei beni in caso di malattia, invalidità o decesso, la tutela dei minori, la previdenza per la vecchiaia e la protezione dei beni aziendali.</p>

        <p>Il trust notarile può essere utilizzato anche per la protezione dei beni immobiliari, per la gestione dei beni aziendali e per la costituzione di fondazioni. Inoltre, il trust notarile può essere utilizzato per la protezione dei beni in caso di crisi aziendale o per la costituzione di fondi patrimoniali.</p>

        <p>Lo Studio Notarile del Notaio Falcinelli di Treviso offre un servizio di alta qualità e una consulenza personalizzata in materia di trust notarile. Il Notaio Falcinelli e il suo team di professionisti sono a disposizione per fornire assistenza nella costituzione di un trust e nella gestione dei beni in esso trasferiti.</p>

        <p>In generale, il trust notarile è uno strumento giuridico molto versatile che può essere utilizzato per proteggere il patrimonio in diverse situazioni e per diverse finalità. Grazie all'esperienza e alla competenza del Notaio Falcinelli e del suo team, lo Studio Notarile di Treviso è in grado di fornire una consulenza personalizzata per la costituzione di un trust notarile e per la gestione dei beni in esso trasferiti.</p>

        <p>Inoltre, lo Studio Notarile del Notaio Falcinelli offre anche servizi di assistenza e consulenza per la redazione di testamenti, per la gestione di successioni e per la tutela del patrimonio in generale.</p>

        <p>Per chi cerca un servizio di alta qualità e una consulenza personalizzata in materia di trust notarile, lo Studio Notarile del Notaio Falcinelli di Treviso è la scelta ideale. Grazie all'esperienza e alla competenza del Notaio Falcinelli e del suo team, lo Studio Notarile di Treviso è in grado di fornire un servizio completo e professionale per la protezione del patrimonio e per la tutela dei propri cari.</p>

        <p>Per coloro che cercano un notaio esperto in trust notarili a Treviso, lo Studio Notarile del Notaio Falcinelli rappresenta una scelta ideale, offrendo un servizio di alta qualità e una consulenza professionale e personalizzata. Contattando lo studio notarile del Notaio Falcinelli si potrà avere la certezza di essere assistiti in modo efficace e professionale, per costituire un trust adatto alle proprie esigenze.</p>
        <br></br>
        <p>Conclusioni:</p>

        <p>In linea generale, si può dire che il trust consiste in un rapporto in virtù del quale un dato soggetto, chiamato trustee (o fiduciario), cui sono attribuiti i diritti e i poteri di un vero e proprio proprietario (o legal owner) gestisce un patrimonio per uno scopo prestabilito, purchè lecito e non contrario all'ordine pubblico.</p>

        <p>Per esempio, fra le possibili finalità del trust, può evidenziarsi quella di attribuire al trustee un certo patrimonio al fine di conservarlo per restituirlo a determinati destinatari finali (remaindermen) al verificarsi di un dato evento (come la morte del disponente oppure il raggiungimento da parte del destinatario di determinati traguardi quali il matrimonio, la laurea o la maggiore età) oppure per attribuire vantaggi a soggetti terzi, i cosiddetti beneficiaries, al cui esclusivo vantaggio devono dunque essere usati quei poteri.</p>
        
        
        <p>Il contratto di affidamento fiduciario è quel contratto con il quale un soggetto, l’affidante fiduciario, concorda con un altro soggetto, l’affidatario fiduciario, di destinare i “beni affidati” a vantaggio di uno o più soggetti, detti Beneficiari, in forse di un programma, la cui attuazione è assegnata all’affidatario fiduciario che ne assume l’impegno.</p> 
        
        <p>Al termine del programma i Beneficiari riceveranno i beni liberi da vincoli.</p>
        
        <p>È quindi un contratto cha ha ad oggetto un trasferimento inter vivos di beni, dove l’oggetto rappresentato dal programma destinatorio e la causa è generalmente di garanzia, gestoria, di segregazione e così via.</p>`,
        imageArt: `https://www.notaio-treviso.com/trust-affidamento-fiduciario.webp`,
    },

    {
        id: 10,
        name: "Tutela del patrimonio",
        link: "tutela-del-patrimonio",
        tipologia: "Donazioni - Trust - Tutela del Patrimonio",
        tag: ["donazioni", "trust", "tutela del patrimonio"],
        codice_tipo: 3,
        description: `Studio Notarile Falcinelli - Notaio Falcinelli - La tutela del patrimonio è un aspetto importante del diritto che riguarda la protezione dei beni di una persona o di un'azienda. Il notaio è un professionista che può offrire un supporto prezioso in materia di tutela del patrimonio, sia nella predisposizione di atti notarili che nella consulenza.`,
        corpus: `<p>
        La tutela del patrimonio è un aspetto importante del diritto che riguarda la protezione dei beni di una persona o di un'azienda. Il notaio è un professionista che può offrire un supporto prezioso in materia di tutela del patrimonio, sia nella predisposizione di atti notarili che nella consulenza.</p>

        <p>Lo Studio Notarile del Notaio Falcinelli di Treviso è un'eccellenza nella città di Treviso per quanto riguarda la consulenza e la predisposizione di atti notarili in materia di tutela del patrimonio. Il notaio Falcinelli, con la sua lunga esperienza e competenza, saprà consigliare al meglio i propri clienti in materia di tutela del patrimonio, offrendo loro una consulenza personalizzata in base alle loro esigenze.</p>

        <p>Il notaio Falcinelli è esperto nella predisposizione di strumenti di tutela del patrimonio, come ad esempio trust, fondazioni e patti di famiglia, per garantire che i beni di una persona o di un'azienda siano protetti e gestiti in modo adeguato, sia in caso di malattia, invalidità o decesso.</p>

        <p>Inoltre, il notaio Falcinelli è in grado di assistere i propri clienti nella costituzione di società e nella predisposizione di contratti commerciali, per garantire che i beni aziendali siano protetti e gestiti in modo adeguato.</p>

        <p>In generale, lo Studio Notarile del Notaio Falcinelli di Treviso offre una vasta gamma di servizi in materia di tutela del patrimonio, fornendo una consulenza personalizzata e un servizio di alta qualità ai propri clienti. Il notaio Falcinelli e il suo team sono in grado di assistere i propri clienti in tutte le fasi del processo di tutela del patrimonio, garantendo che i loro beni siano protetti e gestiti in modo adeguato.
        </p>

        <p>La tutela del patrimonio è un'attività fondamentale per garantire che i beni di una persona o di un'azienda siano protetti e gestiti in modo adeguato. Ci sono diversi strumenti giuridici che possono essere utilizzati per proteggere il patrimonio, come ad esempio trust, fondazioni e patti di famiglia.</p>

        <p>I trust, ad esempio, sono uno strumento giuridico molto versatile che può essere utilizzato per proteggere il patrimonio in caso di malattia, invalidità o decesso. Un trust è un accordo in base al quale una persona (il disponente) trasferisce la proprietà di un bene a un'altra persona (il trustee) per la gestione in beneficio di una o più persone (i beneficiari). Ci sono diversi tipi di trust, come ad esempio trust discrezionali, trust per la tutela dei minori e trust a scopo di previdenza.</p>

        <p>Le fondazioni, invece, sono un'altra forma di tutela del patrimonio che può essere utilizzata per proteggere i beni in caso di decesso o per scopi benefici. Una fondazione è un'entità giuridica che ha lo scopo di gestire e proteggere i beni a beneficio di una o più persone o enti.</p>

        <p>I patti di famiglia, infine, sono un accordo tra membri della famiglia che hanno lo scopo di regolamentare la gestione dei beni familiari e di proteggere il patrimonio in caso di decesso o di altri eventi imprevisti.</p>

        <p>In generale, la tutela del patrimonio è un'attività complessa che richiede una competenza specifica e una consulenza personalizzata. Lo Studio Notarile del Notaio Falcinelli di Treviso offre un servizio di alta qualità e una consulenza personalizzata in materia di tutela del patrimonio, per garantire che i beni dei propri clienti siano protetti e gestiti in modo adeguato.</p>
        `,
        imageArt: `https://www.notaio-treviso.com/tutela-del-patrimonio.webp`,
    },

    {
        id: 11,
        name: "Aziende e società",
        link: "aziende-e-società",
        tipologia: "Aziende e società",
        tag: ["aziende", "società"],
        codice_tipo: 4,
        description: `Studio Notarile Falcinelli - Notaio Falcinelli - Nel momento in cui si decide di costituire una società, è necessario capire prima, anche con l’aiuto del notaio, quale tipo di società risponde meglio alle proprie esigenze, sia sotto il profilo organizzativo, sia delle responsabilità e degli scopi da raggiungere.`,
        corpus: `<p>Nel momento in cui si decide di costituire una società, è necessario capire prima, anche con l’aiuto del notaio, quale tipo di società risponde meglio alle proprie esigenze, sia sotto il profilo organizzativo, sia delle responsabilità e degli scopi da raggiungere.</p>

        <p>Si distinguono le società di persone e le società di capitali.</p>
        
        <p>Le società di persone non hanno personalità giuridica: delle obbligazioni della società rispondono anche i soci; quindi i debiti della società li pagano anche i soci (con alcune eccezioni previste dalla legge). Esistono:</p>
        <ul>
        <li>la società semplice (S.s.);</li>
        <li>la società in nome collettivo (S.n.c.);</li>
        <li>la società in accomandita semplice (S.a.s.).</li>
        </ul>
        <p>Le società di capitali hanno personalità giuridica: delle obbligazioni della società risponde solo la società, non i soci. I debiti della società li paga solo la società, non i soci (con alcune eccezioni previste dalla legge). Esistono:</p>
        <ul>
        <li>le società per azioni (S.p.A.);</li>
        <li>le società in accomandita per azioni (S.a.p.a.);</li>
        <li>le società a responsabilità limitata (S.r.l.);</li>
        <li>le società a responsabilità limitata semplificata (S.r.l.s.).</li>
        </ul>
        <p>Anche le società cooperative hanno personalità giuridica: delle obbligazioni della società risponde solo la società, non i soci. i debiti della società li paga solo la società, non i soci (con alcune eccezioni previste dalla legge).</p>
        
        <p>Le società di capitali possono svolgere, attraverso la costituzione di consorzi, anche attività consortile che consiste nella organizzazione comune per la disciplina e lo svolgimento di determinate fasi delle rispettive imprese.</p>
        
        <p>La personalità giuridica si acquista con l'iscrizione da parte del notaio dell'atto pubblico costitutivo della società presso il Registro delle Imprese.</p>
        `,
        imageArt: `https://www.notaio-treviso.com/aziende-società.webp`,
    },

    {
        id: 12,
        name: "Contratto di rete",
        link: "contratto-di-rete",
        tipologia: "Aziende e società",
        tag: ["aziende", "società"],
        codice_tipo: 4,
        description: `Studio Notarile Falcinelli - Notaio Falcinelli - Il contratto di rete tra imprese è un nuovo strumento giuridico, introdotto nell’ordinamento italiano nell’anno 2009, che consente alle aggregazioni di imprese di instaurare tra loro una collaborazione organizzata e duratura, mantenendo la propria autonomia e la propria individualità (senza costituire un’organizzazione come la società o il consorzio), nonché di fruire di rilevanti incentivi e di agevolazioni fiscali.`,
        corpus: `
        <p>Il contratto di rete è un accordo tra imprese indipendenti finalizzato alla creazione di una struttura organizzativa comune per la realizzazione di obiettivi comuni. Questo tipo di contratto consente alle imprese di unirsi per condividere risorse, competenze e conoscenze al fine di aumentare la loro competitività sul mercato.</p>

        <p>Il notaio svolge un ruolo fondamentale nella stipula di un contratto di rete, in quanto è lui che redige il contratto e ne certifica la validità. Inoltre, il notaio è un professionista che può offrire un supporto prezioso nella scelta della forma più adatta per la costituzione del contratto di rete e nella definizione degli obiettivi.</p>
        
        <p>Lo Studio Notarile del Notaio Falcinelli di Treviso, è un'eccellenza nella città di Treviso, per quanto riguarda la consulenza e la predisposizione di atti notarili e nella costituzione di contratti di rete. Il notaio Falcinelli, con la sua lunga esperienza e competenza, saprà consigliare al meglio i propri clienti per quanto riguarda la costituzione di contratti di rete, offrendo loro una consulenza personalizzata in base alle loro esigenze.</p>
        
        <p>I contratti di rete possono essere utilizzati per diversi scopi, ad esempio per aumentare la competitività sul mercato, per creare sinergie tra imprese o per condividere risorse e competenze. La costituzione di un contratto di rete può rappresentare una soluzione efficace per le imprese che vogliono unirsi per raggiungere obiettivi comuni e per aumentare la loro competitività sul mercato.</p>
        
        <p>Per coloro che cercano un notaio esperto in contratti di rete a Treviso, lo Studio Notarile del Notaio Falcinelli rappresenta una scelta ideale, offrendo un servizio di alta qualità e una consulenza professionale e personalizzata. Contattando lo studio notarile del Notaio Falcinelli si potrà avere la certezza di essere assistiti in modo efficace e professionale, per costituire un contratto di rete adatto alle proprie esigenze.</p>
        `,
        imageArt: `https://www.notaio-treviso.com/contratto-di-rete.webp`,
    },

    {
        id: 13,
        name: "Enti no-profit",
        link: "enti-no-profit",
        tipologia: "Aziende e società",
        tag: ["aziende", "società"],
        codice_tipo: 4,
        description: `Studio Notarile Falcinelli - Notaio Falcinelli - La legge consente a chiunque la possibilità di costituire un’associazione o una fondazione: la stessa Costituzione, all'art. 18, tutela e valorizza tale diritto.`,
        corpus: `
        <p>Sono ormai molti anni che, in Italia e nel mondo, il fenomeno associativo suscita sempre maggiore consenso ed entusiasmo.</p>

        <p>La legge consente a chiunque la possibilità di costituire un’associazione o una fondazione: la stessa Costituzione, all'art. 18, tutela e valorizza tale diritto.</p>

        <p>Si possono creare organizzazioni con gli  scopi più vari: solidaristiche, assistenziali, culturali, mediche, ricreative, sociali, sportive, professionali, studentesche, sindacali, politiche, religiose, patriottiche, enogastronomiche ecc.</p>

        <p>La libertà di associazione, costituzionalmente riconosciuta, trova il solo limite nel divieto di perseguire fini vietati ai singoli dalle leggi penali e scopi politici mediante organizzazioni di carattere militare.</p>

        <p>Le forme per realizzare gli scopi associativi sono diversi e vari. La legge italiana prevede più alternative. Le principali sono:</p>

        <ul>
        <li>le associazioni riconosciute;</li>
        <li>le associazioni non riconosciute;</li>
        <li>le fondazioni;</li>
        <li>i comitati;</li>
        <li>le onlus;</li>
        <li>l'impresa sociale.</li>
        </ul>

        <p>È quanto mai opportuno rivolgersi per tempo al notaio, che potrà orientare i cittadini nella scelta delle soluzioni più idonee e meglio rispondenti alla loro volontà.</p>

        <p>Il notaio potrà inoltre prestare consulenza qualificata qualora sia necessario armonizzare esigenze diverse. Ad esempio, nella costituzione di una fondazione disposta per testamento, quando occorra contemperare l'intento del fondatore con i diritti dei legittimari. Potrà inoltre intervenire nella valutazione degli aspetti fiscali, oggetto di continua evoluzione normativa. Inoltre, quando necessario, accompagnerà il nuovo soggetto nel percorso mirante ad ottenere il riconoscimento e lo status di persona giuridica.</p>
        `,
        imageArt: `https://www.notaio-treviso.com/enti-no-profit.webp`,
    },

    {
        id: 14,
        name: "Leasing",
        link: "leasing",
        tipologia: "Aziende e società",
        tag: ["aziende", "società"],
        codice_tipo: 4,
        description: `Studio Notarile Falcinelli - Notaio Falcinelli - Il leasing è il contratto col quale il locatore o concedente attribuisce all’utilizzatore il godimento di beni mobili od immobili dietro versamento di un corrispettivo sotto forma di canoni a scadenza periodica.`,
        corpus: `
        <p>Il leasing è un contratto che consente di utilizzare un bene (ad esempio, un'auto, un immobile o un attrezzatura) per un periodo di tempo determinato, contro il pagamento di un canone periodico. A fine contratto si può decidere se riscattare il bene, restituirlo al locatore o rinnovare il contratto.</p>

        <p>Il notaio svolge un ruolo fondamentale nella stipula di un contratto di leasing in quanto è lui che redige il contratto e ne certifica la validità, assicurando che sia conforme alla legge. Inoltre, il notaio è un professionista che può offrire un supporto prezioso nella scelta della forma più adatta per il contratto di leasing e nella definizione degli obiettivi.</p>
        
        <p>Lo Studio Notarile del Notaio Falcinelli di Treviso, è un'eccellenza nella città di Treviso per quanto riguarda la consulenza e la predisposizione di atti notarili e nella costituzione di contratti di leasing. Il notaio Falcinelli, con la sua lunga esperienza e competenza, saprà consigliare al meglio i propri clienti per quanto riguarda la costituzione di contratti di leasing, offrendo loro una consulenza personalizzata in base alle loro esigenze.</p>
        
        <p>Il leasing può essere utilizzato per diversi scopi, ad esempio per l'utilizzo di un bene senza doverlo acquistare, per ottenere benefici fiscali o per avere maggiore flessibilità nella gestione del proprio patrimonio. La costituzione di un contratto di leasing può rappresentare una soluzione efficace per le imprese che vogliono utilizzare un bene senza doverlo acquistare e per avere maggiore flessibilità nella gestione del proprio patrimonio.</p>
        
        <p>Per coloro che cercano un notaio esperto in contratti di leasing a Treviso, lo Studio Notarile del Notaio Falcinelli rappresenta una scelta ideale, offrendo un servizio di alta qualità e una consulenza professionale e personalizzata. Contattando lo studio notarile del Notaio Falcinelli si potrà avere la certezza di essere assistiti in modo efficace e professionale, per costituire un contratto di leasing adatto alle proprie esigenze.</p>
        `,
        imageArt: `https://www.notaio-treviso.com/leasing.webp`,
    },

    {
        id: 15,
        name: "Successioni",
        link: "successioni",
        tipologia: "Famiglia Unioni Civili e Successioni",
        tag: ["famiglia", "persone", "successione", "morte"],
        codice_tipo: 2,
        description: `Studio Notarile Falcinelli - Notaio Falcinelli - La dichiarazione di successione è un documento di natura fiscale che segue il momento della morte di un certo soggetto e disciplina il momento dell’apertura della successione a favore dei chiamati all’eredità.`,
        corpus: `
            <p>La dichiarazione di successione è un documento di natura fiscale che segue il momento della morte di un certo soggetto e disciplina il momento dell’apertura della successione a favore dei chiamati all’eredità.</p>
            <p>Tale dichiarazione è quindi a loro carico e la funzione principale è quella di fornire informazioni all’Agenzia delle entrate sull’ammontare e sulla composizione del patrimonio ereditario in modo da poter calcolare le imposte che devono essere pagate.</p>
            <p>Molte volte è difficile capire come debba avvenire la sua esatta compilazione, ed è per questo che il notaio viene in aiuto.</p>
            <p>I soggetti obbligati a presentare la dichiarazione di successione sono individuati dall’Agenzia delle entrate e sono i seguenti:</p>
            <ul>
            <li>i chiamati all'eredità (per legge o per testamento, anche se non hanno ancora accettato l'eredità, purché non vi abbiano espressamente rinunciato) e i legatari, anche nel caso di apertura della successione per dichiarazione di morte presunta, o i rappresentanti legali;</li>

            <li>chi è immesso nel possesso dei beni, in caso di assenza del defunto o di dichiarazione di morte presunta;</li>

            <li>gli amministratori dell'eredità;</li>

            <li>i curatori delle eredità giacenti;</li>

            <li>gli esecutori testamentari;</li>

            <li>il trustee.</li>
            </ul>

            <p>Il ruolo del Notaio è quanto mai significativo nel momento dell’apertura della successione, soprattutto per il compimento di tutte le pratiche preliminari in cui si richiede una collaborazione di tutti gli eredi partecipanti.</p>

            <p>In particolare, il Notaio si occuperà di coadiuvare le parti nella compilazione della dichiarazione di successione, compiendo tutti gli accertamenti necessari.</p>

            <p>Provvede, inoltre:</p>

            <ul>
            <li>a redigere il prospetto di autoliquidazione delle imposte, il modello di pagamento di tasse, imposte, sanzioni ed altre entrate, nonché la relativa autocertificazione;</li>

            <li>a registrare la dichiarazione presso l’Agenzia delle Entrate competente, successivamente predisponendo e presentando la voltura catastale presso il Catasto territorialmente competente in base a dove sono situati gli immobili ereditati.</li>
            </ul>

            <p>COSA SUCCEDE SE MANCA LA DICHIARAZIONE DI SUCCESSIONE?
            <p>Nel caso in cui la registrazione della dichiarazione di successione sia omessa, si applica una sanzione che va dal 120 al 240% dell’imposta liquidata; se non è dovuta l’imposta, si applica la sanzione amministrativa da 250 a 1.000 euro.</p>

            <p>Se invece la dichiarazione è presentata con un ritardo che non supera i trenta giorni, si applica la sanzione amministrativa dal 60 al 120% dell’imposta liquidata; se non è dovuta imposta, si applica la sanzione amministrativa da 150 a 500 euro.</p>

            <p>Il tardivo versamento dell’imposta comporta invece l’applicazione della sanzione del 30%, calcolata sull’importo non versato. Tuttavia, prima che avvenga l’accertamento d’ufficio si può effettuare il cosiddetto “ravvedimento operoso”, mediante il quale è possibile regolarizzare i versamenti di imposte omessi o insufficienti, in questo modo beneficiando di una riduzione delle sanzioni.</p>

            <p>COSA SUCCEDE DOPO LA PRESENTAZIONE DELLA DICHIARAZIONE?</p>
            <p>Una volta presentata la dichiarazione, l’Agenzia delle entrate notifica agli eredi la liquidazione dell’imposta di successione, da pagare entro i 60 giorni successivi alla richiesta (e devono farlo tutti gli eredi solidalmente: significa che il Fisco può agire per ottenere l’intero importo anche nei confronti di un erede soltanto che, poi, potrà rivalersi sugli altri in proporzione alle loro quote di eredità). Ovviamente l’importo dovuto a titolo di imposta dipende dal grado di parentela degli eredi rispetto al defunto, e si dovranno applicare, quindi, certe aliquote e franchigie.</p>
        `,
        imageArt: `https://www.notaio-treviso.com/successioni.webp`,
    },

    {
        id: 16,
        name: "Dichiarazione di successione",
        link: "dichiarazione-di-successione",
        tipologia: "Famiglia Unioni Civili e Successioni",
        tag: ["famiglia", "persone", "successione", "morte"],
        codice_tipo: 2,
        description: `La dichiarazione di successione notarile a Treviso è un passo importante per regolare la successione di una persona dopo il suo decesso. Essa serve a dichiarare e quantificare i beni e i debiti del defunto, nonché a stabilire chi sono gli eredi legittimi.`,
        corpus: `
            <p>La dichiarazione di successione notarile a Treviso è un passo importante per regolare la successione di una persona dopo il suo decesso. Essa serve a dichiarare e quantificare i beni e i debiti del defunto, nonché a stabilire chi sono gli eredi legittimi.</p>

            <p>La dichiarazione di successione notarile a Treviso va presentata entro 12 mesi dalla data del decesso del defunto, e può essere redatta presso un notaio del luogo in cui il defunto aveva la residenza al momento della morte. Il notaio, che è un professionista abilitato e iscritto all'albo, provvederà alla stesura del documento, alla verifica dei documenti e alla trasmissione della dichiarazione al tribunale.</p>
            
            <p>Il vantaggio principale nell'affidarsi ad un notaio per la dichiarazione di successione notarile a Treviso è la certezza della regolarità della procedura, in quanto il notaio è un professionista abilitato e iscritto all'albo e garantisce la corretta compilazione del documento, evitando errori che possono causare ritardi o contestazioni.</p>
            
            <p>La dichiarazione di successione notarile a Treviso è composta da diversi documenti, tra cui l'atto di morte, l'inventario dei beni del defunto e la relazione sulla situazione ereditaria. Inoltre, è necessario allegare eventuali documenti che attestino la presenza di debiti o crediti del defunto.</p>
            
            <p>Una volta presentata la dichiarazione di successione notarile a Treviso, il tribunale la esaminerà e, se tutto è in regola, emetterà un decreto di omologazione. A questo punto gli eredi legittimi potranno entrare in possesso dei beni ereditati.</p>
            
            <p>In caso di contestazione, il notaio può anche rappresentare gli interessi degli eredi in un eventuale giudizio di successione, garantendo una maggiore tutela e certezza del diritto.</p>
            
            <p>In sintesi, la dichiarazione di successione notarile a Treviso è un passo importante per regolare la successione di una persona dopo il suo decesso. Affidarsi ad un notaio per la redazione del documento garantisce la corretta compilazione e la certezza della regolarità della procedura, evitando eventuali contestazioni e permettendo agli eredi legittimi di entrare in possesso dei beni ereditati in modo tempestivo.</p>
        `,
        imageArt: `https://www.notaio-treviso.com/successioni.webp`,
    },

];
export default initialDetails;
