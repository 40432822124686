import React, { Component } from 'react';
import { useParams } from 'react-router-dom';

import Seo from '../../components/seo/Metatags';
import dynamic from 'next/dynamic'

import Data from '../../components/DocumentiStipula/data/output';

import './styles.css';


const BackToTop = dynamic(
  () => import('../../components/back-top'),
  { ssr: false }
)

const Intro = dynamic(
  () => import('../../components/Intro/index'),
  { ssr: false }
)
const DocumentiStipulaDettagli_contenuto = dynamic(
  () => import('./contenuto'),
  { ssr: false }
)

const DocumentiStipulaDettagli = props => {

  const { documentiStipulaID } = useParams();
  
  const location_origin = typeof window !== "undefined" ? `${window.location.href.toString()}` : "https://www.notaio-treviso.com"
  const location_host = typeof window !== "undefined" ? `${window.location.host.toString()}` : "www.notaio-treviso.com"

  return (
    <div>
      {Data.map(docStip => {
        // console.log(servizioID, servizio.link)
        if (documentiStipulaID === docStip.link)
          return (
            <div key={docStip.id}>
              {/* metadati SEO */}
              <Seo
                title_par={`Documenti per la stipula di ${docStip.name} | Notaio a Treviso`}
                description_par={`${docStip.description}`}
                type_par="article"
                ad_host={location_host}
                ad_origin={location_origin}
                link_page={location_origin}
                keywords_par={`${docStip.name}, Treviso, servizio notarile, notaio a Treviso, notaio, notai treviso`}
                isArticle={'vero'}
                art_titleData={docStip.name}
                art_descriptionData={docStip.description}
                art_imagesData={docStip.imageArt}
                art_imagesData_caption={`${docStip.name} - notaio a Treviso`}
                art_keywordsData={docStip.name}
                art_dateCreationData="2022-05-07T10:10:54+00:00"
                art_dateLastActivityData="2022-05-07T10:30:54+00:00"
                art_shortDescriptionData={docStip.description}
                art_urlData={location_origin}
              />

              <React.Fragment>
                <Intro 
                    title={docStip.name} 
                    subtitle={`Notai di Treviso - Parliamo di ${docStip.name}`} 
                    contents={`${docStip.description}<br/><a className='italicIntro' title='notaio treviso' href='https://www.notaio-treviso.com/'>NOTAIO TREVISO</a>`}
                    linkSpinner={'divStipulaDett'}
                />
                <DocumentiStipulaDettagli_contenuto docStip={docStip} />
                <BackToTop />
              </React.Fragment>

            </div>
          );
      }
      )}
    </div>
  );
};

export default DocumentiStipulaDettagli;

