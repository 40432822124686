import React from 'react';
import Seo from '../components/seo/Metatags';
import dynamic from 'next/dynamic'

//import components
// wrap your component that uses the graph lib.
const Intro = dynamic(
    () => import('../components/Intro/index'),
    { ssr: false }
)

const LinkedinPreview = dynamic(
    () => import('../components/linkedinPreview'),
    { ssr: false }
)

const Preloader = dynamic(
    () => import('../components/preloader'),
    { ssr: false }
)


let ad_origin = typeof window !== "undefined" ? `${window.location.origin.toString()}` : "https://www.notaio-treviso.com"
let ad_host = typeof window !== "undefined" ? `${window.location.origin.toString()}` : "www.notaio-treviso.com"

const NewsPage = () => {
    return (
        <>
            <Seo
                title_par="Notaio Treviso | News"
                description_par="Le news dello Studio Notarile Falcinelli. Notaio di Treviso e provincia: mutui, atti societari, atti di compravendita, trust, successioni."
                type_par="website"
                ad_host={ad_host}
                ad_origin={ad_origin}
                link_page={`${ad_origin}/news`}
                keywords_par="Treviso trust notarile, trust notarile, trust notaio, Treviso, notai treviso"
                isArticle={''}
                art_titleData=''
                art_descriptionData=''
                art_imagesData=''
                art_imagesData_caption=''
                art_keywordsData=''
                art_dateCreationData=''
                art_dateLastActivityData=''
                art_shortDescriptionData=''
                art_urlData=''
            />
            <React.Fragment>
                <Intro 
                    title={'Studio Notarile Falcinelli - Notaio Treviso'} 
                    subtitle={'News'}
                    contents={`News dallo studio notarile Falcinelli.<br/><a className='italicIntro' title='notaio treviso' href='https://www.notaio-treviso.com/'>NOTAIO TREVISO</a>`}
                    linkSpinner={'newsLink'}
                />
                <LinkedinPreview />
                <Preloader />
            </React.Fragment>
        </>
    )
}

export default NewsPage;