import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const myFirebase = firebase.initializeApp(firebaseConfig);
export const myAnalytics = firebase.analytics();


const baseDb = myFirebase.firestore();
export const db = baseDb;
export const arrayUnion = firebase.firestore.FieldValue.arrayUnion;
