import React from 'react';
import Seo from '../components/seo/Metatags';
import dynamic from 'next/dynamic'

const Intro = dynamic(
    () => import('../components/Intro/index'),
    { ssr: false }
)
const Footer = dynamic(
    () => import('../components/Footer'),
    { ssr: false }
)

const BackToTop = dynamic(
    () => import('../components/back-top'),
    { ssr: false }
)

const FormCustom = dynamic(
    () => import('../components/contatti/form/Start_FormCustom'),
    { ssr: false }
)
const TagComponent = dynamic(
    () => import('../components/tags/index'),
    { ssr: false }
)

let ad_origin = typeof window !== "undefined" ? `${window.location.origin.toString()}` : "https://www.notaio-treviso.com"
let ad_host = typeof window !== "undefined" ? `${window.location.origin.toString()}` : "www.notaio-treviso.com"

const VideoConferenzaPage = () => {
    return (
        <>
            <Seo
                title_par="Notaio a Treviso | Richiedi una Videoconferenza"
                description_par="Richiedi una videoconferenza al notaio. Notaio Treviso"
                type_par="website"
                ad_host={ad_host}
                ad_origin={ad_origin}
                link_page={`${ad_origin}/videoconferenza`}
                keywords_par="Notaio a Treviso, Treviso trust notarile, trust notarile, trust notaio, Treviso, notai treviso"
                isArticle={''}
                art_titleData=''
                art_descriptionData=''
                art_imagesData=''
                art_imagesData_caption=''
                art_keywordsData=''
                art_dateCreationData=''
                art_dateLastActivityData=''
                art_shortDescriptionData=''
                art_urlData=''
            />
            <React.Fragment>
                <Intro 
                    subtitle={'Videoconferenza'} 
                    title={'Studio Notarile Falcinelli - Notaio Treviso'}
                    contents={`Richiedi una videoconferenza al tuo notaio di fiducia di Treviso.<br/><a className='italicIntro' title='notaio treviso' href='https://www.notaio-treviso.com/'>NOTAIO TREVISO</a>`}
                    linkSpinner={'formCustomId'}
                />
                <FormCustom FormTitle={`Richiesta Videoconferenza`}/>

                <TagComponent />
                <Footer />
                <BackToTop />
            </React.Fragment>
        </>
    )
}

export default VideoConferenzaPage;
