import React from 'react';
import Seo from '../components/seo/Metatags';
import dynamic from 'next/dynamic'

//import components
// wrap your component that uses the graph lib.
const Intro = dynamic(
    () => import('../components/Intro/index'),
    { ssr: false }
)

const BackToTop = dynamic(
    () => import('../components/back-top'),
    { ssr: false }
)

const Contact = dynamic(
    () => import('../components/contatti'),
    { ssr: false }
)

// const Preloader = dynamic(
//     () => import('../components/preloader'),
//     { ssr: false }
// )


let ad_origin = typeof window !== "undefined" ? `${window.location.origin.toString()}` : "https://www.notaio-treviso.com"
let ad_host = typeof window !== "undefined" ? `${window.location.origin.toString()}` : "www.notaio-treviso.com"

const Contatti = () => {
    return (
        <>
            <Seo
                title_par="Notaio a Treviso | Contatti"
                description_par="Contatta lo Studio Notarile Falcinelli per il tuo atto notarile a Treviso e provincia: mutui, atti societari, atti di compravendita, trust, successioni."
                type_par="website"
                ad_host={ad_host}
                ad_origin={ad_origin}
                link_page={`${ad_origin}/contatti`}
                keywords_par="Notaio a Treviso, Treviso trust notarile, trust notarile, trust notaio, Treviso, notai treviso"
                isArticle={''}
                art_titleData=''
                art_descriptionData=''
                art_imagesData=''
                art_imagesData_caption=''
                art_keywordsData=''
                art_dateCreationData=''
                art_dateLastActivityData=''
                art_shortDescriptionData=''
                art_urlData=''
            />
            <React.Fragment>
                <Intro 
                    title={'Contatti'} 
                    subtitle={'Studio Notarile Falcinelli - Notaio Treviso'}
                    contents={`In questa pagina potrete accedere a tutte le informazioni per contattare lo studio notarile di Treviso in Via Cornarotta, 5. Il numero telefonico dello studio è abilitato a ricevere messaggi, chiamate, videochiamate e file tramite l'applicazione WhatsApp. Potete raggiungere lo studio parcheggiando in uno dei grandi parcheggi vicinissimi allo studio, come il Duomo, l'EX Pattinodromo, il parcheggio Burchiellati o il parcheggio Cantarane.<br/><a className='italicIntro' title='notaio treviso' href='https://www.notaio-treviso.com/'>NOTAIO TREVISO</a>`}
                    linkSpinner={'formContatti'}
                />
                <Contact />
                <BackToTop />
                {/* <Preloader /> */}
            </React.Fragment>
        </>
    )
}

export default Contatti;
